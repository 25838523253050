.c-thumb {
    &__img {
        position: relative;
        border: 1px solid #eee;

        &--80 {
            width: 82px;
            height: 82px;
            min-width: 82px;
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
}
