@charset "UTF-8";
// member_temporary.html
// 단일 안내 페이지 레이아웃

// KYC 인증과 동일한 디자인에 c-popup-alert를 사용해주세요.
.member_wrap {
    @extend .c-notice;

    .member_header {
        @extend .c-notice--header;

        .tit {
            @extend .c-notice--header__title;
        }
    }

    .kyc_notice {
        @extend .c-notice--content;
    }
}

.c-notice {
    width: 100%;
    height: 100vh;
    margin: 0 auto 60px;
    border: none;
    padding: 49px 0 0 0;
    background: $color-gray-14;

    &--header {
        position: relative;
        z-index: 1;
        width: 960px;
        margin: 0 auto;
        padding-bottom: 28px;
        margin-bottom: 41px;
        border-bottom: 1px solid #333;

        &__title {
            font-size: 26px;
            color: #111;
            line-height: 28px;
            font-family: $font-family-default;
            .sp_login {
                @include sprite-retina($sp_icon_2x_logo_11st);
                display: inline-block;
                overflow: hidden;
                line-height: 200px;
                width: 64px;
                height: 28px;
                margin-right: 10px;
                vertical-align: bottom;
            }
            span {
                display: inline-block;
                margin-bottom: 2px;
            }
        }
    }

    &--content {
        position: relative;
        z-index: 1;
        width: 960px;
        margin: 0 auto;
        padding: 19px 0 0 0;
        .text_box {
            padding: 0 0 60px 0;
            text-align: center;
            .text {
                font-size: 20px;
                font-weight: bold;
                color: $color-gray-02;
                strong {
                    color: $color-brand;
                }
            }
            .help {
                margin-top: 40px;
                font-size: 14px;
                color: $color-gray-03;
                strong {
                    color: $color-gray-02;
                    &.color-blue {
                        color: $color-11st-blue;
                    }
                }
            }
            .text2 {
                margin-top: 40px;
                color: #333;
                line-height: 22px;
            }
            &:before {
                content: "";
                display: block;
                @include sprite-retina($sp_icon_2x_alert-90-gray);
                margin: 0 auto 10px auto;
            }
        }
        .bottom_box {
            text-align: center;
            .btn-size__6 {
                width: 240px;
                font-size: 19px;
                margin: 0 3px 0 3px;
            }
        }
    }
}
