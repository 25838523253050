p.c-text {
    display: block;
}

.c-text {
    display: inline-block;
    vertical-align: middle;
    color: #111;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
    box-sizing: content-box;
    margin: var(--text-margin-top, 10px) var(--text-margin-right, 0) var(--text-margin-bottom, 0) var(--text-margin-left, 0);
    &__title,
    &__value {
        display: inline-block;
    }

    &__title {
        padding-right: 5px;
    }

    &--value {
        margin-top: 1px;
        line-height: 40px;
    }
    &--lineheight-40 {
        line-height: 40px;
    }

    &--margin-0 {
        --text-margin-top: 0;
        --text-margin-right: 0;
        --text-margin-bottom: 0;
        --text-margin-left: 0;
    }
    &.mt-0 {
        --text-margin-top: 0;
    }
    &.mt-20 {
        --text-margin-top: 20px;
        --element-mt: 20px; //   margin-top: var(--element-mt, $gap); 대응하기 위함
    }
    &.mr-10 {
        --text-margin-right: 10px;
    }
    &.mr-20 {
        --text-margin-right: 20px;
    }
    &.ml-10 {
        --text-margin-left: 10px;
    }

    &--emphasis {
        color: $color-11st-red;
    }

    &__subtext {
        color: $color-gray-04;
    }
    &--color-01 {
        color: $color-gray-01;
    }

    .section-bar {
        margin: 0 15px;
        color: #d0d1d6;

        &__equal {
            color: #111;
            margin: 0 10px;
        }
    }

    .color-brand {
        color: $color-11st-red;
    }

    .number {
        font-family: $font-family-number;
    }

    &.number-emphasis {
        [class^="btn-style"] {
            margin-left: 16px;
        }
        strong.number {
            margin: -2px 4px 0px 0;
            vertical-align: baseline;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: -0.44px;
            line-height: 26px;

            &.space-10 {
                margin-left: 10px;
            }
        }
        em.number {
            font-weight: bold;
        }
    }
    @at-root .c-date & {
        color: $color-gray-06;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.65px;
    }
}

.c-text-group {
    &__row {
        display: table;
        width: 100%;
    }

    &__col {
        display: table-cell;
        vertical-align: top;
    }

    &__col--gap30 {
        display: inline-block;

        &:not(:last-of-type) {
            margin-right: 30px;
        }
    }
}
