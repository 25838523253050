@charset "UTF-8";

/* 11 Editor 안내 레이어 */
.ly_editor_guide {
    position: absolute;
    width: 1000px;
    padding: 0;
}
.ly_editor_guide .cont {
    height: 600px;
    padding: 0;
    background-color: #fff;
}
.ly_editor_guide .guide_box {
    position: relative;
    overflow: hidden;
    text-align: center;
}
.ly_editor_guide .guide_list {
    position: relative;
    left: 0;
    width: 4990px;
}
.ly_editor_guide .guide_list li {
    float: left;
    width: 998px;
    height: 537px;
    margin-top: 44px;
}
.ly_editor_guide .lk_11editor_guide {
    display: block;
    width: 276px;
    margin: 0 auto;
    font-weight: bold;
}
.ly_editor_guide .lk_download:hover,
.ly_editor_guide .lk_download:focus,
.ly_editor_guide .lk_download:active,
.ly_editor_guide .lk_download:visited {
    color: #ff0038;
    text-decoration: none;
}
.ly_editor_guide .btn_arrow {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    background: transparent;
}
.ly_editor_guide .btn_arrow.prev {
    left: 14px;
}
.ly_editor_guide .btn_arrow.prev .ico {
    background-position: -29px 0;
}
.ly_editor_guide .btn_arrow.next {
    right: 14px;
}
.ly_editor_guide .btn_arrow .ico {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 26px;
    height: 50px;
    background: url("/img/common/sp_arr.png") 0 0 no-repeat;
    line-height: 200px;
    vertical-align: middle;
}
.ly_editor_guide .btn_close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    background: url("/img/common/sp_arr.png") -12px -54px no-repeat;
}
.ly_editor_guide .chk_box {
    position: absolute;
    bottom: 16px;
    left: 30px;
}
.ly_editor_guide .chk_box .lab {
    vertical-align: middle;
    cursor: pointer;
}
.ly_editor_guide .chk_box .lab:before {
    display: inline-block;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    margin: -1px 12px 1px 0;
    background: url("/img/common/sp_arr.png") -60px 0 no-repeat;
    vertical-align: middle;
    content: "";
}
.ly_editor_guide .chk_box .inp {
    *position: relative;
    *left: 0;
    *top: -2px;
    *text-indent: 0;
}
.ly_editor_guide .chk_box .inp:checked + .lab:before {
    background-position: -60px -26px;
}
.ly_editor_guide .chk_box .inp:disabled + .lab:before {
    background-position: -60px -52px;
}
