@charset "UTF-8";

.c-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 22px;
    margin: 10px 0;
    border: 1px solid $color-gray-11;
    background: $color-gray-14;
    &:first-child {
        margin-top: 0;
    }

    summary {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        gap: 22px;
        color: $color-gray-02;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.7px;
        cursor: pointer;

        &::before {
            flex: 0 0 auto;
            display: inline-block;
            content: "Q";
            color: $color-gray-02;
            font-family: Roboto;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -1.1px;
        }
        &::after {
            flex: 0 0 32px;
            height: 32px;
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 25C15 25.5523 15.4477 26 16 26C16.5523 26 17 25.5523 17 25V17H25C25.5523 17 26 16.5523 26 16C26 15.4477 25.5523 15 25 15H17V7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7V15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H15V25Z' fill='%23333333'/%3E%3C/svg%3E");
        }
    }
    &[open] summary::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M6 16C6 15.4477 6.44772 15 7 15H25C25.5523 15 26 15.4477 26 16C26 16.5523 25.5523 17 25 17H7C6.44772 17 6 16.5523 6 16Z' fill='%23FF0038'/%3E%3C/svg%3E");
    }
    &[open] {
        gap: 22px;
        border: 1px solid $color-gray-06;
    }
    &-category {
        flex: 0 0 100px;
        color: $color-gray-02;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.7px;
    }
    &-summary {
        flex: 1 1 100%;
        color: $color-gray-02;
        font-size: 14px;
        letter-spacing: -0.7px;
    }
    &-answer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 22px;
        margin-top: 10px;
        color: $color-gray-04;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.7px;
        &::before {
            margin-top: -5px;
            flex: 0 0 auto;
            display: inline-block;
            content: "A";
            color: #ff0038;
            font-family: Roboto;
            font-size: 22px;
            font-weight: 700;
            letter-spacing: -1.1px;
        }
    }
}
