.c-catalogue {
    border-top: 1px solid $color-gray-11;
    margin-top: 16px;
    @include clearfix;

    &__text {
        color: $color-gray-04;
        font-size: 13px;
        line-height: 54px;
    }

    &__result {
        color: $color-11st-blue;
        font-size: 13px;
        line-height: 54px;
    }

    .gicon-question {
        margin: -2px 0 0 0;
    }

    .btn-style__2 {
        margin-left: 10px;
    }
}

.c-catalogue-matching {
    .c-product-card {
        display: flex;
        width: 100%;

        &__info {
            flex-grow: 1;
            padding: 0 15px;
        }

        &__name {
            @include text-ellipsis-multiple(2);
            max-height: 40px;
            overflow: hidden;
        }

        &__button {
            max-width: 160px;
            min-width: 132px;

            .vc {
                text-align: right;
                .btn-style__1 {
                    + .btn-style__1 {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    &--other {
        margin-bottom: 20px;
    }

    &--selected {
        padding-bottom: 30px;
        border-bottom: 1px solid $color-gray-11;

        .c-product-card {
        }
    }
}

.c-catalogue-option {
    @include clearfix();

    &__header {
        position: relative;
        margin: 30px 0 10px 0;
        height: 53px;
        font-size: 13px;
        z-index: 1;

        .c-title {
            .c-title__text {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
                color: $color-gray-02;
            }
        }

        &-right {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
        }
    }

    &__body {
        border: 1px solid $color-gray-11;
        background: $color-gray-13;
    }

    &__row {
        display: flex;
        width: 100%;
        padding: 20px 0;
        background: $color-gray-13;
        font-size: 0;

        .c-title {
            display: flex;
            position: relative;
            padding-right: 20px;
            color: #111;
            font-size: 14px;
            vertical-align: middle;

            .c-title__text {
                color: #111;
                margin-top: 10px;
                font-size: 14px;
                line-height: 20px;
                font-weight: normal;
            }
        }

        &:not(:first-of-type) {
            border-top: 1px solid $color-gray-11;
        }
    }

    &__title {
        padding-left: 20px;
        width: 158px;
        min-width: 158px;
        vertical-align: top;
        color: $color-gray-02;
    }

    &__cont {
        flex-grow: 1;
        padding-right: 20px;
        font-size: 0;
    }

    &__choice {
        margin-top: -10px;
        max-height: 84px;
        overflow: hidden;

        &--open {
            overflow: visible;
            max-height: 100%;
        }
    }

    &__list {
        display: inline-flex;
        flex-wrap: wrap;
        width: calc(100% + 5px);
        margin-left: calc(-5px / 2);

        li {
            padding-right: calc(5px / 2);
            padding-left: calc(5px / 2);
            padding-top: 10px;
        }
    }
}
