@charset "UTF-8";
@import "../selleroffice/mixins"; //믹스인모음

@import "../selleroffice/helpers/variables"; //변수모음
@import "../selleroffice/helpers/reset"; //스타일 초기화

@import "../selleroffice/sprites/sp_icon.scss";
@import "../selleroffice/sprites/sp_gnb_2x.scss";
@import "../selleroffice/sprites/sp_icon_2x.scss";
@import "../selleroffice/sprites/sp_form_2x.scss";
@import "../selleroffice/sprites/sp_grid_2x.scss";
@import "../selleroffice/sprites/sp_footer_2x.scss";
@import "../selleroffice/sprites/sp_delivery.scss";

@import "../selleroffice/partial/common.scss";
@import "../selleroffice/partial/layout.scss";
@import "../selleroffice/partial/main-report.scss"; // 메인 리포트
@import "../selleroffice/partial/c-conversion-dashboard.scss"; // 셀러 전환 대시보드
@import "../selleroffice/partial/c-main-top-banner.scss"; // 셀러 전환 하단 광고
@import "../selleroffice/partial/footer.scss";
@import "../selleroffice/partial/button.scss";
@import "../selleroffice/partial/c-text.scss";
@import "../selleroffice/partial/c-guide.scss";
@import "../selleroffice/partial/form.scss";
@import "../selleroffice/partial/c-select.scss";
@import "../selleroffice/partial/modal.scss";
@import "../selleroffice/partial/table.scss";
@import "../selleroffice/partial/gicon.scss";
@import "../selleroffice/partial/box.scss";
@import "../selleroffice/partial/c-tip.scss";
@import "../selleroffice/partial/c-clean.scss";
@import "../selleroffice/partial/c-thumb.scss";
@import "../selleroffice/partial/c-addimg.scss";
@import "../selleroffice/partial/c-preview.scss";
@import "../selleroffice/partial/c-product-card.scss";
@import "../selleroffice/partial/c-list.scss";
@import "../selleroffice/partial/c-catalogue.scss";
@import "../selleroffice/partial/c-option.scss";
@import "../selleroffice/partial/c-payment-box.scss";
@import "../selleroffice/partial/c-caution.scss";
@import "../selleroffice/partial/c-details.scss";
@import "layerpopup_11editer_guide.scss";
@import "../selleroffice/sprites/sp_main_pop_mov.scss"; //레이어팝업 - 동영상 등록 안내
@import "../selleroffice/shockingdeal_info.scss"; //쇼킹딜 소개
@import "../selleroffice/etc/_c-notice.scss"; // 아이디 일시정지 안내

//캘린더 라이브러리 css 충돌 제거
.vdpOuterWrap {
    table,
    th,
    td {
        font-size: 10px;
        line-height: normal;
    }

    button {
        line-height: normal;
    }
}

.c-close {
    display: none;
}
.c-open {
    display: block;
}
.p-size {
    &__10 {
        padding: 10px 0;
    }
}

.mt-size {
    &__10 {
        margin-top: 10px;
    }
}

.newc_popup .newc_close {
    position: absolute;
    top: 10px;
    right: 30px;
    width: 14px;
    height: 14px;
    color: #111;
    cursor: pointer;
    display: block;
    text-align: center;
    text-decoration: none;
    background: url("/img/renew2013/btn/closed02.png") no-repeat 0 0;
    overflow: hidden;
    text-indent: -20000px;
}

.c-header {
    &.c-header__style1 {
        padding-bottom: 30px;
        text-align: center;

        .c-header__describe {
            display: block;
            font-size: 14px;
            color: #333;
            letter-spacing: -0.44px;
            line-height: 20px;
        }
        .c-header__title {
            font-size: 30px;
            color: #333;
            letter-spacing: -0.94px;
        }
    }
}
.c-pageheader {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 9px 0 4px;
    &__title {
        color: #333;
        font-size: 24px;
    }

    &__description {
        margin-top: 3px;
        color: #666;
        font-size: 13px;
    }

    &__link {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #0b83e6;
        font-size: 13px;
    }
}

.c-status {
    margin: 16px 10px;
    padding: 11px 21px;
    background-color: #fffbe9;
    border: 1px solid #f3ecce;
    height: 48px;
    font-size: 16px;
    line-height: 26px;
    color: #000;

    @include clearfix();

    &__title {
        float: left;

        &:before {
            content: "";
            margin-top: -2px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            @include sprite($sp_icon_info);
        }
    }

    &__link {
        position: relative;
        float: right;
        color: $color-secondary;
        font-size: 16px;
        line-height: 26px;

        &:after {
            content: "";
            margin-top: -2px;
            margin-left: 5px;
            display: inline-block;
            vertical-align: middle;
            @include ico-angle(5px, $color-secondary);
        }
    }
}

.c-box {
    &.c-box---style1,
    &.c-box__style1 {
        width: 100%;
        padding: 13px 8px;
        background: #fff;
        border: 1px solid #ddd;
        font-size: 14px;
        letter-spacing: 0;
        color: $color-grayscale-05_1;
    }

    &.c-box--style2 {
        width: 100%;
        padding: 20px 4px;
        background: #fff;
        border-top: 1px solid #eee;
        font-size: 14px;
        letter-spacing: 0;
        color: $color-grayscale-05_1;
    }

    &.scroll--line-6,
    &.scroll-line__6 {
        overflow: hidden;
        overflow-y: auto;
        max-height: 147px;
    }

    &.scroll--line-12 {
        overflow: hidden;
        overflow-y: auto;
        height: 294px; //빈 data 화면 고정 디자인 요청
    }
}

.c-category__field {
    margin-top: 10px;
    padding: 9px 17px;
    border: 1px solid #eee;
    background: #fafafa;
    font-size: 14px;
    line-height: 24px;
    color: #111;

    .c-category__title {
        display: inline-block;
        margin-right: 14px;
    }

    .c-category__text {
        display: inline-block;
        font-weight: bold;

        em {
            font-weight: bold;
        }

        .gt {
            margin: 0 5px;
        }
    }
}

.c-category-field {
    margin-top: 10px;
    padding: 9px 17px;
    border: 1px solid #eee;
    background: #fafafa;
    font-size: 14px;
    line-height: 24px;
    color: #111;

    &__title {
        display: inline-block;
        margin-right: 14px;
    }

    &__text {
        display: inline-block;
        font-weight: bold;

        em {
            font-weight: bold;
        }

        .gt {
            margin: 0 5px;
        }
    }
}

.c-form__group {
    font-size: 0;

    &:first-child {
        .c-form__title {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 40px;
        }
        .c-input,
        .c-dropdown,
        [class^="btn-style"],
        [class^="check-style"],
        [class^="radio-style"],
        .c-radio__tab,
        .c-date__calender,
        .c-select {
            margin-top: 0;
        }
        .c-input {
            [class^="btn-style"] {
                margin-bottom: 0px;
            }
        }
    }
    &:not(:first-child) {
        margin-top: 4px;
    }
    & + .c-guide {
        margin-top: 0;
    }

    .c-form__group-row {
        margin-top: 4px;

        &:first-of-type {
            margin-top: 0;
        }

        .c-button {
            &.del-plus {
                margin-left: 15px;
            }
        }
    }

    .c-form__title {
        min-width: 65px;
        display: table-cell;
        margin-right: 18px;
        font-size: 14px;
        line-height: 50px;
        letter-spacing: -0.44px;
        color: #111;
    }
    .c-form__cont {
        display: table-cell;
        vertical-align: top;
    }
    .float-right {
        display: inline-block;
        float: right;
    }
    .c-input,
    .c-dropdown,
    [class^="btn-style"],
    [class^="check-style"],
    [class^="radio-style"],
    .c-radio__tab,
    .c-select,
    .c-date__calender {
        margin: 5px 0;
        vertical-align: middle;
        margin-right: 10px;

        & + .section-bar {
            margin-left: 0px;
            line-height: 40px;
            vertical-align: middle;
        }
        &:nth-last-child(1) {
            margin-right: 0;
        }
        .c-input {
            margin: 0;
        }
        &.ml-20 {
            margin-left: 20px;
        }
    }
    [class^="radio-style"]:nth-child(1),
    [class^="check-style"]:nth-child(1) {
        margin-left: -9px;
    }
    .c-input[class="c-input"] {
        display: inline-block;
        width: auto;

        .input-text {
            display: inline-block;
            width: auto;
        }
        .unit {
            display: inline-block;
        }
    }
    .c-dropdown + .c-noti__must {
        margin-left: -3px;
    }
    .c-dropdown:not(.wide) {
        display: inline-block;

        > .sub-text {
            display: inline-block;
        }
    }
    .section-bar {
        display: inline-block;
        height: 40px;
        margin: 0 10px;
        font-size: 14px;
    }
    .col {
        display: inline-block;
    }
    &.col-2 {
        .col {
            width: 49%;
        }
    }
    &.col-3 {
        .col {
            width: 33%;
        }
    }
    &.col-4 {
        .col {
            width: 24%;
        }
        [class^="radio-style"],
        [class^="check-style"],
        [class^="c-input"] {
            width: 24%;
        }
        [class^="radio-style"]:nth-child(4n + 1),
        [class^="check-style"]:nth-child(4n + 1) {
            margin-left: -9px;
        }
    }
    &.col-5 {
        .col {
            width: 20%;
        }
        [class^="radio"],
        [class^="check"],
        [class^="c-input"] {
            width: calc(20% - 5px);
            margin-right: 5px;
        }
        [class^="radio"]:nth-child(5n + 1),
        [class^="check"]:nth-child(5n + 1) {
            margin-left: -9px;
        }
    }
}

@mixin formGap($gap) {
    > .c-input,
    > .c-dropdown,
    > [class^="btn-style"],
    > .c-check,
    > .c-radio,
    > .c-radio__tab,
    > .c-select,
    > .c-date__calender,
    > .c-category,
    > .c-guide-special,
    > .c-date > .c-select,
    > .c-date > .c-input,
    > .c-date > .c-input + .section-bar,
    > .c-input + .section-bar,
    > [class^="link link-style"],
    > .c-text,
    > .c-textarea,
    > .c-enter-safe,
    > .c-enter-delivery,
    > .c-enter-address {
        margin-top: var(--element-mt, $gap);
    }

    > .c-enter-option {
        margin-top: calc(#{$gap} + 10px);
    }
}
.c-form {
    font-size: 0;

    &:not(:first-child) {
        margin-top: 10px;
    }

    &__row {
        display: table;
        width: 100%;

        @include formGap(10px);

        &:first-child {
            margin-top: -10px;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }

        &--flex {
            display: flex;
        }

        &--slim {
            @include formGap(0);
        }
        //개발리뷰 후 제거 :S
        &--gap30 {
            .c-form__col {
                &:not(:last-child) {
                    padding-right: 30px;
                }

                .c-input {
                    margin-right: 30px;
                }
            }
        }
        //개발리뷰 후 제거 :E
    }

    &__col {
        display: table-cell;
        vertical-align: top;

        &:not(:last-child) {
            padding-right: 10px;
        }

        @include formGap(10px);

        &--w140 {
            width: 140px;
        }

        $gap-values: (30, 20);
        @each $gap-value in $gap-values {
            &--gap#{$gap-value} {
                display: inline-block;

                &:not(:last-of-type) {
                    padding-right: 0;
                    margin-right: #{$gap-value}px;
                }
            }
        }
    }

    &__title {
        width: 65px;
        min-width: 65px;
        display: table-cell;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: -0.5px;
        color: #111;
    }

    &__cont {
        display: table-cell;
        vertical-align: top;
    }

    .float-right {
        display: inline-block;
        float: right;
    }
    &__tail {
        //버튼을 담기 위한 영역
        min-width: var(--tailWidth, 85px);
    }

    .c-input,
    .c-dropdown,
    [class^="btn-style"],
    .c-check,
    .c-radio,
    .c-radio__tab,
    .c-select,
    .c-date__calender {
        vertical-align: middle;
        margin-right: 10px;

        & + .section-bar {
            //     margin-left: 0px;
            //     line-height: 40px;
            vertical-align: top;
            //     // 가입 절차에서 디자인 QA 로 수정됨 사이드 이팩트시 section-bar 조치 필요
            //     // margin-top: 10px;
            //     // vertical-align: middle;
        }

        &:nth-last-child(1) {
            margin-right: 0;
        }

        & + .link {
            margin-left: 20px;
        }
    }

    .c-radio,
    .c-check {
        &:first-of-type() {
            margin-left: -9px;
        }
    }

    .c-input[class="c-input"] {
        display: inline-block;
        width: auto;

        .input-text {
            display: inline-block;
            width: auto;
        }
        .unit {
            display: inline-block;
        }
    }

    .c-dropdown + .c-noti__must {
        margin-left: -3px;
    }

    .c-dropdown:not(.wide) {
        display: inline-block;

        > .sub-text {
            display: inline-block;
        }
    }

    .section-bar {
        display: inline-block;
        height: 40px;
        margin: 0 10px;
        font-size: 14px;
        line-height: 40px;
    }

    .col {
        display: inline-block;
    }
    &.col-2 {
        .col {
            width: 49%;
        }
    }
    &.col-3 {
        .col {
            width: 33%;
        }
    }
    &.col-4 {
        .col {
            width: 24%;
        }
        [class^="radio-style"],
        [class^="check-style"],
        [class^="c-input"] {
            width: 24%;
        }
        [class^="radio-style"]:nth-child(4n + 1),
        [class^="check-style"]:nth-child(4n + 1) {
            margin-left: -9px;
        }
    }
    &.col-5 {
        .col {
            width: 20%;
        }
        [class^="radio"],
        [class^="check"],
        [class^="c-input"] {
            width: calc(20% - 5px);
            margin-right: 5px;
        }
        [class^="radio"]:nth-child(5n + 1),
        [class^="check"]:nth-child(5n + 1) {
            margin-left: -9px;
        }
    }

    &--waring {
        .c-input {
            .input-text {
                padding-bottom: 0;
            }
            &.waring {
                vertical-align: top;
            }
        }
        [class^="btn-style"] {
            vertical-align: top;
        }
        .c-select + .section-bar,
        .c-input + .section-bar {
            margin-top: 0;
            vertical-align: top;
        }
        .c-select {
            vertical-align: top;
            padding-bottom: 0;
        }
    }
    //c-form 다음으로 c-guide가 있을때 사용
    + .c-guide {
        margin-top: 10px;
    }
}

.c-data {
    &-delivery {
        display: table;

        &__item {
            display: table-row;
        }
        &__fee {
            display: table-cell;
            padding: 5px 0;
            min-width: 45px;
            color: $color-grayscale-02;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;
            text-align: right;
        }

        &__terms {
            display: table-cell;
            color: $color-grayscale-02;
            font-size: 14px;
            line-height: 20px;
            vertical-align: middle;

            &::before {
                display: inline-block;
                color: $color-grayscale-05_1;
                margin: 0 15px;
                vertical-align: middle;
                content: "/";
            }
        }

        &:not(:first-child) {
            margin-top: 15px;
        }
    }
}

.c-enter {
    &-delivery {
        display: table;

        &__col {
            display: table-cell;

            &:nth-child(1) {
                width: 214px;

                .section-bar {
                    margin: 0 10px;
                }
            }
        }

        &__row {
            line-height: 40px;

            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    }

    &-package-size {
        margin-top: 10px;
        .c-img {
            width: 209px;
            margin-right: 30px;
            vertical-align: top;

            img {
                width: 100%;
            }
        }
        .c-input {
            margin-right: 30px;
        }
    }

    &-safe {
        .c-select,
        .c-input,
        [class^="btn-style"] {
            margin: 0;
        }

        &__col {
            &:not(:first-child) {
                margin-left: 10px;
            }

            &:nth-of-type(1) {
                flex-shrink: 0;
                width: 344px;
            }
            &:nth-of-type(2) {
                flex-shrink: 0;
                width: 180px;
            }
            &:nth-of-type(3) {
                flex-grow: 1;
            }
        }

        &__row {
            display: flex;
            font-size: 0;
            margin-top: 10px;
        }

        .c-button {
            &.del-plus {
                display: inline-block;
                margin-left: 0 !important;
                font-size: 0;
                vertical-align: middle;

                button {
                    &:not(:first-child) {
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    &-option {
        .c-select,
        .c-input,
        [class^="btn-style"] {
            margin: 0;
        }

        &__title {
            font-size: 14px;
            color: $color-grayscale-02;
            line-height: 20px;
            font-weight: normal;
        }
        &__col {
            &:not(:first-child) {
                margin-left: 10px;
            }

            &:nth-of-type(1) {
                flex-shrink: 0;
                width: 157px;
            }
            &:nth-of-type(2) {
                flex-shrink: 0;
                width: 505px;
            }
            &:nth-of-type(3) {
                flex-grow: 1;
            }
        }

        &__row {
            display: flex;
            font-size: 0;
            &:not(:first-child) {
                margin-top: 10px;
            }
        }

        .c-button {
            &.del-plus {
                display: inline-block;
                margin-left: 0 !important;
                font-size: 0;
                vertical-align: middle;

                button {
                    &:not(:first-child) {
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    &-address {
        .c-select,
        .c-input,
        [class^="btn-style"] {
            margin: 0;
        }

        &__row {
            display: flex;
            font-size: 0;
            margin-top: 10px;
        }
    }
}

.c-grid {
    &--1 {
        width: 8.33333%;
    }
    &--2 {
        width: 16.66667%;
    }
    &--3 {
        width: 25%;
    }
    &--4 {
        width: 33.33333%;
    }
    &--5 {
        width: 41.66667%;
    }

    &--6 {
        width: 50%;
    }
    &--7 {
        width: 58.33333%;
    }
    &--8 {
        width: 66.66667%;
    }
    &--9 {
        width: 75%;
    }
    &--10 {
        width: 83.33333%;
    }
    &--11 {
        width: 91.66667%;
    }
    &--12 {
        width: 100%;
    }
}

//상품등록 컴포넌트
.c-category {
    display: table;
    width: 100%;
    border: 1px solid #d1d1d6;

    .c-category__title {
        padding: 0 12px;
        height: 40px;
        border-bottom: 1px solid #bbb;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 40px;
        color: #333;
    }

    .c-category__col {
        display: table-cell;
        width: 18%;
        border-right: 1px solid #d1d1d6;

        &:last-child {
            border-right: none;
        }
    }
    .list {
        width: 100%;
        padding: 10px 0;
        background: #fff;
        min-height: 360px;
        max-height: 360px;
        overflow: hidden;
        overflow-y: auto;

        [role="option"],
        .list__item {
            position: relative;
            display: block;
            width: 100%;
            padding: 0 26px;
            line-height: 40px;
            font-size: 14px;
            color: #000;
            text-align: left;
            cursor: pointer;

            &.non-arrow {
                &:after {
                    display: none;
                }
            }
            &[aria-selected="true"],
            &.list__item--selected {
                color: $color-11st-red;
                background: #fafafa;
            }
            &[aria-selected="true"]:after,
            &.list__item--selected:after {
                border-color: $color-11st-red;
            }
            &:hover {
                color: #111;
                background: #fafafa;
                text-decoration: none;
                color: $color-11st-red;
            }
            &:after {
                position: absolute;
                top: 16px;
                right: 20px;
                content: "";
                margin-top: -2px;
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
                width: 5px;
                height: 5px;
                border: 1px solid $color-grayscale-05-1;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }
        }
    }

    &.c-category__style2 {
        margin-top: 15px;

        width: 238px;

        .c-category__col {
            width: 238px;
        }
        .list {
            padding: 0;
            min-height: 162px;
            max-height: 162px;

            [role="option"] {
                position: relative;
                display: block;
                width: 100%;
                padding: 0 12px;
                line-height: 1.2;
                font-size: 14px;
                color: #000;
                text-align: left;
                cursor: pointer;

                &:after {
                    display: none;
                }
            }
            .check-style__5 {
                margin: 0;
            }
        }
    }

    & + .c-guide {
        margin-top: 10px;
    }
}

.c-table__set {
    display: table;
    width: 100%;

    .set-left {
        display: table-cell;
        width: 20%;
    }
    .set-mid {
        display: table-cell;
        min-width: 620px;

        .c-input,
        .c-dropdown {
            margin: 0 10px;
        }
    }
    .set-right {
        display: table-cell;
        width: 20%;
        text-align: right;
    }
    .c-input {
        display: inline-block;
        width: auto;

        .input-text {
            display: inline;
        }
        .inp {
            width: 120px;
        }
        .unit {
            display: inline;
        }
    }
}

.c-table__area {
    margin-top: 20px;
}

.c-expand {
    position: relative;
    z-index: 1;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #111;
    font-size: 14px;

    .arrow-down {
        position: absolute;
        top: 29px;
        right: 29px;
    }
}

.c-list {
    margin-top: 30px;
    min-height: 416px;
    max-height: 509px;
    border-top: 1px solid #eee;
    overflow: hidden;
    overflow-y: auto;

    .count {
        display: block;
        width: 100%;
        height: 44px;
        font-size: 13px;
        color: #111;
        line-height: 44px;
        border-bottom: 1px solid #eee;
    }
    .item:last-child {
        .item-box {
            border-bottom: none;
        }
    }
    .item-box {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 160px;
        padding: 20px;
        text-align: left;
        border-bottom: 1px solid #eee;
        vertical-align: top;

        .img {
            position: relative;
            z-index: 1;
            float: left;
            width: 120px;
            height: 120px;
            margin-right: 20px;
            border: 1px solid #e0e0e0;
            overflow: hidden;

            img {
                max-width: 120px;
                max-height: 120px;
                display: block;
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .name {
            height: 26px;
            margin-top: 6px;
            font-size: 16px;
            line-height: 26px;
            color: #000;
            font-weight: bold;
            margin-bottom: 10px;
            overflow: hidden;
        }
        .brand {
            height: 24px;
            font-size: 14px;
            line-height: 24px;
            overflow: hidden;
        }
        .made {
            height: 24px;
            font-size: 14px;
            line-height: 24px;
            overflow: hidden;
        }
        .cate {
            font-size: 14px;
            line-height: 24px;
            overflow: hidden;
        }
        .date {
            font-size: 14px;
            line-height: 20px;
        }
        .price {
            font-size: 14px;
        }
        .del {
            position: absolute;
            top: 61px;
            right: 20px;
            width: 80px;
            height: 40px;
            border: 1px solid #ccc;
            text-align: center;
            line-height: 40px;
            background-color: #fff;
            font-size: 14px;
            color: #111;
        }
        .time {
            margin-left: 13px;
        }
        &:hover,
        &.active {
            &:before {
                position: absolute;
                top: 0px;
                left: 0;
                width: 100%;
                height: 159px;
                display: block;
                content: "";
                border: 1px solid $color-11st-red;
            }

            background-color: #eee;
        }
    }
    .c-list__notice {
        border-bottom: 1px solid #eee;
        width: 100%;
        text-align: center;
        font-size: 13px;
        color: #111;
        line-height: 30px;
        height: 216px;

        strong {
            font-size: 14px;
        }
    }
}

.c-list-data {
    display: flex;
    flex-wrap: wrap;

    &--col4 {
        .c-list-data__item {
            flex-basis: 25%;
            width: 25%;
        }
    }

    &--style1 {
        color: $color-grayscale-02;
        font-size: 14px;
        .c-list-data__item {
            padding: 7px 4px;
        }

        a {
            color: inherit;
            font-size: inherit;
        }
    }
}

.c-list-info {
    &__item {
        position: relative;
        padding-left: 8px;

        &:not(:first-of-type) {
            margin-top: 4px;
        }

        @include clearfix();
        &::before {
            position: absolute;
            display: block;
            top: 9px;
            left: 0;
            width: 2px;
            height: 2px;
            background-color: $color-gray-03;
            border-radius: 50%;
            content: "";
        }
    }
    &__title {
        float: left;
        margin-right: 6px;
        color: $color-11st-red;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 20px;
    }
    &__desc {
        float: left;
        color: $color-gray-03;
        font-size: 14px;
        letter-spacing: -0.5px;
        line-height: 20px;
    }
}

.c-info {
    font-size: 14px;
    color: #111;
    line-height: 20px;

    .c-info__group {
        border-top: 1px solid #eee;
        &:not(:first-of-type) {
            margin-top: 30px;
        }
        &:first-child {
            border-top: none;
            .title,
            .c-info__title {
                padding-top: 0;
            }
        }

        & > .img_block {
            img:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
    .c-info__center {
        font-size: 0;
        text-align: center;
        height: 160px;

        button {
            margin: 0 10px;
        }
        & + .c-info__center {
            border-top: 1px solid #eee;

            button {
                margin: 0 5px;
            }
        }
    }
    .c-info__btn {
        font-size: 0;
        text-align: center;
        margin-top: 19px;

        &--left {
            text-align: left;
        }

        .c-btn {
            margin-right: 5px;
        }
    }
    .title,
    .c-info__title {
        padding: 20px 0;
        font-size: 16px;
        font-weight: bold;
        color: $color-grayscale-02;

        &:not(:first-of-type) {
            margin-top: 20px;
        }

        .describe {
            font-size: 12px;
        }

        & + .tbl {
            margin-top: 0;
        }

        & + .sentence,
        & + .c-info__sentence {
            margin-top: 0;

            &:not(:first-of-type) {
                margin-top: 0;
            }
        }
    }
    .sub_title {
        padding-bottom: 4px;
        font-size: 14px;
        color: #111;
        line-height: 20px;
        font-weight: 700;
        & + .sentence,
        & + .c-info__sentence {
            // margin-top: 4px;

            &:not(:first-of-type) {
                margin-top: 0;
            }
        }
    }
    .list,
    &__list {
        font-size: 14px;
        line-height: 20px;
        color: #333;
        letter-spacing: -0.44px;

        li {
            padding-left: 17px;
            letter-spacing: -0.44px;

            &:before {
                display: inline-block;
                width: 3px;
                height: 3px;
                border-radius: 1.5px;
                color: #333;
                background-color: #333;
                content: "";
                vertical-align: top;
                -webkit-transform: translate(-9px, 9px);
                -o-transform: translate(-9px, 9px);
                transform: translate(-9px, 9px);
            }
            + li {
                margin-top: 4px;
            }
        }

        & ~ .tbl_fixed {
            margin-top: 18px;
        }
    }
    .sentence,
    .c-info__sentence {
        //padding: 24px 0;
        &:not(:first-of-type) {
            margin-top: 20px;
        }

        & + .sentence,
        & + .c-info__sentence {
            padding-top: 4px;
        }
        &.sub,
        &--sub {
            color: #666;
            font-size: 13px;
            line-height: 20px;
        }
        &.accent,
        &--accent {
            margin-top: 20px;
            text-align: center;
            font-weight: bold;
            line-height: 60px;
        }
        &.sentence-style__2,
        &.c-info__sentence--styl2 {
            padding: 20px 24px;
            border: 1px solid #ddd;
        }
        .img_block {
            display: block;
            margin-top: 10px;
        }

        & + .img_block,
        & + .sub_title,
        & + .c-noti__list {
            margin-top: 20px;
        }
    }
    .img_block {
        .describe {
            margin: 0 40px;
            padding: 33px 0;
            border-top: 1px solid #d8d8d8;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.44px;
            text-align: center;

            .emphasis,
            strong {
                font-weight: bold;
            }
        }
    }
    .c-caution {
        margin-top: 24px;
    }
    & + .c-noti__list {
        margin-top: 16px;
    }
    & + .b-box {
        margin-top: 40px;
    }

    &--style2 {
        .c-info__title {
            font-size: 20px;
            padding: 8px 0;
        }

        .c-info__sentence {
            color: $color-grayscale-03;
            font-size: 16px;
            letter-spacing: -1.28px;

            &:not(:first-of-type) {
                margin-top: 10px;
            }

            em {
                font-weight: bold;
            }

            [class^="link link-style"] {
                margin: 0;
            }
        }

        & + .b-box {
            margin-top: 32px;
        }
    }

    &--center {
        display: flex;
        height: 100%;
        align-items: center;
    }

    &__complete {
        padding: 16px 0;
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        flex-grow: 1;
    }
    &--color-brand {
        color: $color-brand;
        font-weight: 700;
    }
    &--text-center {
        text-align: center;
    }
    // 리뷰 이벤트 관리 : 셀러포인트 자동 충전 약관 동의 안내 팝업에 사용
    &--style3,
    &__style3 {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #eee;
        .c-info__title {
            font-size: 16px;
            font-weight: bold;
            color: #111;
            text-align: center;
        }
        .c-caution {
            margin-top: 0;
        }
        .c-noti__list {
            li {
                font-size: 14px;
                color: #666;
                &:before {
                    background-color: #666;
                }
            }
        }
    }
}

.c-editor {
    &__body {
        position: relative;
        min-height: 344px;
        border: 1px solid #ddd;
        margin-top: -1px;
        overflow: hidden;
        background-color: #fff;

        .vc {
            width: 100%;
            position: absolute;
        }
    }

    &__guide {
        padding: 20px;
        border: 1px solid #ddd;
        border-top: none;

        .guide {
            color: #666;
            line-height: 22px;
            font-size: 13px;
        }
    }

    &__notice {
        font-size: 16px;
        line-height: 15px;
        text-align: center;

        &::before {
            display: block;
            margin: 0 auto 6px;
            content: "";
        }

        &--none {
            color: $color-grayscale-02;
            &::before {
                @include sprite-retina($sp_icon_2x_alert-gray);
            }
        }

        &--have {
            color: $color-11st-red;
            &::before {
                @include sprite-retina($sp_icon_2x_alert-red);
            }
        }
    }

    &__button {
        padding: 10px 0;
        text-align: center;
        button {
            margin: 0 3px;
        }

        &--left {
            float: left;
            text-align: left;
            width: 50%;
        }
        &--right {
            float: right;
            text-align: right;
            width: 50%;
        }

        @include clearfix();
    }

    .editor-11st {
        display: block;
        width: 360px;
        height: 48px;
        margin: 0 auto;
        border: 1px solid #ccc;
        color: #111;
        line-height: 48px;
        font-weight: bold;
    }

    .c-editor__html {
        .txtarea {
            width: 100%;
            height: 450px;
            padding: 16px 34px;
            border: none;
            background: #fff;
            font-size: 16px;
            color: #000;
            line-height: 1.75;
            resize: vertical;
        }
    }

    &-11editor {
        &-link {
            margin-right: 18px;
            color: $color-11st-red;
            font-size: 13px;
            font-weight: bold;
            line-height: 19px;

            &:hover {
                text-decoration: none;
            }

            &::after {
                display: inline-block;
                @include ico-angle("right", 8px, $color-11st-red, 1px, "static");
                margin: -3px 0 0 7px;
                content: "";
            }
        }

        &-go {
            display: block;
            width: 300px;
            height: 48px;
            margin: 0 auto;
            color: $color-grayscale-13;
            background: $color-11st-red;
            line-height: 48px;
            font-size: 16px;
            font-weight: bold;

            &::before {
                display: inline-block;
                @include sprite-retina($sp_icon_2x_11st-editor-text);
                vertical-align: middle;
                margin-top: -4px;
                content: "";
            }

            &:not(:first-child) {
                margin-top: 25px;
            }
        }
    }
}

.c-button {
    display: inline-block;

    &.del-plus {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;
        white-space: nowrap;

        button {
            &:not(:first-child) {
                margin-left: 5px;
            }
        }
    }
    &.vertical-bottom {
        vertical-align: bottom;
    }
}

.c-button__group {
    display: inline-block;
}

.c-address {
    display: table;
    width: 100%;

    .address {
        display: table-cell;
        width: 100%;
        color: #111;
        font-size: 14px;
        line-height: 40px;
    }
}

.c-deliver {
    width: 100%;
    min-width: 756px;
    text-align: left;
    font-size: 0;

    .c-deliver__row {
        &:first-child {
            margin-top: 0;

            .c-input:nth-child(1):nth-last-child(1),
            .c-input:nth-child(1):nth-last-child(1) ~ div {
                width: 100%;
                text-align: left;
            }
        }

        margin-top: 10px;
    }

    .c-deliver__set {
        display: inline-block;
        min-width: 460px;
        padding-right: 10px;
        vertical-align: top;

        .c-input {
            display: inline-block;

            .unit {
                display: inline-block;
                width: 85px;
                text-align: left;
            }
        }

        .c-input:first-child {
            // width: calc(40%);
            text-align: left;

            .unit {
                width: auto;
            }
        }

        .c-input:last-child {
            //width: calc(60% - 30px);
            text-align: right;
        }

        .c-input:nth-child(1):nth-last-child(1),
        .c-input:nth-child(1):nth-last-child(1) ~ div {
            width: 100%;
            text-align: right;
            .unit {
                width: 85px;
            }
        }
    }

    .c-deliver__price {
        display: inline-block;

        .c-input {
            margin-right: 9px;
        }
    }

    .section-bar {
        display: inline-block;
        width: 30px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        line-height: 40px;
    }
}

.c-cellphone {
    .section-bar {
        display: inline-block;
        margin: 40px 20px 0;
        vertical-align: middle;
        line-height: 40px;
    }
    .c-cellphone__rate {
        margin-bottom: 20px;

        .title {
            color: #111;
            font-size: 14px;
            line-height: 40px;
            margin-right: 20px;
        }
        .c-dropdown {
            margin-right: 40px;
        }
    }
    .c-cellphone__price {
        display: inline-block;
        margin-top: 40px;
        line-height: 40px;
        color: #111;
        vertical-align: middle;

        .section-bar {
            margin-top: 0;
        }
        .price {
            margin-left: 20px;
            color: $color-11st-red;
            font-size: 18px;
            line-height: 40px;
        }
        .unit {
            font-weight: bold;
        }
    }
}

.loading {
    display: inline-block;
    width: 64px;
    height: 64px;
    background: url("/img/selleroffice/spinner.gif") no-repeat 0 0;
    background-size: 64px 64px;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;
}

.c-progress {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-right: 50px;
    line-height: 40px;

    .c-progress__bar {
        display: inline-block;
        width: 100%;
        height: 4px;
        background-color: #d2d2d2;
        border-radius: 12px;
        overflow: hidden;
        line-height: 4px;

        .active {
            display: block;
            height: 4px;
            background-color: $color-11st-red;
            line-height: 4px;
            border-radius: 12px;
        }
    }

    .c-progress__text {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        font-size: 13px;
        color: #111;
        line-height: 40px;
    }

    &.progress-style__2 {
        padding-right: 0;
        line-height: 40px;

        .c-progress__info {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 16px;
            line-height: 24px;
            color: #111;
            font-family: roboto;

            .complete {
                color: $color-11st-red;
                font-weight: bold;
            }
        }

        .c-progress__text {
            top: 0;
            color: $color-11st-red;
            font-size: 16px;
            font-family: Roboto;
            line-height: 24px;

            .complete {
                font-weight: bold;
            }
        }

        .c-progress__bar {
            margin-top: 30px;
            height: 6px;

            .active {
                height: 6px;
            }
        }
    }
    &.progress-style__3 {
        padding-right: 0;
        line-height: 40px;

        .c-progress__info {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            font-size: 20px;
            line-height: 24px;
            color: #111;
            font-family: roboto;

            .complete {
                color: $color-11st-red;
                font-weight: bold;
            }

            .sub_info {
                font-size: 14px;
                color: #666;
                line-height: 24px;
                vertical-align: baseline;
                letter-spacing: 0;
            }
        }

        .c-progress__text {
            top: 0;
            color: $color-11st-red;
            font-size: 20px;
            font-family: Roboto;
            line-height: 24px;

            .complete {
                font-weight: bold;
            }
        }

        .c-progress__bar {
            margin-top: 38px;
            height: 6px;

            .active {
                height: 6px;
            }
        }
    }
    /* 클래스명 변경으로 삭제 예정*/
    .complete-bar {
        display: inline-block;
        width: 100%;
        height: 4px;
        background-color: #d2d2d2;
        border-radius: 12px;
        overflow: hidden;
        line-height: 4px;
        .active {
            display: block;
            height: 4px;
            background-color: $color-11st-red;
            line-height: 4px;
        }
    }
    .complete-text {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        font-size: 13px;
        color: #111;
        line-height: 40px;
    }
}

.c-spinner {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    text-align: center;
    min-height: 64px;
}

.c-file {
    padding: 95px 0;
    text-align: center;
    font-size: 0;
    line-height: 0;

    .c-file__button {
        display: inline-block;
        position: relative;
        z-index: 1;
        vertical-align: top;
        margin: 0 10px;

        .inp {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:focus + .text {
                color: $color-11st-red;
                border: 1px solid #66afe9;
                -webkit-box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05), 0 0 8px rgba(102, 175, 233, 0.6);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); /* focus효과*/
            }
            &:hover + .text {
                color: $color-11st-red;
                border-color: $color-11st-red;
            }
            &:focus + .text:before,
            &:hover + .text:before {
                @include sprite-retina($sp_icon_2x_pc_red);
            }
        }
        .text {
            display: inline-block;
            width: 200px;
            height: 110px;
            padding-top: 25px;
            border: 1px solid #d0d1d6;
            font-size: 16px;
            color: #000;
            text-align: center;
            line-height: 110px;
            overflow: hidden;

            &:before {
                display: block;
                position: absolute;
                z-index: 1;
                top: 23px;
                left: 87px;
                content: "";
                @include sprite-retina($sp_icon_2x_pc_black);
            }
        }
        &.btn-storage .text:before {
            display: block;
            position: absolute;
            z-index: 1;
            top: 23px;
            left: 87px;
            content: "";
            @include sprite-retina($sp_icon_2x_storage_black);
        }
        &.btn-storage:hover {
            .text {
                color: $color-11st-red;
                border-color: $color-11st-red;
            }
            .text:before {
                @include sprite-retina($sp_icon_2x_storage_red);
            }
        }

        &.btn-url .text:before {
            display: block;
            position: absolute;
            z-index: 1;
            top: 23px;
            left: 87px;
            content: "";
            @include sprite-retina($sp_icon_2x_url);
        }
        &.btn-url:hover {
            .text {
                color: $color-11st-red;
                border-color: $color-11st-red;
            }
            .text:before {
                @include sprite-retina($sp_icon_2x_url-red);
            }
        }
    }
}

.c-example {
    &--style1 {
        position: relative;
        display: table;
        margin: auto;

        .c-example__img {
            display: table-cell;
        }

        figcaption {
            position: relative;
            display: table-cell;
            padding-left: 15px;
            vertical-align: bottom;
        }

        .c-example__explan {
            width: 238px;
            &__title {
                color: $color-grayscale-02;
                font-size: 14px;
                line-height: 20px;
            }
            &__cont {
                color: $color-grayscale-04;
                font-size: 13px;
                line-height: 20px;
                margin-top: 5px;
                padding-bottom: 10px;
            }
        }
    }
}

/* 상품상세 이미지 확대보기 */
.prdc_bigimgWrap {
    width: 700px;
    height: 508px;
    background: url("/img/selleroffice/viewprd.png") no-repeat 0 0;
    background-size: 780px 508px;
}

.prdc_bigimg {
    position: relative;
    top: 86px;
    width: 300px;
    height: 300px;

    .v-align {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .mousetrap {
        position: absolute;
    }
    .thumb {
        width: 300px;
        height: 300px;
    }
    .largeImg {
        overflow: hidden;
        position: absolute;
        left: 322px;
        top: 0px;
        z-index: 999;
        width: 456px;
        height: 456px;
        border: 1px solid #8b8b8b;
    }
    .lens {
        position: absolute;
        z-index: 98;
        margin: 0;
        border: 1px solid #888;
        background-color: #fff;
        cursor: move;
    }
    .ico_img_expand {
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: url("/img/selleroffice/btn_viewprd.png") no-repeat 0 0;
        overflow: hidden;
        line-height: 200px;
    }
}

/* 상품조회/수정 상품코드 검색 */
.c-product__code {
    display: table;
    width: 100%;

    .c-textarea {
        .txtarea {
            resize: vertical;
        }
    }
}

.c-date {
    display: inline-block;
    vertical-align: middle;

    &:not(:first-child) {
        margin-left: 30px;
    }

    .c-input {
        & + .time {
            margin-left: -5px;
            //상품그룹관리 > 등록 > 전시 기간에서 시간 패딩값 수정
            .input-text {
                .inp {
                    padding-right: 12px;
                }
            }
        }
    }

    .c-button {
        margin-top: 10px;
    }
}

.c-date__period {
    display: inline-table;
    table-layout: fixed;
    padding-left: 1px;
    vertical-align: top;

    &:not(:first-child) {
        margin-left: 10px;
    }
    &:not(:last-child) {
        margin-right: 10px;
    }
    .inp {
        position: absolute;
        display: inline-block;
        opacity: 0;
        width: 0;
        height: 0;
    }
    .lab {
        position: relative;
        z-index: 1;
        float: left;
        margin-left: -1px;
        display: table-cell;
        padding: 4px 10px;
        min-width: 60px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #ccc;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #111;
        background: #fff;

        &:first-child {
            margin-left: 0px;
        }
    }
    .active {
        z-index: 2;
        color: $color-11st-red;
        border: 1px solid $color-11st-red;
    }
    .inp:disabled + .lab {
        color: #b7b7b7;
        border-color: #eee;
        background-color: #eee;
    }
}

.c-date__calender {
    font-size: 0;
    display: inline-block;
    vertical-align: middle;

    .c-dropdown:first-child {
        width: 120px !important;
        margin-right: 18px;

        .btn,
        .list {
            width: 120px !important;
        }
    }
    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;
    }
    .c-input {
        margin-right: 0;
    }
    .c-input .inp {
        width: 137px;
        letter-spacing: 0;
    }
    .c-input.time .inp {
        width: 80px;
        margin-left: 8px;
        text-overflow: clip;
        white-space: nowrap;
        overflow: visible;
        padding: 0 12px 0 12px;
    }
    .section-bar {
        display: inline-block;
        width: 27px;
        margin: 0;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        vertical-align: middle;
    }
    &.c-date__calender--size-32 {
        .c-input {
            .inp {
                height: 32px;
            }
            .input-etc {
                top: 1px;
                right: 1px;
                height: 30px;
                .btn-calender {
                    margin: 4px 8px;
                    &:before {
                        right: 37px;
                        height: 32px;
                        border-left: 1px solid $color-gray-12;
                    }
                }
            }
        }
        .section-bar {
            width: 18px;
        }

        + .c-btn--size-32 {
            margin-left: 3px;
            + .c-btn--icon-excel {
                margin-left: 8px;
            }
        }
    }
}

.c-hp {
    font-size: 0;

    .inp {
        width: 50px;
        padding: 0 12px 0 12px;
    }
    .c-input {
        margin-right: 0;
    }
    .c-input:not(:first-child) {
        .inp {
            width: 60px;
        }
        &:before {
            width: 6px;
            border-bottom: 1px solid #333;
            display: inline-block;
            margin: 19px 8px;
            vertical-align: middle;
            content: "";
        }
    }
    [class^="btn-style"] {
        margin: 0 0 0 8px;
    }
}

/* c-date__set 명칭 변경 */
.c-salesdate {
    .c-dropdown,
    .c-dropdown .btn,
    .c-dropdown .list {
        min-width: 120px !important;
    }
    .c-input.calender {
        margin-right: 7px;
    }
    .section-bar {
        display: inline-block;
        margin: 0 20px;
    }
    .c-button__group {
        display: inline-block;
        margin-left: 20px;

        button {
            margin-right: 10px;
        }
    }
    .c-salesdate__row {
        margin-top: 20px;

        &:first-child {
            margin-top: 0px;
        }
    }
}

.c-date__set {
    font-size: 0;

    .c-date__calender {
        margin-right: 8px;
    }
    .c-button__group {
        display: inline-block;
        margin-left: 20px;

        button {
            margin-right: 10px;
        }
    }
    .c-date__row {
        margin-top: 20px;

        &:first-child {
            margin-top: 0px;
        }
    }
}

.c-time {
    margin-top: 10px;

    .c-dropdown,
    .c-dropdown .btn,
    .c-dropdown .list {
        min-width: 100px !important;
    }
    .section-bar {
        margin: 0 15px;
    }
}

.c-noti__must {
    display: none;
    color: $color-11st-red;
    font-size: 13px;
    margin-left: 7px;
    line-height: 40px;
    vertical-align: middle;

    &.horizontal {
        display: inline-block;
    }
    &.active {
        display: block;
    }
    &.horizontal.active {
        display: inline-block;
    }
}

.c-noti__action {
    padding: 0 0 5px 0;
    font-size: 14px;
    color: #111;
    font-weight: bold;
    line-height: 20px;

    .count {
        color: $color-11st-red;
        font-weight: bold;
    }

    & + .summary-box {
        margin-top: 20px;
    }
}

.c-noti__care {
    position: relative;
    z-index: 1;
    padding-left: 28px;
    font-size: 14px;
    line-height: 22px;
    color: $color-11st-red;

    & + p,
    & + div,
    & + table {
        margin-top: 30px;
    }
    &:before {
        position: absolute;
        z-index: 1;
        top: 2px;
        left: 0;
        display: block;
        @include sprite-retina($sp_icon_2x_noti_care);
        vertical-align: middle;
        content: "";
    }
}

/* popup top noti */
.c-noti {
    .c-noti__button {
        margin-top: 10px;
    }

    & + .result-box {
        margin-top: 25px;
    }
}
.c-noti__list,
p.c-noti__list {
    font-size: 14px;
    line-height: 24px;
    color: $color-grayscale-02;
    letter-spacing: -0.44px;

    li {
        padding-left: 10px;
        letter-spacing: -0.44px;

        &:before {
            display: inline-block;
            width: 2px;
            height: 2px;
            margin-top: 2px;
            border-radius: 1px;
            color: #333;
            background-color: #333;
            content: "";
            vertical-align: top;
            -webkit-transform: translate(-8px, 9px);
            -o-transform: translate(-8px, 9px);
            transform: translate(-8px, 9px);
        }

        .line {
            display: block;
            text-decoration: underline;
        }
        + li {
            margin-top: 4px;
        }
    }
    & + p,
    & + div,
    & + table,
    & + ul {
        margin-top: 20px;
    }

    &.noti-style__2 {
        li:not(:first-child) {
            margin-top: 12px;
        }
    }

    &.noti-style__3 {
        padding: 26px 66px 26px 24px;
        background-color: $color-grayscale-10;
        border-radius: 4px;
        font-size: 13px;
        letter-spacing: -1px;
        color: $color-grayscale-04;

        li {
            padding-left: 0;
            line-height: 1.85;
            &::before {
                display: none;
            }
        }
    }

    &.c-noti__list--style3 {
        font-size: 13px;
        color: $color-gray-04;
        line-height: 19px;

        li {
            position: relative;
            padding-left: 8px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 2px;
                height: 2px;
                margin-top: 2px;
                border-radius: 1px;
                color: #333;
                background-color: #333;
                content: "";
                vertical-align: top;
                transform: translate(0px, 6px);
            }
        }
    }
    &.color-brand,
    .color-brand {
        li {
            color: $color-11st-red;
            &::before {
                color: $color-11st-red;
                background-color: $color-11st-red;
            }
        }
    }
    &--indent li {
        text-indent: -14px;
        padding-left: 14px;
        margin-top: 8px; //엑셀파일 다운로드 안내 팝업에만 쓰임. 분리 가능함.
    }
}

p.c-noti__list {
    padding-left: 10px;

    &:before {
        display: inline-block;
        width: 2px;
        height: 2px;
        margin-top: 2px;
        border-radius: 1px;
        color: #333;
        background-color: #333;
        content: "";
        vertical-align: top;
        -webkit-transform: translate(-8px, 9px);
        -o-transform: translate(-8px, 9px);
        transform: translate(-8px, 9px);
    }
}

.c-noti__list2 {
    font-size: 14px;
    line-height: 22px;
    color: #666;

    &--indent li {
        $textindexnt: 14px;
        text-indent: $textindexnt;
        padding-left: $textindexnt;
    }
    .point {
        color: $color-11st-blue;
    }

    & + p,
    & + div,
    & + table,
    & + .tbl_fixed,
    & + ul {
        margin-top: 20px;
    }
    & + .caption-open {
        margin-top: 58px;
    }
    li + li {
        margin-top: 4px;
    }
}

.c-result {
    &__list {
        border-top: 1px solid #eee;
        padding-top: 20px;

        > div {
            &:not(:first-of-type) {
                margin-top: 4px;
            }
        }

        dt,
        dd {
            display: inline-block;
            color: $color-grayscale-03;
        }

        dt {
            position: relative;
            padding-left: 10px;

            &::before {
                position: absolute;
                display: block;
                top: 10px;
                left: 0;
                width: 2px;
                height: 2px;
                background-color: #000;
                content: "";
            }

            &::after {
                display: inline-block;
                margin: 0 2px 0 3px;
                content: ":";
            }
        }
    }
}

.c-address__search {
    .c-address_info {
        margin-top: 20px;
    }
    .info {
        font-size: 13px;
        line-height: 22px;
        color: #666;
        margin-top: 30px;

        li {
            position: relative;
            z-index: 1;
            padding-left: 7px;

            &:before {
                position: absolute;
                z-index: 1;
                left: 0;
                top: 50%;
                display: inline-block;
                width: 5px;
                height: 1px;
                color: #333;
                background-color: #666;
                content: "";
                vertical-align: middle;
            }
        }
    }
}

.c-address__list {
    margin-top: 30px;
    min-height: 416px;
    max-height: 509px;
    border-top: 1px solid #eee;
    overflow: hidden;
    overflow-y: auto;

    .count {
        display: block;
        width: 100%;
        height: 44px;
        font-size: 13px;
        color: #111;
        line-height: 44px;
        border-bottom: 1px solid #eee;
        span {
            font-family: $font-family-number;
        }
    }
    .item {
        border-bottom: 1px solid #eee;
    }
    .item-box {
        position: relative;
        z-index: 1;
        padding: 16px 75px 16px 10px;
        min-height: 90px;
        border: 1px solid #fff;

        &:hover {
            border: 1px solid $color-11st-red;
        }
    }
    .address_icon {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        display: inline-block;
        width: 56px;
        height: 24px;
        margin-right: 10px;
        background-color: #fff;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
    .road_name {
        position: relative;
        z-index: 1;
        display: block;
        color: #111;
        text-align: left;
        padding-left: 66px;

        .address_icon {
            border: 1px solid #0b83e6;
            color: #0b83e6;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    .land_lot {
        position: relative;
        z-index: 1;
        display: block;
        margin-top: 10px;
        color: #111;
        text-align: left;
        padding-left: 66px;

        .address_icon {
            border: 1px solid $color-grayscale-05-1;
            color: $color-grayscale-05-1;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    .zipcode {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 0;
        height: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        color: #111;

        .code {
            display: block;
        }
    }
}

.c-address__notice {
    margin-top: 20px;
    height: 389px;
    border-top: 1px solid #eee;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #111;
    line-height: 30px;
}

.c-send__area {
    .item-box {
        position: relative;
        z-index: 1;
        min-height: 130px;
        padding-right: 75px;
    }
    .item {
        border-bottom: 1px solid #eee;
    }
    .radio-style__1 .lab {
        font-weight: bold;
    }
    .info {
        padding-left: 40px;
        font-size: 14px;
        line-height: 24px;
        color: #111;

        .tel {
            font-family: $font-family-number;
            letter-spacing: -0.44px;
        }
        .tel + .tel {
            margin-left: 30px;
        }
    }
    .btn {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 20px;
        width: 56px;
        height: 100%;

        button:not(:first-child) {
            margin-top: 8px;
        }
    }
}

.c-material {
    font-size: 0;

    .c-input:not(:first-child) {
        margin-left: 10px !important;
    }
    .c-button.del-plus {
        vertical-align: bottom;
        margin-left: 8px;
    }
    .c-material__row:not(:first-child) {
        margin-top: 10px;

        .lab {
            display: none;
        }
    }
}

.htmleditor {
    position: relative;
    margin: 10px;

    .cpeditor_toolbar {
        position: absolute;
        width: 100%;
        height: 90px;
        z-index: 1;
        display: none;
    }
}

.c-stxt {
    display: inline-block;
    font-size: 13px;
    color: #666;
    line-height: 26px;
    vertical-align: middle;

    &:before {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: #666;
        content: "";
        margin: 0 10px 0 20px;
        line-height: 26px;
        vertical-align: middle;
    }
}

.c-pick {
    position: relative;
    z-index: 1;
    padding: 10px 20px;
    background-color: #fafafa;
    @include clearfix();

    &:before {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        margin-top: -35px;
        margin-left: -4px;
        border: 1px solid $color-grayscale-05-1;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        content: "";
    }
    .c-pick__left {
        width: calc(50% - 41px);
        float: left;
    }
    .c-pick__right {
        width: calc(50% - 41px);
        float: right;
    }
    .c-pick__title {
        font-size: 14px;
        color: #000;
        line-height: 40px;
    }
    .c-pick__list {
        width: 100%;
        border: 1px solid #ddd;
        background-color: #fff;
        height: 320px;
        overflow: hidden;
        overflow-y: auto;
    }
    .c-pick__buttom {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        padding: 10px 0;
    }
}

.c-tel {
    .c-dropdown .btn {
        width: 130px;
    }
    .c-input .input-text .inp {
        width: 140px;
    }
    .c-input {
        &:before {
            display: inline-block;
            margin: 0 8px;
            content: "-";
            vertical-align: middle;
            line-height: 40px;
        }
    }
}

.c-img {
    display: inline-block;

    &--wide {
        img {
            width: 100%;
            height: auto;
        }
    }
    &--2x {
        img {
            width: 50%;
        }
    }

    &--package-size {
        width: 208px;

        img {
            width: 100%;
        }
    }
    // 간격
    &--margin {
        &-20 {
            margin: 20px 0;
        }
    }
}
//단일상품등록 - 검색/추천설정(검색 적용 태그 확인)
.c-tag__check {
    .tag-check__title {
        font-size: 14px;
        color: #111;
        line-height: 60px;
        font-weight: normal;

        .good,
        .bad {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 1px solid #0b83e6;
            border-radius: 9px;
            margin: -2px 0 0 10px;
            content: "";
            vertical-align: middle;
            overflow: hidden;
        }
        .bad {
            border-color: $color-11st-red;
        }
        .bad:before,
        .bad:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 1px;
            background-color: $color-11st-red;
        }
        .bad:before {
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
        }
        .bad:after {
            -webkit-transform: translate(-50%, -50%) rotate(135deg);
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    .tag-check__title:not(:first-child) {
        margin-top: 11px;
        border-top: 1px solid #eee;
    }
}
//단일상품등록 - 판매자 태그관리
.c-tag__list {
    font-size: 0;
    margin-top: -10px;

    li {
        display: inline-block;
        width: 25%;
        padding-right: 10px;
        margin-top: 10px;

        &:nth-child(4n) {
            padding-right: 0;
        }
    }
    .c-tag__key {
        position: relative;
        z-index: 1;
        border: 1px solid $color-grayscale-05-1;
        background-color: #fff;
        width: 100%;
        padding: 0 13px;
        line-height: 32px;
        font-size: 14px;
        color: #333;
        letter-spacing: -0.5px;
    }
    .btn-del {
        position: absolute;
        z-index: 1;
        top: 5px;
        right: 10px;
        width: 22px;
        height: 22px;
        line-height: 200px;
        overflow: hidden;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 14px;
            height: 1px;
            background-color: $color-grayscale-05-1;
        }
        &:before {
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
            -webkit-transform: translate(-50%, -50%) rotate(135deg);
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    .inp {
        width: 100%;
        font-size: 14px;
        letter-spacing: -0.5px;
        border: none;

        &::-webkit-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        }
        &::-moz-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* firefox 19+ */
        &::-ms-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* ie */
    }
}

.c-card {
    margin-top: 10px;
    border-top: 1px solid #eee;

    .c-card__title {
        color: #111;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 60px;
    }
    .c-card__list {
        font-size: 0;

        li {
            display: inline-block;
            width: calc(50% - 3px);
            margin-bottom: 10px;
            font-size: 0;
        }
        li:nth-child(2n) {
            margin-left: 6px;
        }

        &.wide {
            li {
                width: 100%;
            }
        }
    }
    .c-card__item {
        display: table;
        width: 100%;
        height: 48px;
        background-color: #fff;
        border: 1px solid #ddd;
        line-height: 18px;

        .c-card__icon {
            display: table-cell;
            width: 135px;
            color: #666;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.5px;
            vertical-align: middle;

            &:before {
                display: inline-block;
                content: "";
                vertical-align: middle;
            }
            &.c-card__bc {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_bc);
                }
            }
            &.c-card__citi {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_citi);
                }
            }
            &.c-card__hyundai {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_hyundai);
                }
            }
            &.c-card__kb {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_kb);
                }
            }
            &.c-card__nh {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_nh);
                }
            }
            &.c-card__samsung {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_samsung);
                }
            }
            &.c-card__shinhan {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_shinhan);
                }
            }
            &.c-card__sc {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_standard);
                }
            }
            &.c-card__woori {
                &:before {
                    @include sprite-retina($sp_icon_2x_card_woori);
                }
            }
        }
        .c-card__benefit {
            display: table-cell;
            color: #666;
            font-size: 13px;
            line-height: 48px;
            letter-spacing: -0.5px;
            text-align: left;
        }
    }
}

.c-agree {
    border-top: 1px solid #ddd;
    padding: 20px 0 10px;
    margin-top: 20px;

    .subtxt {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.46px;
        color: #666;
        padding-left: 44px;
    }

    &.c-agree__style2,
    &.c-agree--style2 {
        text-align: center;
        [class^="check-style"] {
            margin: 0 10px;
        }
        .subtxt {
            padding-left: 0;
        }
    }

    &.c-agree--style3 {
        border-top: none;
        padding: 0;
        .check-style__2 .lab {
            color: $color-grayscale-03;
            font-size: 13px;
        }
    }
}

// 동영상 등록 안내
.mov-pop__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;

    .mov-pop {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        width: 800px;
        height: 100vh;
        max-height: 740px;
        background: #f4f4f4;
        transform: translate(-50%, -50%);

        .pop-body {
            max-height: calc(100vh - 64px);
            overflow: hidden;
            overflow-y: auto;
        }

        .pop-close {
            z-index: 50;
            position: absolute;
            top: 30px;
            right: 30px;
            display: block;
            width: 40px;
            height: 40px;
            line-height: 200px;
            overflow: hidden;
            background-color: #f4f4f4;
        }

        .swiper-mov__box {
            position: relative;
            h4 {
                display: block;
                overflow: hidden;
                line-height: 200px;
                font-size: 0;
                margin: 0 0 35px;
            }

            .swiper-mov {
                position: relative;
                height: 740px;
                width: 800px;
                overflow: hidden;

                .swiper-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    display: flex;
                    box-sizing: content-box;
                }

                .swiper-slide {
                    position: relative;
                    display: flex;
                    width: 800px;
                    align-items: center;
                    flex-shrink: 0;
                    padding: 0 0 0 90px;
                    box-sizing: border-box;
                }

                .slide-btn__prev,
                .slide-btn__next {
                    z-index: 50;
                    position: absolute;
                    top: 68px;
                    bottom: 68px;
                    display: block;
                    width: 60px;
                    background: #f4f4f4;
                    text-align: center;

                    button {
                        position: absolute;
                        top: 50%;
                        display: block;
                        width: 40px;
                        height: 40px;
                        margin-top: -20px;
                        overflow: hidden;
                        line-height: 200px;

                        &.swiper-button-disabled {
                            cursor: default;
                            opacity: 0.3;
                        }
                    }
                }

                .slide-btn__prev {
                    button {
                        left: 20px;

                        &:before {
                            display: block;
                            @include ico-angle(left, 24px, #666, 1px, absolute);
                            left: 25%;
                            top: 18px;
                        }
                    }
                }

                .slide-btn__next {
                    right: 0;
                    width: 110px;

                    button {
                        right: 20px;

                        &:before {
                            display: block;
                            @include ico-angle(right, 24px, #666, 1px, absolute);
                            right: 25%;
                            top: 18px;
                        }
                    }
                }

                .mov-pop__info {
                    h4 {
                        @include sprite($sp_main_pop_mov_tit_info);
                        margin: 0;
                    }
                }

                .mov-pop__info2 {
                    h4 {
                        @include sprite($sp_main_pop_mov_tit_info2);
                        margin-bottom: 23px;
                    }

                    .txt__p {
                        font-size: 15px;
                    }
                }

                .mov-pop__info3 {
                    h4 {
                        @include sprite($sp_main_pop_mov_tit_info3);
                    }
                }

                .mov-pop__info4 {
                    h4 {
                        @include sprite($sp_main_pop_mov_tit_info4);
                    }

                    .txt__p {
                        margin-bottom: 18px;
                    }

                    .btn-style__2 {
                        padding: 0 23px;
                        font-weight: bold;

                        &:hover {
                            background: #fff;
                        }

                        &:after {
                            display: inline-block;
                            margin-left: 10px;
                            @include ico-angle(right, 8px, #ff5462, 1px, static);
                        }
                    }
                }

                .txt__p {
                    font-size: 16px;
                    color: #666;
                    line-height: 24px;
                    letter-spacing: -0.6px;

                    strong {
                        display: inline;
                        font-weight: normal;
                        color: #111;
                        border-bottom: 1px solid #111;

                        em {
                            font-weight: bold;
                        }

                        &.txt-red {
                            color: $color-11st-red;
                            border-color: $color-11st-red;
                        }
                    }
                }

                .txt__p-bg-red {
                    mark {
                        display: inline;
                        padding: 0 9px 0 1px;
                        line-height: 24px;
                        font-size: 15px;
                        font-weight: bold;
                        background-color: rgba(244, 49, 66, 0.2);

                        strong {
                            color: $color-11st-red;
                        }
                    }

                    .txt__span-small {
                        display: block;
                        margin: 5px 0 24px 0;
                        font-size: 11px;
                        color: #666;
                        letter-spacing: -0.4px;
                    }
                }
            }

            .mov-box {
                z-index: 60;
                position: absolute;
                top: 63px;
                right: 110px;
                width: 285px;
                height: 615px;
                border-radius: 40px;
                box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
                background-color: #ffffff;

                &:before {
                    content: "";
                    position: absolute;
                    top: 23px;
                    left: 113px;
                    display: block;
                    width: 59px;
                    height: 6px;
                    opacity: 0.39;
                    border-radius: 3.5px;
                    background-color: #d8d8d8;
                }

                .mov-box__in {
                    position: absolute;
                    top: 45px;
                    left: 20px;
                    width: 245px;
                    height: 538px;
                    background: #f4f4f4;
                    overflow: hidden;

                    &:after {
                        content: "";
                        z-index: 10;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }

                    video {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        display: block;
                        width: auto;
                        height: 538px;
                        transform: translate(-50%, 0);
                    }
                }
            }
        }
    }
}

//배송관리 넛징 팝업
.custom-delivery-nudging {
    .delivery-nudging__top {
        line-height: 32px;
        letter-spacing: -0.62px;
        color: #111;
        text-align: center;

        .noti {
            font-size: 20px;
            font-weight: bold;
            text-align: left;
        }
        .name {
            color: $color-secondary;
            font-weight: bold;
        }
        .number {
            font-family: $font-family-number;
            font-weight: bold;
        }
        .box {
            padding: 16px 0;
            margin: 20px 0;
            border: solid 1px #f4f4f4;
            background-color: #fafafa;
            text-align: center;
            font-size: 14px;

            .gap {
                display: inline-block;
                margin: 17px 40px 17px 54px;
                padding: 0 44px 0 30px;
                @include sprite($sp_delivery_deliver_gap);
                overflow: hidden;
                vertical-align: middle;
                color: #fff;
                font-size: 16px;
                letter-spacing: -0.5px;
                line-height: 28px;
                font-weight: bold;
                text-align: center;
            }
            strong {
                font-size: 18px;
                margin-left: 10px;
            }
        }
        [class^="btn-style"] {
            margin: 0 5px;
        }
    }
    .delivery-nudging__tip {
        margin-top: 60px;
        padding: 29px 0 0 0;
        border-top: 1px solid #eee;

        .title {
            font-size: 16px;
            line-height: 41px;
            letter-spacing: -0.5px;
            color: #111;

            &::before {
                display: inline-block;
                margin: -4px 11px 0 0;
                vertical-align: middle;
                @include sprite($sp_delivery_icon_tip);
                content: "";
            }
        }
        .tip1 {
            position: relative;
            padding: 25px 0 40px 281px;
            margin: 20px 0 50px 0;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.56px;
            color: #111;

            .color_brand {
                border-bottom: 1px solid $color-11st-red;
            }
            .sub_txt {
                font-size: 12px;
                color: $color-grayscale-05-1;
                vertical-align: sub;
                margin-left: 5px;
            }
            &::before {
                position: absolute;
                top: 0;
                left: 52px;
                display: inline-block;
                vertical-align: middle;
                @include sprite($sp_delivery_tip1);
                content: "";
            }
        }
        .tip2 {
            font-size: 14px;
            color: #111;
            line-height: 22px;
            letter-spacing: -0.35px;
            text-align: center;

            li {
                float: left;
                display: inline-block;
                width: 324px;
                padding: 10px 0 30px;

                &:nth-of-type(2n) {
                    float: right;
                }
                &:after {
                    display: block;
                    margin-top: 8px;
                    content: "";
                }
                &.tip2_item1:after {
                    @include sprite($sp_delivery_tip2_1);
                }
                &.tip2_item2:after {
                    @include sprite($sp_delivery_tip2_2);
                }
                &.tip2_item3:after {
                    @include sprite($sp_delivery_tip2_3);
                }
                &.tip2_item4:after {
                    @include sprite($sp_delivery_tip2_4);
                }
            }

            @include clearfix();
        }
    }
}
// 프로모션 관리 > 프로모션 상세 > 참프로모션 신청: sk pay point 적립
.custom-prd__list {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;

    .item {
        &:not(:first-child) {
            .inp:disabled {
                border-top: none;
            }
            .btn-style__1 {
                border-top: none;
            }
            .point {
                border-top: none;
            }
        }
    }
    .inp:disabled {
        margin-right: 4px;
        width: 295px;
    }
    .btn-style__1 {
        margin-left: 4px;
        min-width: 80px !important;
    }
}
//리테일 상품등록 > 상품명 > 가격비교 > 모델명
.custom-model {
    display: flex;

    &-item {
        display: flex;
        flex-grow: 1;
        padding: 0;
        margin: 0;

        &__info {
            padding: 0 15px;

            &:first-child {
                padding-left: 0;
            }
        }

        &__thumb {
            position: relative;
            width: 82px;
            height: 82px;
            min-width: 82px;
            border: 1px solid #eee;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        &__name {
            color: $color-grayscale-01;
            font-size: 14px;
            font-weight: bold;
            line-height: 20px;
        }

        &__attr {
            &:not(:first-child) {
                margin-top: 10px;
            }

            dd {
                display: inline;
                color: #666;
                font-size: 13px;

                &:not(:last-of-type) {
                    &::after {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        margin: 0 8px 0 10px;
                        background-color: #ddd;
                        content: "";
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    &__button {
        width: 87px;
    }
}
//리테일 상품등록 > 상품설명 > 11에디터

.choice {
    &-character {
        button {
            font-size: 18px;
            font-weight: bold;
            color: $color-grayscale-03;
            margin-right: 10px;

            &:hover {
                text-decoration: underline;
            }

            &.active {
                color: $color-11st-red;
                font-weight: bold;
                margin-right: 9px;
            }
        }

        &--kor {
            button {
                font-size: 19px;
            }
        }

        &--eng {
            button {
                font-size: 19px;
            }
        }

        &__row {
            font-size: 0;

            &:not(:first-child) {
                margin-top: 10px;
            }

            @include clearfix();
        }

        &__col {
            float: left;
        }

        & + .c-box {
            margin-top: 30px;
        }

        @include clearfix();
    }
}

.dialog {
    .dialog-bottom {
        .check-style__2.left {
            position: absolute;
            left: 24px;
        }
    }

    .duplicate_registration_product {
        text-align: center;

        p {
            font-size: 14px;
            line-height: 20px;
            margin: 0 auto 30px;
            color: #111;
        }

        .img {
            img {
                display: block;
                width: 607px;
                height: 178px;
                margin: auto;
                height: 176px;
            }
        }
    }
}

.btn_big_chat {
    @include sprite-retina($sp_icon_2x_ico_big_chat);
    z-index: 10;
    position: fixed;
    bottom: 26px;
    right: 36px;
    display: inline-block;
    padding: 22px 2px 0 0;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0;
    font-weight: bold;
    color: #fff;
}

//11번가 파트너 애널리틱스 오픈 안내
.pop_partner_analytics_paid {
    .popup-header {
        max-width: 980px;
    }
    .popup-body {
        max-width: 980px;
        text-align: center;
    }
    .title {
        font-size: 30px;
        color: #111;
    }
    .text {
        margin-top: 2px;
        font-size: 13px;
        color: #666;
    }
    .text_box {
        margin-top: 30px;
        font-size: 13px;
        color: #111;
        .point {
            margin-top: 6px;
            font-weight: bold;
            strong {
                color: #5483fd;
            }
        }
    }
    .paid_info {
        margin: 30px auto 37px auto;
        width: 442px;

        ul {
            text-align: center;
            display: flex;
            .type {
                position: relative;
                width: 205px;
                height: 190px;
                padding: 12px 17px;
                margin: 0 8px 0 8px;
                text-align: left;
                letter-spacing: 0;
                border-radius: 10px;
                border: 1.5px solid rgba(84, 131, 253, 0.5);
                background: rgba(84, 131, 253, 0.03);
                .info {
                    dt {
                        font-size: 17px;
                        font-weight: bold;
                        font-family: "roboto";
                    }
                    dd {
                        overflow: hidden;
                        color: #111;
                        margin-top: -6px;
                        .value {
                            float: left;
                            vertical-align: middle;
                            font-size: 17px;
                            font-family: "roboto";
                        }
                        .unit {
                            float: left;
                            font-size: 11px;
                            vertical-align: middle;
                            margin-top: 5px;
                        }
                        span {
                            float: left;
                            margin-top: 5px;
                            vertical-align: middle;
                            font-size: 11px;
                        }
                    }
                }
                .text {
                    margin-top: 7px;
                    font-size: 10px;
                    line-height: 1.4;
                    color: #666;
                    strong {
                        color: #5483fd;
                    }
                }
                .report {
                    margin-top: 10px;
                    border-top: 1px solid rgba(0, 0, 0, 0.05);
                    dt {
                        padding-top: 8px;
                        font-size: 10px;
                        font-weight: bold;
                        color: #111;
                    }
                    dd {
                        padding-top: 4px;
                        ul {
                            display: block;
                            li {
                                position: relative;
                                text-align: left;
                                font-size: 10px;
                                color: #666;
                                span {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
                .badge_free {
                    display: block;
                    position: absolute;
                    bottom: -20px;
                    right: -20px;
                    width: 88.5px;
                    height: 88.5px;
                    text-indent: -9999px;
                    background: url("/img/selleroffice/main_notice_pop/partner_paid_02.png") no-repeat;
                    background-size: 100%;
                }

                &.basic {
                    .info {
                        dt {
                            color: #5483fd;
                        }
                    }
                }
                &.silver {
                    .info {
                        dt {
                            color: #ff0038;
                        }
                    }
                }
            }
        }
    }
    .list_box {
        width: 512px;
        margin: 0 auto;
        padding: 12px 19px 11px 19px;
        text-align: left;
        background: #fafafa;
        li {
            position: relative;
            padding: 0 0 0 11px;
            font-size: 12px;
            color: #949494;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 9px;
                left: 0;
                width: 3px;
                height: 3px;
                border-radius: 5px;
                background: #949494;
            }
        }
    }

    .list_box_02 {
        width: 512px;
        margin: 0 auto;
        padding: 12px 19px 15px 19px;
        text-align: center;
        background: #fafafa;
        ul {
            li {
                position: relative;
                padding: 0 0 0 11px;
                font-size: 12px;
                color: #949494;
                text-align: left;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 3px;
                    height: 3px;
                    border-radius: 5px;
                    background: #949494;
                }
            }
        }
        .btn_link {
            display: inline-block;
            height: 30px;
            margin-top: 12px;
            padding: 0 10px 0 10px;
            font-size: 12px;
            line-height: 27px;
            color: #111;
            border: 1px solid #ccc;
            background: #fff;
        }
        .button_box {
            .btn_link {
                margin: 12px 0 0 6px;
            }
        }
        + .text_box {
            margin-top: 50px;
        }
    }

    .infographic_box {
        width: 508px;
        height: 220px;
        margin: 30px auto 26px auto;
        background: url("/img/selleroffice/main_notice_pop/partner_paid_01.png") no-repeat;
        background-size: 100%;
    }
}

//상품목록 대량 엑셀 다운로드
.popup-bulk-product-excel {
    .c-guide-list--bullet {
        li {
            margin-bottom: 4px;
        }
        + .b-frame {
            margin-top: 29px;
            .search-box {
                margin-top: 20px;
                .c-title {
                    &__text {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .b-frame {
        margin-top: 29px;

        .grid-box {
            border: none;
        }
    }
}

//기본즉시할인 설정
.popup-instant-discount {
    .b-box {
        &.b-box--style7 {
            border: 0;
            padding: 0;
            &:first-child {
                .b-box__header {
                    margin-top: 0;
                }
            }
            .b-box__header {
                height: auto;
                padding: 0;
                margin: 20px 0 0 0;
                border: 0;
                line-height: normal;
                .c-title__text {
                    font-size: 16px;
                    line-height: 24px;
                }
                + .search-box {
                    margin-top: 20px;
                }
            }
            & + .b-box--style7 {
                margin-top: 0;
                border-top: none;
            }
            .b-box__body {
                .b-box-sub--style2 {
                    margin-bottom: 0;
                }
                + .b-box__bottom {
                    padding: 15px 0;
                    border-top: none;
                }
                .result-box {
                    border-top: none;
                    padding: 0;
                    margin-top: 10px;
                    &__header {
                        &__left {
                            .info {
                                font-size: 13px;
                                color: #666;
                                strong {
                                    color: #000;
                                    font-weight: normal;
                                }
                            }
                        }
                        &__right {
                            margin-top: -13px;
                        }
                    }
                }
                .c-guide {
                    &.c-guide--style6 {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.popup-automate-pricing {
    .search-box {
        margin-top: 20px;
    }
    .b-frame {
        &:first-child {
            margin-top: 0;
        }
        margin-top: 19px;
        .result-box {
            border-top: none;
            padding: 0;
            margin-top: 10px;
            &__header {
                &__left {
                    .info {
                        font-size: 13px;
                        color: #666;
                        strong {
                            color: #000;
                            font-weight: normal;
                        }
                    }
                }
                &__right {
                    margin-top: -13px;
                }
            }
        }
        .search-box {
            .c-title {
                .c-title__text {
                    font-size: 14px;
                }
            }
            .c-form {
                .dropdown {
                    .c-input {
                        margin-right: 0;
                        border-right: 1px solid #f4f4f4;
                    }
                }
            }
        }
    }
}

.review-event-register {
    .event-benefits-setting {
        dl {
            width: 400px;
            padding: 4px 25px 15px;
            border: 1px solid $color-gray-11;
            background: $color-gray-14;
            div {
                @include clearfix;
                padding: 11px 0 0 0;
                dt {
                    float: left;
                    width: 140px;
                    font-size: 14px;
                    color: $color-gray-02;
                }
                dd {
                    position: relative;
                    float: left;
                    width: 208px;
                    padding: 0 70px 0 0;
                    text-align: right;
                    color: $color-gray-02;
                    button {
                        position: absolute;
                        top: -2px;
                        right: 0;
                    }
                }
            }
        }
    }
    .event-proceed-agree {
        padding-left: 20px;
        .c-form {
            margin-top: 20px;
            padding-top: 10px;
            border-top: 1px solid $color-gray-11;
            + .c-guide {
                margin-top: 0px;
            }
        }
        .c-guide-list {
            &--bullet {
                li {
                    &:before {
                        top: 10px;
                    }
                }
            }
        }
    }
    .event-status-info {
        position: relative;
        padding: 15px 24px;
        background: $color-gray-13;
        .text {
            padding-right: 120px;
            font-size: 13px;
            color: $color-gray-04;
        }
        .btn-event-floding {
            position: absolute;
            top: 15px;
            right: 24px;
            font-size: 13px;
            color: $color-gray-04;
            &::after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin: -5px 0 0 9px;
                width: 6px;
                height: 6px;
                border: 1px solid #949494;
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
                background-color: transparent;
            }

            &--closed {
                &::after {
                    transform: rotate(-45deg);
                    margin-top: 0;
                }
            }
        }
        + .table-box {
            margin-top: 20px;
        }
    }
    .tbl-event-info {
        th,
        td {
            font-size: 14px;
        }
        .current-info {
            @include clearfix();
            div {
                display: inline-block;
                vertical-align: middle;
                dt {
                    display: inline-block;
                    vertical-align: middle;
                }
                dd {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    button {
                        margin-top: -5px;
                        color: $color-gray-02;
                    }
                    .line {
                        font-weight: bold;
                        text-decoration: underline;
                        cursor: pointer;
                        color: $color-gray-02;
                    }
                }
                &:not(:first-child) {
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 1px;
                        height: 15px;
                        background: #ddd;
                        margin: 0 10px;
                        vertical-align: middle;
                        line-height: 32px;
                    }
                }
                &.failed {
                    color: $color-brand;
                    dt {
                        &:before {
                            content: "";
                            @include sprite-retina($sp_icon_2x_info_red_18_2);
                            display: inline-block;
                            vertical-align: middle;
                            margin: -4px 4px 0 0;
                        }
                    }
                    dd {
                        .line {
                            color: $color-brand;
                        }
                    }
                }
            }
        }
    }
    .review-detail {
        @include clearfix;
        width: 654px;
        padding: 5px;
        .image-box {
            float: left;
            width: 300px;
            .image-view-box {
                position: relative;
                width: 300px;
                height: 400px;
                background: #000;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 300px;
                    max-height: 400px;
                    transform: translate(-50%, -50%);
                }
                video {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    max-width: 300px;
                    max-height: 400px;
                    object-fit: contain;
                    transform: translate(-50%, -50%);
                }
            }
            .image-list-box {
                @include clearfix;
                li {
                    float: left;
                    position: relative;
                    width: 44px;
                    height: 44px;
                    margin: 5px 5px 0 0;
                    background: #000;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        max-width: 44px;
                        max-height: 44px;
                        transform: translate(-50%, -50%);
                    }
                    &.active {
                        &:before {
                            content: "";
                            z-index: 2;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 44px;
                            height: 44px;
                            border: 2px solid #000;
                        }
                    }
                    &.type_video {
                        &:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            display: block;
                            @include sprite-retina($sp_icon_2x_ico_video_play);
                        }
                    }
                }
            }
        }
        .info-box {
            float: left;
            width: 324px;
            margin: 0 0 0 20px;
            .c-grade {
                @include sprite-retina($sp_icon_2x_ico_grade_off);
                position: relative;
                display: inline-block;

                &:before {
                    content: "";
                    @include sprite-retina($sp_icon_2x_ico_grade_on);
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                }
                &.grade_0 {
                    &:before {
                        width: 0%;
                    }
                }
                &.grade_5 {
                    &:before {
                        width: 5%;
                    }
                }
                &.grade_10 {
                    &:before {
                        width: 10%;
                    }
                }
                &.grade_15 {
                    &:before {
                        width: 15%;
                    }
                }
                &.grade_20 {
                    &:before {
                        width: 20%;
                    }
                }
                &.grade_25 {
                    &:before {
                        width: 25%;
                    }
                }
                &.grade_30 {
                    &:before {
                        width: 30%;
                    }
                }
                &.grade_35 {
                    &:before {
                        width: 35%;
                    }
                }
                &.grade_40 {
                    &:before {
                        width: 40%;
                    }
                }
                &.grade_45 {
                    &:before {
                        width: 45%;
                    }
                }
                &.grade_50 {
                    &:before {
                        width: 50%;
                    }
                }
                &.grade_55 {
                    &:before {
                        width: 55%;
                    }
                }
                &.grade_60 {
                    &:before {
                        width: 60%;
                    }
                }
                &.grade_65 {
                    &:before {
                        width: 65%;
                    }
                }
                &.grade_70 {
                    &:before {
                        width: 70%;
                    }
                }
                &.grade_75 {
                    &:before {
                        width: 75%;
                    }
                }
                &.grade_80 {
                    &:before {
                        width: 80%;
                    }
                }
                &.grade_85 {
                    &:before {
                        width: 85%;
                    }
                }
                &.grade_90 {
                    &:before {
                        width: 90%;
                    }
                }
                &.grade_95 {
                    &:before {
                        width: 95%;
                    }
                }
                &.grade_100 {
                    &:before {
                        width: 100%;
                    }
                }
            }
            .meta {
                padding: 5px 0 0 0;
                .name {
                    font-size: 13px;
                    color: $color-gray-04;
                }
                .data {
                    font-size: 13px;
                    color: $color-gray-04;
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 2px;
                        height: 2px;
                        margin: -2px 5px 0 2px;
                        border-radius: 4px;
                        vertical-align: middle;
                        background: $color-gray-10;
                    }
                }
            }
            .cont {
                margin-top: 7px;
                font-size: 14px;
                line-height: 1.43;
                color: $color-gray-02;
            }
            .review_check {
                display: block;
                margin-top: 3px;
                font-size: 13px;
                color: $color-gray-04;
            }
        }
    }
    .b-box-sub {
        + .b-box-sub__bottom {
            border-top: none;
        }
    }
}

.review-event-benefits-paid {
    .information {
        padding-bottom: 15px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-gray-11;
        div {
            @include clearfix;
            color: $color-gray-02;
            min-height: 40px;
            padding: 9px 0 9px 0;
            dt {
                float: left;
                width: 160px;
            }
            dd {
                float: left;
                .total-benefit-point {
                    font-size: 16px;
                }
                .benefit-point {
                    padding: 0;
                    min-height: auto;
                    button {
                        margin-left: 8px;
                    }
                }
                .error-msg {
                    margin-top: 10px;
                    font-size: 14px;
                    color: $color-brand;
                }
            }
            &:first-child {
                min-height: 31px;
                padding: 0 0 9px 0;
            }
        }
    }
}

.c-count {
    display: inline-block;
    color: $color-gray-04;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.7px;
    &__stat {
        font-size: 13px;
        color: $color-gray-04;
    }

    @at-root .grid-box .grid-box__filter .filter__right & {
        height: 3px;
        vertical-align: bottom;
    }
}

.sku_status_info_box {
    @include clearfix;
    padding: 20px 24px 18px 24px;
    li {
        float: left;
        position: relative;
        padding: 0 40px 0 40px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: 2px;
            right: 0;
            border-right: 1px solid $color-gray-11;
            height: 20px;
        }
        dl {
            color: $color-gray-03;
            font-size: 14px;
            overflow: hidden;
            dt {
                float: left;
                padding: 5px 0 0 0;
                button {
                    display: inline-block;
                    vertical-align: middle;
                    margin: -6px 0 0 5px;
                    font: 0/0 arial;
                }
                .gicon-question {
                    margin: -4px 0 0 2px;
                }
            }
            dd {
                float: left;
                position: relative;
                padding: 0 0 0 8px;
                strong {
                    font-size: 18px;
                    color: $color-gray-02;
                    margin-right: 5px;
                }
            }
            &:hover {
                color: $color-11st-red;
                dd {
                    strong {
                        text-decoration: underline;
                        color: $color-11st-red;
                    }
                }
            }
        }
        &.active {
            dl {
                color: $color-11st-red;
                dd {
                    strong {
                        text-decoration: underline;
                        color: $color-11st-red;
                    }
                }
            }
        }

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            &:before {
                display: none;
            }
        }
    }
}

.sku_status_board {
    position: relative;
    padding: 0 20px;

    &--info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        li {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: max-content;

            &.active {
                dl {
                    color: $color-11st-red;
                    dd {
                        strong {
                            text-decoration: underline;
                            color: $color-11st-red;
                        }
                    }
                }
            }
        }
        dl {
            overflow: hidden;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            // gap: 8px;
            color: $color-gray-03;
            font-size: 14px;
            dt {
                // float: left;
                flex: 0 0 auto;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: max-content;
                padding-top: 5px;
                padding-right: 8px;
                button {
                    display: inline-block;
                    vertical-align: middle;
                    margin: -6px 0 0 5px;
                    font: 0/0 arial;
                }
            }
            dd {
                // float: left;
                position: relative;
                // padding: 0 0 0 8px;
                strong {
                    font-size: 18px;
                    color: $color-gray-02;
                    margin-right: 5px;
                }
            }
            &:hover {
                color: $color-11st-red;
                dd {
                    strong {
                        text-decoration: underline;
                        color: $color-11st-red;
                    }
                }
            }
            .gicon {
                margin: -4px 0 0 2px;
            }
        }
    }
    &__divider {
        display: block;
        width: 1px;
        height: 20px;
        flex: 0 1 1px;
        background: $color-gray-11;
    }

    &--right {
        position: absolute;
        top: 50%;
        right: 24px;
        margin: -10px 0 0 0;
        .text {
            font-size: 14px;
            color: $color-gray-06;
        }
    }
}

.sku_barcode {
    padding: 16px 0;
    text-align: center;
    .barcode {
        width: 340px;
        height: 128px;
    }
}

//4PL
.pl_shipment_status_info_box {
    @include clearfix;
    padding: 20px 24px 18px 24px;
    li {
        float: left;
        padding: 0 20px 0 20px;
        dl {
            color: $color-gray-03;
            font-size: 14px;
            dt {
                button {
                    display: inline-block;
                    vertical-align: middle;
                    margin: -6px 0 0 5px;
                    font: 0/0 arial;
                }
            }
            dd {
                position: relative;
                strong {
                    font-size: 18px;
                    color: $color-gray-02;
                    margin-right: 5px;
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 2px;
                    right: -20px;
                    border-right: 1px solid $color-gray-11;
                    height: 20px;
                }
            }
            &:hover {
                color: $color-11st-red;
                dd {
                    strong {
                        text-decoration: underline;
                        color: $color-11st-red;
                    }
                }
            }
        }
        &.active {
            dl {
                color: $color-11st-red;
                dd {
                    strong {
                        text-decoration: underline;
                        color: $color-11st-red;
                    }
                }
            }
        }

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            dl {
                dd {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.pl_order_product_list {
    .order_product {
        position: relative;
        display: flex;
        align-items: stretch;
        margin-right: 24px;
        .b-box-sub {
            flex-grow: 1;
            margin-right: 0px;
            &--style2 {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .button-del-plus {
            flex-shrink: 0;
            position: relative;
            min-width: 92px;
            padding: 11px 0 11px 10px;
            button {
                position: absolute;
                left: 10px;
                bottom: 11px;
                + button {
                    left: 55px;
                }
            }
        }
    }
}

.pl_data_table_box {
    table {
        &.tbl {
            tbody {
                tr {
                    td {
                        .text {
                            overflow: hidden;
                            display: block;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 25px;
                        }
                        &.point {
                            padding: 0;
                            background: #fefbeb;
                            .c-input {
                                .input-text {
                                    .inp {
                                        width: 100%;
                                        padding: 0 24px 0 12px;
                                        border: none;
                                        text-align: right;
                                        text-overflow: initial;
                                        background: transparent;
                                    }
                                }
                                .input-etc {
                                    top: 13px;
                                    right: 6px;
                                    height: 14px;
                                    background: transparent;
                                }
                            }
                        }
                        .btn_del {
                            overflow: hidden;
                            width: 22px;
                            height: 22px;
                            text-indent: -9999px;
                            @include sprite-retina($sp_icon_2x_btn_22_del);
                        }
                    }
                }
            }
        }
    }
}

//물류관리 - 입고현황 - 입고 명세서
.receiving_report_print {
    min-width: 1308px;
    padding: 20px 20px 40px 20px;
    .popup-body {
        padding: 0;
        .receiving_report_info {
            margin-top: 0;
            th {
                padding-top: 8px;
                padding-bottom: 8px;
                font-weight: normal;
                &.title {
                    font-size: 30px;
                    font-weight: bold;
                    background: none;
                }
            }
            td {
                padding-top: 8px;
                padding-bottom: 8px;
            }
        }
        .receiving_report_list {
            thead {
                th {
                    height: 44px;
                    font-weight: normal;
                }
            }
            tbody {
                td {
                    padding-top: 0;
                    padding-bottom: 0;
                    .check {
                        position: relative;
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        margin-top: 6px;
                        border: 1px solid #ccc;
                    }
                    .line {
                        text-decoration: underline;
                    }
                }
            }
            .sum {
                td {
                    font-weight: bold;
                    background: #fefbeb;
                }
            }
        }
        .receiving_report_check_info {
            margin-top: 20px;
            overflow: hidden;
            .tbl {
                float: right;
                width: 451px;
                margin-top: 20px;
                margin-left: 600px;
                th {
                    font-size: 14px;
                }
            }
        }
    }
    .popup-bottom {
        border: none;
    }
}

//상품그룹 관리 - 상품 그룹 등록 - 기준상품 선택
.select_standard_product {
    @include clearfix();
    &__thumb {
        float: left;
        .thumb {
            width: 100px;
            height: 100px;
            margin-bottom: 10px;
            border: 1px solid #eee;
            text-align: center;
            img {
                max-width: 98px;
                max-height: 98px;
            }
        }
    }

    &__info {
        float: left;
        margin-left: 15px;
        ul {
            li {
                font-size: 13px;
                color: #666;
            }
        }
    }
}

.agree_check_box {
    .c-guide {
        margin: 0 0 0 30px;
    }
}

@media print {
    //물류관리 - 입고현황 - 입고 명세서
    .receiving_report_print {
        height: auto;
        padding: 0;
        .popup-body {
            max-height: none;
            overflow-y: auto;
            overflow-x: auto;
        }
        .popup-bottom {
            display: none;
        }
    }
}
