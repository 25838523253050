@mixin c-select-height($c-select-height: 40px) {
    .select-box {
        position: relative;
        z-index: 1;
        width: 160px;
        display: inline-block;
        font-size: 0;

        .c-input {
            margin-right: 0;

            .inp {
                height: $c-select-height;
            }
        }

        .select {
            width: 160px; /* 원하는 너비설정 */
            height: $c-select-height;
            padding: 0 52px 0 12px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 0px; /* iOS 둥근모서리 제거 */
            -webkit-appearance: none; /* 네이티브 외형 감추기 */
            -moz-appearance: none;
            appearance: none;
            line-height: calc(#{$c-select-height} - 2px);
            font-size: 13px;
            color: #111;
            cursor: pointer;
            /* IE 10, 11의 네이티브 화살표 숨기기 */
            &::-ms-expand {
                display: none;
            }
            &:disabled {
                background-color: #f4f4f4;
                color: #bbb;
            }
        }

        &.disabled {
            &::before {
                border-color: #ddd;
            }
        }

        &::before,
        &::after {
            position: absolute;
            content: "";
            pointer-events: none;
        }

        &::before {
            top: 1px;
            right: $c-select-height;
            height: calc(100% - 2px);
            width: 1px;
            border-left: 1px solid #f4f4f4;
        }

        &::after {
            top: 50%;
            right: calc(#{$c-select-height} / 2.5);
            margin-top: -7px;
            display: inline-block;
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border: 1px solid $color-grayscale-05-1;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
            background-color: transparent;
        }
    }

    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: #111;
        line-height: $c-select-height;
        vertical-align: middle;
        white-space: nowrap;
        font-weight: normal;
    }

    .unit {
        position: relative;
        display: inline-block;
        margin-left: 5px;
        color: #111;
        line-height: $c-select-height;
        font-size: 14px;
        vertical-align: middle;
        white-space: nowrap;
    }

    &.auto {
        .c-input .input-text .inp {
            color: #111;
            background-color: #f4f4f4;
            border-color: #ddd;
        }

        .select-box {
            .select {
                color: #111;
                background-color: #f4f4f4;
                border-color: #ddd;
            }
        }
    }

    &.wide {
        display: table;
        width: 100%;

        .select-box {
            display: table-cell;
            width: 100%;

            .c-input {
                width: calc(100% - 60px);

                .input-text,
                .inp {
                    width: 100%;
                }
            }

            .select {
                width: 100%;
            }
        }

        &.input-field {
            .select-box {
                &::after {
                    display: none;
                }

                .c-input {
                    position: absolute;
                    width: calc(100% - 40px);
                    .inp {
                        border-right: none;
                    }
                }

                .select {
                    background-image: url("/img/selleroffice/icon_2x/select_style1.png") no-repeat right 50%;
                    background-size: 22px 22px;
                }
            }
        }
    }

    &.unit {
        .inp {
            width: 100px;
            padding: 0 12px 0 12px;
            border-right: none;
        }

        .select-box {
            &::before {
                display: none;
            }
            .select {
                width: 60px;
                max-width: 60px;
                min-width: 60px;
                padding-right: 34px;
                border-left: 1px solid #f4f4f4;
            }
        }
    }

    &.waring {
        position: relative;
        z-index: 1;
        padding-bottom: 18px;
        .select {
            border: 1px solid $color-11st-red;
        }

        .input-message {
            display: block;
            position: absolute;
            z-index: 1;
            // left: 7px; // 가입 절차 디자인 QA 로 수정됨 사이드 이팩트시 조치 필요
            left: 0;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }
    }

    &.vertical {
        display: flex;
        position: relative;
        margin-top: 0;
        padding-top: 40px;

        .title {
            position: absolute;
            top: 0;
            width: 100%;
            padding: 0;
            margin-right: 0;
            line-height: $c-select-height;
        }
    }

    &.input-field {
        .select-box {
            &::after {
                display: none;
            }

            .c-input {
                position: absolute;
                width: calc(100% - 40px);

                .input-text {
                    width: 100%;
                }

                .inp {
                    width: 100%;
                    border-right: none;
                }

                .inp:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: #bbb;
                }
            }

            .select {
                background-image: url("/img/selleroffice/icon_2x/select_style1.png");
                background-repeat: no-repeat;
                background-position: calc(100% - 7px) 50%;
                background-size: 22px 22px;
            }
        }
    }
}

.c-select {
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 0;
    cursor: pointer;

    //.style__1 ~ .style__4 제거, --style1 ~ --style4 이용

    &--style1,
    &.style__1 {
        @include c-select-height();
    }

    &--style2 {
        @include c-select-height(36px);
    }

    &.size__4,
    &--style3 {
        @include c-select-height(32px);
    }

    &.w-90 {
        .select-box {
            width: 90px;

            .select {
                width: 90px;
                padding: 0 35px 0 2px;
            }

            &::before {
                display: none;
            }
        }

        &.unit {
            .select-box {
                .select {
                    width: 45px;
                    max-width: 45px;
                    min-width: 45px;
                    padding: 0 2px;
                }
            }

            .inp {
                width: 45px;
                border-right: none;
            }
        }
    }

    &.w-120 {
        .select-box {
            width: 120px;

            .select {
                width: 120px;
                padding-right: 35px;
            }
        }

        &.unit {
            .select-box {
                .select {
                    width: 60px;
                    max-width: 60px;
                    min-width: 60px;
                }
            }

            .inp {
                width: 60px;
                border-right: none;
            }
        }
    }

    &.w-140 {
        .select-box {
            width: 140px;

            .select {
                width: 140px;
                padding-right: 35px;
            }
        }

        &.unit {
            .select-box {
                .select {
                    width: 60px;
                    max-width: 60px;
                    min-width: 60px;
                }
            }

            .inp {
                width: 60px;
                border-right: none;
            }
        }
    }

    $widths: (200, 240, 300, 400, 500, 600, 700);
    @each $width in $widths {
        &.w-#{$width} {
            .select-box {
                width: #{$width}px;

                .select {
                    width: #{$width}px;
                }
            }

            &.unit {
                .select-box {
                    .select {
                        width: 60px;
                        max-width: 60px;
                        min-width: 60px;
                    }
                }

                .inp {
                    width: #{($width - 60)}px;
                    border-right: none;
                }
            }
        }
    }

    .select-text {
        display: inline-block;
        vertical-align: top;
    }

    .c-text {
        display: inline-block;
        margin-left: 4px;
    }
    option[value=""][disabled] {
        display: none;
    }
    @at-root .table-box & {
        .select {
            padding-right: 33px;
            border: 0;

            &-box::before {
                display: none;
            }
        }
    }
}
