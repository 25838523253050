.c-preview__img {
    border: 1px solid #e3e7ee;
    overflow: auto;
    max-height: 656px;
}

.c-preview {
    &-option {
        padding: 40px 0;
        &__img {
            position: relative;
            width: 400px;
            height: 400px;
            margin: 0 auto;
            text-align: center;

            img {
                @include img-center();
            }
        }
    }
}
