//광고
.c-payment-box {
    display: flex;
    margin: 20px 0 10px 0;
    border: 1px solid $color-gray-11;
    background-color: $color-gray-14;
    @include clearfix;

    &__col {
        flex-basis: 50%;
        padding: 15px 30px;

        &:first-child {
            border-right: 1px solid $color-gray-11;
        }
    }

    &__top {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid $color-gray-11;
    }

    &__title {
        flex: 1;
        font-size: 14px;
        line-height: 21px;
        color: $color-gray-02;
        font-weight: normal;
    }

    &__total {
        flex: 1;
        color: $color-gray-02;
        text-align: right;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }
}
.c-payment-item {
    padding: 15px 0;

    &:not(:first-of-type) {
        margin-top: 4px;
        border-top: 1px solid $color-gray-11;
    }

    &__row {
        display: flex;
        font-size: 13px;
        line-height: 19px;
        color: $color-gray-03;

        letter-spacing: 0;

        &:not(:first-of-type) {
            margin-top: 4px;
        }
    }

    &__rate {
        color: $color-11st-red;
    }

    &__top {
        flex-grow: 2;
    }

    &__title {
        min-width: 80px;
        display: inline-block;
        font-size: 13px;
        font-weight: normal;
        vertical-align: middle;
    }

    &__content {
        flex-grow: 1;
        text-align: right;
    }

    &__info {
        margin-top: 8px;
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-06;

        &::before {
            content: "ㄴ";
            display: inline-block;
            margin-right: 2px;
        }
    }
}
