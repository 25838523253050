@charset "UTF-8";
//main-top-banner // 셀러 전환 하단 광고
// 전시 구좌로 운영 됨, 넓이는 고정, 여백만 유동, 링크 존재함, 미디어쿼리로 padding 조절

.main-top-banner {
    // 넓이
    --banner-width: 322px;
    --banner-height: 82px;
    --banner-padding: 30px;
    //스타일
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--banner-padding);
    background-color: #fff;

    &__item {
        flex: 0 1 auto;
        max-width: var(--banner-width);
        background: #fff;
    }

    &__link {
        display: block;
        width: var(--banner-width);
        height: var(--banner-height);
        text-decoration: none;
        background-image: var(--background-image);
        background-size: auto var(--banner-height);
        background-position: 0 center;
        background-repeat: no-repeat;
        background-color: gray;
    }

    &--divider {
        width: 1px;
        min-width: 1px;
        height: var(--banner-height);
        background-color: $color-gray-11;
    }
    @media (max-width: 1330px) {
        --banner-padding: 30px 0;
    }
}
// 968
