@charset "UTF-8";
// @import "../selleroffice/sprites/sp_icon.scss";
// @import "../selleroffice/sprites/sp_gnb_2x.scss";
// @import "../selleroffice/sprites/sp_icon_2x.scss";

// @media (max-width: 1200px) {
//     html.menu-open {
//         //overflow-x: scroll !important;
//     }
// }

.l-content {
    &--actionbar {
        .action-bar {
            border: 1px solid #d0d1d6;
            position: static;
            margin-top: 10px;
        }
    }

    &--po,
    &--popup {
        padding: 20px;
        min-width: 1020px;
    }

    &--po {
        .action-bar {
            border: 1px solid #d0d1d6;
            position: static;
            width: calc(100% + 40px);
            margin: 30px 0 -20px -20px;
        }
    }

    &--product {
        padding-bottom: 91px;
    }
}

html.menu-open {
    overflow: hidden;

    .navi-all__view {
        background-color: #0b83e6 !important;
        color: #fff !important;
    }
    .menu-all {
        display: block;
    }
}

.skip_nav {
    display: table;
    position: absolute;
    z-index: 9999;
    width: calc(100% - 210px);

    a {
        display: block;
        overflow: hidden;
        width: 980px;
        height: 0;
        margin: 0 auto;
        height: 1px;
        margin-bottom: -1px;
        white-space: nowrap;
        text-align: center;
        text-indent: -9999px;
    }
}

.c-browser_open {
    padding-top: 60px !important;

    .gnb-toggle {
        top: 60px !important;
    }

    .layout_gnb,
    .layout_gnb:hover {
        top: 60px !important;
        height: calc(100% - 58px);
    }
}

.c-browser {
    position: relative;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100%;
    min-width: 900px;
    height: 60px;
    padding: 0 40px;
    border-bottom: 1px solid #ddd;
    background-color: #e5f2fb;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-size: 16px;
    line-height: 60px;
    letter-spacing: -0.3px;

    &:before {
        display: inline-block;
        @include sprite-retina($sp_icon_2x_info_browser);
        vertical-align: middle;
        margin: -2px 5px 0 0;
        content: "";
    }
    em {
        color: #0b83e6;
    }
    .btn-close {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 20px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        line-height: 200px;

        &:before,
        &:after {
            position: absolute;
            width: 18px;
            height: 1px;
            top: 50%;
            left: 11px;
            margin-top: -1px;
            background: #333;
            content: "";
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.contents_wrap {
    .c-system {
        position: static;
        margin: 0 auto;
        -webkit-transform: translateX(0) translateY(0%);
        -o-transform: translateX(0) translateY(0%);
        transform: translateX(0) translateY(0%);
    }
}

.c-system {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 636px;
    padding: 40px;
    background-color: #fafafa;
    border: 1px solid #ddd;
    text-align: center;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    .logo_11st {
        position: absolute;
        z-index: 1;
        top: -38px;
        left: 0;
        @include sprite-retina($sp_icon_2x_logo_system);
        overflow: hidden;
        line-height: 200px;
    }
    .c-system__title {
        color: #333;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.5px;
        line-height: 36px;

        .color_brand {
            color: $color-11st-red;
        }
    }
    .c-system__msg {
        margin-top: 11px;
        color: #333;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: -0.3px;
    }
    .c-system__info {
        border-top: 1px solid #ddd;
        margin-top: 30px;
        padding: 10px 0;
        font-size: 13px;
        letter-spacing: -0.2px;
        color: #999;
        font-weight: bold;

        & + .c-system__info {
            border-top: none;
            margin-top: 5px;
        }
    }
    .c-system__btn {
        margin-top: 25px;
        font-size: 0;

        a,
        button {
            vertical-align: middle;

            &:not(:first-child) {
                margin-left: 10px;
            }
        }
        .btn_home {
            display: inline-block;
            min-width: 140px;
            padding: 0 15px;
            background-color: #fff;
            border: 1px solid #ccc;
            color: #111;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.5px;
            line-height: 46px;
        }
        .btn_pre {
            display: inline-block;
            min-width: 140px;
            padding: 0 15px;
            background-color: $color-11st-red;
            border: 1px solid $color-11st-red;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.5px;
            line-height: 46px;
        }
    }
}

.layout_wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    min-width: 1270px;
    padding: 0 0 0 210px;

    .c-browser {
        position: fixed;
    }
}

.layout_gnb,
.gnb-fold .layout_gnb:hover {
    /* flex */
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 400;
    top: 0;
    left: 0;
    height: 100%;
    width: 210px;
    padding: 80px 0 0;
    background-color: #192033;
    color: #fff;
    overflow: hidden;
    transition: width 0.3s ease;

    .site-tit {
        position: absolute;
        z-index: 50;
        top: 0;
        left: 0;
        width: 210px;
        height: 80px;
        padding: 27px 0 27px 21px;
        font-size: 0;
        line-height: 0;
        color: #fff;

        .logo {
            display: inline-block;
            margin-right: 7px;
            overflow: hidden;
            @include sprite-retina($sp_gnb_2x_logo_11st);
            line-height: 200px;
            vertical-align: middle;
        }
        .selleroffice {
            display: inline-block;
            overflow: hidden;
            @include sprite-retina($sp_gnb_2x_selleroffice_w);
            line-height: 200px;
            vertical-align: middle;
        }
    }
    .navi {
        flex: 0 0 auto;
        height: calc(100% - 80px);

        .navi-all__view {
            position: relative;
            z-index: 1;
            width: 210px;
            height: 60px;
            padding-left: 16px;
            margin-bottom: 0;
            background: #131927;
            font-size: 15px;
            color: #fff;
            font-weight: bold;
            text-align: left;
            line-height: 60px;

            &:before {
                display: inline-block;
                margin: -4px 16px 0 0;
                vertical-align: middle;
                @include sprite-retina($sp_gnb_2x_navi_allmenu);
                content: "";
            }
            &:after {
                display: block;
                position: absolute;
                z-index: 1;
                top: 50%;
                right: 24px;
                width: 8px;
                height: 8px;
                border: 1px solid rgba(238, 238, 238, 0.5);
                border-width: 1px 1px 0 0;
                -webkit-transform: rotate(45deg) translateY(-50%);
                -o-transform: rotate(45deg) translateY(-50%);
                transform: rotate(45deg) translateY(-50%);
                content: "";
            }
            &.active {
                background-color: #0b83e6 !important;
                color: #fff !important;

                &::before {
                    @include sprite-retina($sp_gnb_2x_navi_on_allmenu);
                }
            }
        }
        .navi-group {
            padding: 10px 0;
            border-bottom: 1px solid #131927;
        }
        .navi-box {
            padding: 0;

            &.open {
                .navi-cate {
                    background-color: #0b83e6;
                    color: #fff;

                    .navi-new {
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        margin: -3px 0 0 4px;
                        line-height: 200px;
                        vertical-align: middle;
                        overflow: hidden;
                        @include sprite-retina($sp_gnb_2x_icon_on_new);
                    }
                }
                button:after {
                    margin-top: -2px;
                    transform: rotate(-135deg);
                    border-color: rgba(238, 238, 238, 1);
                }
                .navi-list {
                    background-color: #131927;
                    display: block;
                }
                .navi-cate {
                    &.icon-style1:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style1);
                    }
                    &.icon-style2:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style2);
                    }
                    &.icon-style3:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style3);
                    }
                    &.icon-style4:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style4);
                    }
                    &.icon-style5:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style5);
                    }
                    &.icon-style6:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style6);
                    }
                    &.icon-style7:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style7);
                    }
                    &.icon-style8:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style8);
                    }
                    &.icon-style9:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style9);
                    }
                    &.icon-style10:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style10);
                    }
                    &.icon-style11:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style11);
                    }
                    &.icon-style12:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style12);
                    }
                    &.icon-style13:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style13);
                    }
                    &.icon-style15:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style15);
                    }
                    &.icon-style20:before {
                        @include sprite-retina($sp_gnb_2x_navi_on_style20);
                    }
                    &.icon-style21:before {
                        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M9.5 4L11.3471 7.50709L14.3715 5.47836L13.6978 10.0074L17.5 11.1139L14.5465 13.5393L16.3326 17' stroke='white'/%3E%3Crect x='3' y='16.5' width='16' height='1' fill='white'/%3E%3C/svg%3E") center no-repeat;
                    }
                    &.icon-style22:before {
                        background: url("data:image/svg+xml,%0A%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4887 8.10416V3.20831H3.44922V17.8978H16.4887V12.5' stroke='white'/%3E%3Cpath d='M5.82016 6.82214H14.118' stroke='white'/%3E%3Cpath d='M5.82016 9.23157H10.7894' stroke='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5168 13.2622L18.8115 5.84778L21 8.07211L13.7052 15.4865H11.5168V13.2622Z' stroke='white'/%3E%3C/svg%3E") center no-repeat;
                    }
                }
            }
        }
        .navi-cate {
            position: relative;
            z-index: 1;
            display: block;
            width: 210px;
            height: 52px;
            padding: 0 16px 0 16px;
            margin: 0;
            line-height: 52px;
            text-align: left;
            color: #fff;
            font-size: 15px;

            .navi-new {
                width: 16px;
                height: 16px;
                display: inline-block;
                margin: -3px 0 0 4px;
                line-height: 200px;
                vertical-align: middle;
                overflow: hidden;
                @include sprite-retina($sp_gnb_2x_icon_new);
            }
            &:before {
                margin: -3px 16px 0 0;
                display: inline-block;
                vertical-align: middle;
                content: "";
            }
            &.icon-style1:before {
                @include sprite-retina($sp_gnb_2x_navi_style1);
            }
            &.icon-style2:before {
                @include sprite-retina($sp_gnb_2x_navi_style2);
            }
            &.icon-style3:before {
                @include sprite-retina($sp_gnb_2x_navi_style3);
            }
            &.icon-style4:before {
                @include sprite-retina($sp_gnb_2x_navi_style4);
            }
            &.icon-style5:before {
                @include sprite-retina($sp_gnb_2x_navi_style5);
            }
            &.icon-style6:before {
                @include sprite-retina($sp_gnb_2x_navi_style6);
            }
            &.icon-style7:before {
                @include sprite-retina($sp_gnb_2x_navi_style7);
            }
            &.icon-style8:before {
                @include sprite-retina($sp_gnb_2x_navi_style8);
            }
            &.icon-style9:before {
                @include sprite-retina($sp_gnb_2x_navi_style9);
            }
            &.icon-style10:before {
                @include sprite-retina($sp_gnb_2x_navi_style10);
            }
            &.icon-style11:before {
                @include sprite-retina($sp_gnb_2x_navi_style11);
            }
            &.icon-style12:before {
                @include sprite-retina($sp_gnb_2x_navi_style12);
            }
            &.icon-style13:before {
                @include sprite-retina($sp_gnb_2x_navi_style13);
            }
            &.icon-style14:before {
                @include sprite-retina($sp_gnb_2x_navi_style14);
            }
            &.icon-style15:before {
                @include sprite-retina($sp_gnb_2x_navi_style15);
            }
            &.icon-style16:before {
                @include sprite-retina($sp_gnb_2x_navi_style16_2x);
            }
            &.icon-style17:before {
                @include sprite-retina($sp_gnb_2x_navi_style17);
            }
            &.icon-style18:before {
                @include sprite-retina($sp_gnb_2x_navi_style18);
            }
            &.icon-style19:before {
                @include sprite-retina($sp_gnb_2x_navi_style19);
            }
            &.icon-style20:before {
                @include sprite-retina($sp_gnb_2x_navi_style20);
            }
            &.icon-style21:before {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M9.5 4L11.3471 7.50709L14.3715 5.47836L13.6978 10.0074L17.5 11.1139L14.5465 13.5393L16.3326 17' stroke='%23bbb'/%3E%3Crect x='3' y='16.5' width='16' height='1' fill='%23bbb'/%3E%3C/svg%3E") center no-repeat;
                background-image: u;
                $size: 22px;
                width: $size;
                height: $size;
                background-size: $size $size;
            }
            &.icon-style22:before {
                background: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.4887 8.10416V3.20831H3.44922V17.8978H16.4887V12.5' stroke='%23BBBBBB'/%3E%3Cpath d='M5.82016 6.82214H14.118' stroke='%23BBBBBB'/%3E%3Cpath d='M5.82016 9.23157H10.7894' stroke='%23BBBBBB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5168 13.2622L18.8115 5.84778L21 8.07211L13.7052 15.4865H11.5168V13.2622Z' stroke='%23BBBBBB'/%3E%3C/svg%3E") center no-repeat;
                $size: 22px;
                width: $size;
                height: $size;
                background-size: $size $size;
            }
        }
        button.navi-cate:after {
            display: block;
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 24px;
            width: 8px;
            height: 8px;
            margin-top: -6px;
            border: 1px solid rgba(238, 238, 238, 0.5);
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            content: "";
        }
        a.navi-cate:after {
            position: absolute;
            z-index: 1;
            top: 15px;
            right: 16px;
            display: inline-block;
            vertical-align: middle;
            content: "";
            @include sprite-retina($sp_gnb_2x_navi_window);
        }
        .navi-list {
            display: none;
            padding-bottom: 28px;

            .navi-new {
                display: inline-block;
                overflow: hidden;
                width: 5px;
                height: 5px;
                margin-left: 3px;
                border-radius: 2.5px;
                background-color: #0b83e6;
                line-height: 200px;
                vertical-align: top;
            }
            .link {
                display: inline-block;
                width: 210px;
                padding: 0 17px 0 53px;
                margin-top: 16px;
                font-size: 15px;
                line-height: 22px;
                color: rgba(255, 255, 255, 0.5);
                text-align: left;
                //word-break: keep-all;
                word-wrap: break-word;
                text-decoration: none;
                letter-spacing: -1.2px;

                &.new:after {
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    background-color: $color-11st-red;
                    overflow: hidden;
                    line-height: 200px;
                    content: "new";
                    vertical-align: top;
                    margin: 0 0 0 5px;
                    border-radius: 3px;
                }
            }
            .link:hover,
            .active {
                color: #fff;
                text-decoration: none;
            }
        }
    }
    .skin-set {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 100%;
        padding: 0 15px;
        @include clearfix();
        border-top: 1px solid #131927;

        .skin-set__title {
            float: left;
            width: 50%;
            color: #eee;
            letter-spacing: -1.29px;
            font-size: 15px;
            line-height: 52px;
            vertical-align: middle;

            &:before {
                display: inline-block;
                margin: -4px 6px 0 0;
                vertical-align: middle;
                @include sprite-retina($sp_gnb_2x_navi_skin);
                content: "";
            }
        }
        .skin-set__choice {
            display: block;
            float: right;
            font-size: 0;
            margin-top: 14px;
        }
        .skin-set__dark {
            display: inline-block;
            position: relative;
            z-index: 1;
            margin-right: 4px;
            overflow: hidden;
            width: 22px;
            height: 22px;

            .inp {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                opacity: 0;
            }
            .lab {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                overflow: hidden;
                line-height: 200px;
                border-radius: 10px;
                background-color: rgba(25, 32, 51, 0.6);
            }
            .inp:checked + .lab {
                @include sprite-retina($sp_gnb_2x_skin_dark);
            }
            .inp:focus + .lab {
                width: 22px;
                height: 22px;
                border-radius: 11px;
                box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                content: "";
            }
            .inp:not(:checked):hover + .lab {
                background: #192033;
            }
        }
        .skin-set__white {
            display: inline-block;
            position: relative;
            z-index: 1;
            overflow: hidden;
            width: 22px;
            height: 22px;

            .inp {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                opacity: 0;
            }
            .lab {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                overflow: hidden;
                line-height: 200px;
                border-radius: 10px;
                background-color: rgba(255, 255, 255, 0.6);
            }
            .inp:checked + .lab {
                @include sprite-retina($sp_gnb_2x_skin_white);
            }
            .inp:focus + .lab {
                width: 22px;
                height: 22px;
                border-radius: 11px;
                box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                content: "";
            }
            .inp:not(:checked):hover + .lab {
                background: #fff;
            }
        }
    }
}

.gnb_white,
.gnb-fold .layout_gnb.gnb_white:hover {
    background-color: #fff;

    .site-tit {
        .logo {
            @include sprite-retina($sp_gnb_2x_logo_11st_red);
        }

        .selleroffice {
            @include sprite-retina($sp_gnb_2x_selleroffice_b);
        }
    }

    .navi {
        .navi-all__view {
            background: #fff;
            color: #333;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            font-weight: normal;
        }

        .navi-cate {
            color: #333;
        }

        .navi-box {
            &.open {
                .navi-list {
                    background: #fafafa;

                    .link {
                        color: rgba(51, 51, 51, 0.7);
                    }
                }
            }
        }

        .navi-group {
            border-color: #eee;
        }
    }

    .skin-set {
        border-top: 1px solid #eee;

        .skin-set__title {
            color: #333;
        }
    }
}

.gnb-fold {
    padding-left: 54px;

    .site-tit {
        width: 54px;
        padding-left: 0;

        .logo {
            display: block;
            margin: 0 auto;
        }
        .selleroffice {
            display: none;
        }
    }
    .layout_util {
        padding-left: 111px;

        .gnb-toggle {
            left: 54px;

            &:after {
                left: 6px;
                transform: translateZ(0) rotate(45deg);
            }
        }
    }
    .layout_gnb {
        width: 54px;

        .navi {
            .navi-all__view {
                width: 54px;
                height: 60px;
                margin: 0 auto 10px;
                padding-left: 0;
                overflow: hidden;
                line-height: 200px;

                &:before {
                    margin-top: 0px;
                    display: block;
                    margin: 19px auto;
                }
                &:after {
                    display: none;
                }
            }
            .navi-cate {
                width: 54px;
                height: 52px;
                overflow: hidden;
                line-height: 200px;

                &:before {
                    margin-top: -1px;
                    display: block;
                    margin: 15px 0;
                }
                &:after {
                    display: none;
                }
            }
            a.navi-cate {
                width: 54px;
                height: 52px;
            }
            .navi-group {
                padding-top: 0px;
                padding-bottom: 10px;

                & + .navi-group {
                    padding-top: 11px;
                }
            }
            .navi-box.open {
                background: #0b83e6;

                .navi-list {
                    display: none;
                }
            }
            .navi-list {
                display: none;
            }
        }
        .skin-set {
            padding: 0;

            .skin-set__title {
                width: 52px;
                height: 54px;
                line-height: 200px;
                overflow: hidden;

                &:before {
                    display: block;
                    margin: 16px auto;
                }
            }
            .skin-set__choice {
                display: none;
            }
        }
    }
    .action-bar {
        padding-left: 70px;
    }
    .menu-all {
        padding-left: 54px;
    }
}

.layout_util {
    position: relative;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 22px 20px 22px 40px;
    color: #fff;
    background-color: #fff;
    border-bottom: 1px solid #eee;

    .gnb-toggle {
        position: fixed;
        z-index: 200;
        left: 210px;
        top: 0;
        display: block;
        width: 25px;
        height: 80px;
        background-color: #fff;
        border: 1px solid #eee;
        border-left: none;
        overflow: hidden;
        line-height: 200px;

        &:after {
            @include ico-angle(left, 8px, #999, 1px, absolute);
            left: 6px;
        }
    }
    .util-search {
        position: relative;
        z-index: 1;
        display: inline-block;
        font-size: 0;
        line-height: 0;

        .util-search__key {
            width: 300px;
            height: 36px;
            padding: 0 20px 0;
            border: 1px solid #f4f4f4;
            border-radius: 2px;

            &.open {
                .list {
                    display: block;
                }
                .btn {
                    &:after {
                        margin-top: -2px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .btn {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 84px;
            height: 34px;
            background-color: #fff;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.88px;
            color: #111;

            &:after {
                display: block;
                position: absolute;
                top: 50%;
                right: 6px;
                @include ico-triangle(down, 8px, 5px, #333, absolute);
            }
            .text {
                display: block;
                width: 66px;
                height: 34px;
                line-height: 34px;
                text-align: left;
                letter-spacing: 0;
            }
        }
        .list {
            display: none;
            position: absolute;
            z-index: 1;
            top: 35px;
            left: 0;
            width: 132px;
            padding: 10px 0;
            background-color: #fff;
            border: 1px solid #ddd;
            line-height: 32px;
            box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.15);

            .link {
                width: 100%;
                height: 36px;
                padding: 0 20px;
                line-height: 36px;
                text-align: left;
                font-size: 14px;
                color: #333;

                &:hover {
                    background-color: #fafafa;
                    color: #111;
                }
                &.active {
                    color: $color-11st-red;
                }
            }
        }
        .inp {
            width: 150px;
            height: 34px;
            padding: 0 8px;
            line-height: 34px;
            border: none;
            vertical-align: top;
        }
        .btn-search {
            display: inline-block;
            margin: 7px 0;
            overflow: hidden;
            line-height: 200px;
            @include sprite($sp_icon_search);
            vertical-align: top;
        }
    }
    .util {
        position: relative;
        z-index: 1;
        display: inline-block;
        margin: 0 10px;
        color: #cbd0e7;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;

        &.util-advice {
            &.open {
                .btn {
                    &:after {
                        margin-top: -4px;
                        transform: rotate(180deg);
                    }
                }
            }
            .btn {
                &::after {
                    display: inline-block;
                    margin: -2px 0 0 8px;
                    vertical-align: middle;

                    @include ico-triangle(down, 8px, 5px, rgba(102, 102, 102, 0.6), static);
                }
            }
        }
        &.util-id {
            &.open {
                .btn {
                    &:after {
                        margin-top: -2px;
                        transform: rotate(180deg);
                    }
                }
                .list {
                    left: auto;
                    right: -30px;
                }
            }
            .btn {
                &::after {
                    display: inline-block;
                    margin: -2px 0 0 8px;
                    vertical-align: middle;

                    @include ico-triangle(down, 8px, 5px, rgba(102, 102, 102, 0.6), static);
                }
            }
            .user-id {
                color: #111;
                font-weight: bold;
                letter-spacing: 0;
            }
        }
        &.open {
            .list {
                display: block;
            }
        }
        .btn {
            height: 36px;
            color: #333;
            line-height: 36px;
            font-size: 14px;
            letter-spacing: -1.2px;
        }
        .list {
            display: none;
            position: absolute;
            z-index: 1;
            top: 35px;
            left: -5px;
            width: 132px;
            padding: 10px 0;
            background-color: #fff;
            border: 1px solid #ddd;
            line-height: 32px;
            box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.15);

            .link {
                display: inline-block;
                width: 100%;
                height: 36px;
                padding: 0 20px;
                line-height: 36px;
                text-align: left;
                font-size: 14px;
                color: #333;

                &:hover {
                    background-color: #fafafa;
                    color: #111;
                    text-decoration: none;
                }
                &.active {
                    color: $color-11st-red;
                }
            }
            .count {
                float: right;
                color: $color-11st-red;
                font-size: 15px;
                font-weight: bold;
                font-family: $font-family-number;
            }
        }
    }
    .util-right {
        float: right;
        margin-top: -6px;
    }
}

.util-dropdown__list {
    display: none;
    position: absolute;
    z-index: 100;
    top: 42px;
    left: 0;
    width: 132px;
    background-color: #fff;
    border: 1px solid #ddd;
    line-height: 32px;
    box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.15);

    .link {
        display: inline-block;
        width: 100%;
        height: 36px;
        padding: 0 12px;
        line-height: 36px;
        text-align: left;
        font-size: 14px;
        color: #333;

        &:hover {
            background-color: #fafafa;
            color: #111;
        }
        &.active {
            color: $color-11st-red;
        }
    }
    &.open {
        display: block;
    }
}

.layout_contents {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    min-height: 100vh;
    @include clearfix();
    height: 100%;
}

.contents_wrap {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 40px;
}

.menu-all {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 300;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 64px 0 0 210px;

    .menu-wrap {
        min-width: 958px;
        position: fixed;
        z-index: 1;
        top: 65px;
        margin: 16px;
        background-color: #fff;
        height: 627px;
        overflow: hidden;

        .menu-header {
            position: relative;
            padding: 18px 49px 0px 20px;
            height: 60px;
            border-bottom: 1px solid #ddd;
            text-align: center;

            &__title {
                font-size: 18px;
                line-height: 26px;
                color: #333;
                font-weight: bold;
            }
        }
        .menu-body {
            height: 567px;
            overflow: hidden;
            overflow-y: auto;
        }
        .navi-group {
            display: table;
            table-layout: fixed;
            width: 100%;
            height: 100%;

            .navi-box {
                display: table-cell;
                border-left: 1px solid #ddd;

                &:first-child {
                    border-left: none;
                }

                .navi-cate {
                    height: 52px;
                    padding: 0 10px 0 16px;
                    line-height: 52px;
                    color: #111;
                    font-size: 15px;
                    background-color: #fafafa;

                    .navi-new {
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        margin: -3px 0 0 4px;
                        line-height: 200px;
                        vertical-align: middle;
                        overflow: hidden;
                        @include sprite-retina($sp_gnb_2x_icon_new);
                    }
                    &:before {
                        margin-top: -2px;
                        margin-right: 6px;
                        display: inline-block;
                        vertical-align: middle;
                        content: "";
                    }
                    &.icon-style1:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style1);
                    }
                    &.icon-style2:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style2);
                    }
                    &.icon-style3:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style3);
                    }
                    &.icon-style4:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style4);
                    }
                    &.icon-style5:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style5);
                    }
                    &.icon-style6:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style6);
                    }
                    &.icon-style7:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style7);
                    }
                    &.icon-style8:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style8);
                    }
                    &.icon-style13:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style13);
                    }
                    &.icon-style15:before {
                        @include sprite-retina($sp_gnb_2x_navi_all_style15);
                    }
                }
                .navi-list {
                    overflow-y: auto;
                    padding: 6px 16px;
                    height: 505px;
                    &:not(:only-of-type) {
                        height: 343px;
                        margin-bottom: 10px;
                    }
                    &:not(:first-of-type) {
                        height: 100px;
                    }
                }
                .navi-list:nth-child(4) {
                    min-height: auto;
                }
                .link {
                    display: block;
                    margin: 8px 0;
                    font-size: 14px;
                    color: #333;
                    text-align: left;
                    letter-spacing: -1.2px;
                    line-height: 20px;

                    .navi-new {
                        display: inline-block;
                        overflow: hidden;
                        width: 5px;
                        height: 5px;
                        margin-left: 3px;
                        border-radius: 2.5px;
                        background-color: #0b83e6;
                        line-height: 200px;
                        vertical-align: top;
                    }
                }
                .link:hover {
                    font-weight: bold;
                }
            }
        }
        .menu-close {
            position: absolute;
            top: 10px;
            right: 10px;
            border: 1px solid #ddd;
            @include ico-cancel(14px, 1px, #999, 13px);
        }
    }
}

.multi-tab {
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);

    .multi-tab__list {
        display: -ms-flexbox; /* IE 10 */
        display: flex;
        white-space: nowrap;
    }
    .multi-tab__item {
        -ms-flex: 0 1 auto; /* IE 10 */
        flex: 0 1 auto;
        min-width: 2em;
        overflow: hidden;
        position: relative;
        z-index: 1;
        height: 60px;
        padding: 0 20px 0 30px;
        text-align: center;
        line-height: 60px;
        border-left: 1px solid #eee;
        border-bottom: none;
        vertical-align: bottom;

        .page-name {
            display: block;
            width: 100%;
            min-width: 35px;
            max-width: 400px;
            height: 60px;
            overflow: hidden;
            @include text-ellipsis();
            text-align: center;
            font-size: 16px;
            line-height: 60px;
            color: #333;

            &:hover {
                text-decoration: none;
            }
        }
        .pin {
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 10px;
            @include sprite-retina($sp_icon_2x_pin);
            overflow: hidden;
            line-height: 200px;

            &.active {
                @include sprite-retina($sp_icon_2x_pin_active);
            }
        }
        .btn-del {
            display: none;
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 10px;
            width: 22px;
            height: 22px;
            overflow: hidden;
            line-height: 200px;
            transform: translateY(-50%);
            @include ico-cancel(10px, 1px, #333, 5px);
        }
        &.main {
            width: 150px;
            max-width: 150px;
            min-width: 80px;
            padding: 0;

            &.active {
                padding-right: 0;
            }
        }
        &.active {
            flex: 0 0 auto;
            padding-right: 35px;
            border-bottom: 2px solid $color-11st-red;

            .btn-del {
                display: block;
            }
        }
        &:last-child {
            border-right: 1px solid #eee;
        }
    }
}

.content_iframe {
    flex: 1 0 auto;
    width: 100%;
}

.action-bar {
    position: fixed;
    z-index: 1000;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 0;
    padding: 11px 11px;
    border: 1px solid #d0d1d6;
    background: #fafafa;
    display: flex;
    justify-content: space-between;

    // button {
    //     margin: 0 5px;
    // }

    &__left,
    &__right {
        // min-width: 50%;
        display: flex;
        gap: 5px;
    }

    &__left {
        text-align: left;
    }

    &__right {
        text-align: right;
    }

    &__center {
        width: 100%;
        text-align: center;
    }
}

.btn_util_chat {
    @include sprite-retina($sp_icon_2x_ico_chat_off);
    position: relative;
    width: auto;
    height: 36px;
    padding: 0 16px 0 36px;
    margin: 5px 0 0 5px;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #333;
    vertical-align: middle;
    border: 1px solid #ddd;
    border-radius: 18px;
    overflow: visible;

    &:after {
        display: none;
    }

    &.new {
        &:after {
            content: "new";
            display: block;
            @include sprite-retina($sp_icon_2x_chat_new);
            overflow: hidden;
            position: absolute;
            top: -5px;
            right: -1px;
            display: inline-block;
            text-indent: -9999px;
        }
    }

    &.active {
        animation: btn_util_chat_active 1s step-end infinite;
    }
}

.divider {
    display: inline-block;
    width: var(--divider-width, 1px);
    height: var(--divider-height, 40px);
    opacity: var(--divider-opacity, 1);
    margin-top: var(--divider-margin-top, 10px);
    // 앞쪽 오브젝트의 maring 상쇄용. 최초 버튼 사이에 사용하여 5로 설정함
    margin-left: var(--divider-margin-left, 5px);
    margin-right: var(--divider-margin-right, 15px);
    margin-bottom: var(--divider-margin-bottom, 0);
    background-color: var(--divider-color, #eee);
    vertical-align: middle;
}

@keyframes btn_util_chat_active {
    50% {
        color: #fff;
        @include sprite-retina($sp_icon_2x_ico_chat_on);
        width: auto;
        height: 36px;
    }
}
