@charset "UTF-8";

.btn-etc {
    font-size: 0;
    line-height: 0;

    .gicon {
        font-size: 12px;
        line-height: 200px;
    }

    &.active {
        .gicon-autosave {
            @include sprite($sp_icon_auto_save_on);
        }
    }

    &.arrow-down {
        position: relative;
        z-index: 1;
        width: 22px;
        height: 22px;
        overflow: hidden;

        &:after {
            position: absolute;
            top: 2px;
            right: 5px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            border: 1px solid $color-grayscale-05-1;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
        }
    }

    &.plus {
        @include sprite-retina($sp_icon_2x_btn_40_add);
    }

    &.del-filed {
        @include sprite-retina($sp_icon_2x_btn_40_del);
    }

    &.del-size__5 {
        position: relative;
        border: 1px solid #ddd;
        background-color: #fff;
        @include ico-cancel(8px, 1px, #949494, 7px);
    }
}

.btn-excel {
    position: relative;
    z-index: 1;
    padding-left: 20px;
    font-size: 14px;
    color: #000;

    &:after {
        position: absolute;
        z-index: 1;
        left: -3px;
        top: 2px;
        display: inline-block;
        content: "";
        @include sprite-retina($sp_icon_2x_excel);
    }
}

.btn-excel-02 {
    position: relative;
    z-index: 1;
    padding: 0 12px 0 32px;
    height: 32px;
    font-size: 13px;
    color: $color-grayscale-02;
    border: 1px solid #ccc;

    &:after {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 6px;
        display: inline-block;
        content: "";
        @include sprite-retina($sp_icon_2x_excel-02);
    }
}

.btn-floding {
    position: relative;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 200px;

    &::before {
        display: none;
    }

    &:after {
        @include ico-angle("up", 8px, #333, 1px, "absolute");
        top: 18px;
        right: 10px;
    }

    &--closed {
        &::after {
            transform: rotate(135deg);
            top: 12px;
            right: 12px;
        }
    }
}

.c-btn {
    display: inline-block;
    text-align: center;
    padding: 0 10px;

    &--normal {
        border: 1px solid $color-gray-09;
        color: $color-gray-02;
        background: $color-gray-14;
    }

    &--alert {
        border: 1px solid $color-11st-red;
        color: $color-11st-red;
        background: $color-gray-14;
    }

    &--primary {
        border: 1px solid $color-11st-red;
        color: $color-gray-14;
        background: $color-11st-red;
    }

    &--size-32 {
        height: 32px;
        line-height: 30px;
        font-size: 13px;
    }

    &--size-w60 {
        min-width: 60px;
    }

    &--size-w200 {
        min-width: 200px;
    }

    &--icon-excel {
        padding: 0 10px;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_excel-02);
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--icon-pdf {
        padding: 0 10px;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_pdf);
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--icon-extend {
        padding: 0 10px;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_extend);
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--icon-reduce {
        padding: 0 10px;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_reduce);
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--icon-notice {
        padding: 0 10px;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_notice);
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--icon-download {
        padding: 0 10px;

        &::after {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_download2);
            margin-left: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }

        &-ppt {
            padding: 0 10px;

            &::before {
                display: inline-block;
                @include sprite-retina($sp_icon_2x_ppt);
                margin-right: 4px;
                margin-top: -2px;
                vertical-align: middle;
                content: "";
            }
        }

        &-pdf {
            padding: 0 10px;

            &::before {
                display: inline-block;
                @include sprite-retina($sp_icon_2x_pdf);
                margin-right: 4px;
                margin-top: -2px;
                vertical-align: middle;
                content: "";
            }
        }
    }
    $iconUpDown: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='19' viewBox='0 0 18 19' fill='none'%3E%3Cpath d='M8.99968 5L15.0619 13.5H2.9375L8.99968 5Z' fill='%23333333'/%3E%3C/svg%3E");
    &--icon-up,
    &--icon-down {
        padding: 0 10px;
        &::before {
            display: inline-block;
            $size: 18px;
            width: $size;
            height: $size;
            background: $iconUpDown center no-repeat;
            background-size: $size;
            width: $size;
            height: $size;
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }
    &--icon-down::before {
        transform: rotate(180deg);
    }
}

%btn {
    display: inline-block;
    padding: 0 10px 0;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0;

    &:disabled {
        cursor: default;
        border-color: #ddd !important;
        color: #bbb !important;
        background-color: #f4f4f4 !important;
    }

    &:hover {
        text-decoration: none;
    }

    &.w-200 {
        min-width: 200px;
    }

    &.w-180 {
        min-width: 180px;
    }

    &.w-160 {
        min-width: 160px;
    }
    &.h-48 {
        --btn-size-height: 48px;
        min-width: var(--btn-size-height);
        min-height: var(--btn-size-height);
    }
}

.btn-style__1 {
    @extend %btn;
    border: 1px solid #ccc;
    color: #111;
    background: #fff;

    &:hover {
        background-color: #eee;
    }
}

.btn-style__2 {
    @extend %btn;
    border: 1px solid $color-11st-red;
    color: $color-11st-red;
    background: #fff;

    &:hover {
        background-color: #eee;
    }
}

.btn-style__3 {
    @extend %btn;
    border: 1px solid $color-11st-red;
    background: $color-11st-red;
    color: #fff;

    &:hover {
        background-color: #ea2f3f;
        color: #fff;
    }
}

.btn-style__4 {
    @extend %btn;
    border: 1px solid $color-grayscale-05-1;
    color: #111;
    background: #fff;

    &:hover {
        color: $color-11st-red;
        border-color: $color-11st-red;
    }
}

.btn-size__1 {
    min-width: 120px;
    height: 48px;
    line-height: 46px;
    font-size: 16px;
}

.btn-size__2 {
    min-width: 120px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__3 {
    min-width: 96px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
}

.btn-size__4 {
    min-width: 87px;
    height: 32px;
    line-height: 30px;
    font-size: 13px;

    &--auto {
        min-width: auto;
        width: auto;
        padding: 0 10px;
    }
}

.btn-size__5 {
    min-width: 56px;
    height: 24px;
    line-height: 22px;
    font-size: 11px;
}

.btn-size__6 {
    min-width: 120px;
    height: 60px;
    line-height: 58px;
    font-size: 14px;
}

.btn-size__7 {
    min-width: 100px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__8 {
    min-width: 80px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__9 {
    min-width: 140px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__10 {
    min-width: 200px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
}

.btn-size__11 {
    min-width: 160px;
    height: 48px;
    line-height: 46px;
    font-size: 16px;
}

.btn-size__12 {
    min-width: 140px;
    height: 48px;
    line-height: 46px;
    font-size: 16px;
}

.btn-size__13 {
    min-width: 107px;
    height: 48px;
    line-height: 46px;
    font-size: 16px;
}

[class^="btn-style"] + .link {
    vertical-align: bottom;
    line-height: 25px;
    margin-left: 10px;
}

.tabel-del {
    width: 22px;
    height: 22px;
    background: #fff;
    overflow: hidden;
    line-height: 200px;
    @include sprite($sp_icon_table_del);
}

.link {
    vertical-align: middle;
    &--underline {
        text-decoration: underline;
    }

    & + .link {
        margin-left: 8px;
    }
}
.link-style1 {
    display: inline-block;
    font-size: 13px;
    color: $color-secondary;

    &.arrow:after {
        content: "";
        margin-top: -2px;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        border: 1px solid $color-secondary;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
    }
}

.link-style2 {
    display: inline-block;
    font-size: 13px;
    color: $color-11st-red;

    &.arrow:after {
        content: "";
        margin-top: -2px;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        border: 1px solid $color-11st-red;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
    }
}

.link-style3 {
    display: inline-block;
    font-size: 13px;
    color: $color-gray-04;

    &.arrow:after {
        content: "";
        margin-top: -4px;
        margin-left: 9px;
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        border: 1px solid $color-gray-04;
        border-width: 1px 1px 0 0;
        transform: rotate(135deg);
    }
    &.active.arrow:after {
        margin-top: 0;
        transform: rotate(-45deg);
    }
}

.link-email {
    color: $color-11st-blue;
}

.c-chip {
    display: inline-block;
    position: relative;
    min-width: 50px;
    height: 32px;
    padding: 0 12px;
    border: 1px solid $color-grayscale-08;
    border-radius: 16px;
    background: $color-grayscale-13;
    font-size: 14px;
    font-weight: normal;
    color: $color-grayscale-03;
    line-height: 30px;
    white-space: nowrap;
    box-sizing: border-box;
    outline: none;

    &--selected {
        border-color: rgba(244, 49, 66, 0.2);
        background-color: #fffbfb;
        font-weight: normal;
        color: $color-11st-red;
    }

    &[disabled],
    &--disabled {
        border-color: $color-grayscale-09;
        color: $color-grayscale-07;

        .delete {
            &:before,
            &:after {
                background-color: $color-grayscale-05;
                opacity: 0.2;
            }
        }
    }

    &-shape {
        .c-chip {
            border-radius: 0;
        }
    }

    &-input {
        a {
            display: block;
        }
        .c-chip {
            padding-right: 30px;
        }
        .delete {
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            background-color: transparent;
            vertical-align: top;
            outline: none;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                right: 58%;
                left: auto;
                width: 1px;
                height: 12px;
                margin: -6px 0 0 -1px;
                background: $color-grayscale-05;
                transform: rotate(45deg);
                content: "";
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    &-filter {
        .c-chip--selected {
            &:before {
                display: inline-block;
                width: 12px;
                height: 20px;
                margin: -3px 4px 3px 0;
                border: 1px solid $color-11st-red;
                border-width: 0 3px 3px 0;
                vertical-align: middle;
                transform: rotate(45deg) scale(0.5);
                content: "";
            }
        }
    }
}

.c-chip-option {
    display: inline-block;
    padding: 0 12px;
    background: $color-gray-12;
    height: 25px;
    margin: 7px 5px 7px 0;
    border-radius: 2px;

    .c-chip {
        display: inline-block;
        position: relative;
        padding: 0 1px 0 0;
        height: 25px;
        max-width: 360px;
        border: none;
        border-radius: 2px;
        background: inherit;
        font-size: 14px;
        font-weight: normal;
        color: $color-grayscale-02;
        line-height: 25px;
        white-space: nowrap;
        box-sizing: border-box;
        outline: none;
        @include text-ellipsis();
    }

    .delete {
        position: relative;
        width: 22px;
        height: 22px;
        margin-top: 2px;
        border: 0;
        border-radius: 0%;
        background-color: transparent;
        vertical-align: top;
        outline: none;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            right: 50%;
            left: auto;
            width: 1px;
            height: 12px;
            margin: -6px 0 0 -0;
            background: $color-gray-08;
            transform: rotate(45deg);
            content: "";
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &--disabled {
        .c-chip {
            color: $color-gray-08;
        }
        .delete {
            display: none;
        }
    }
}

.btn-image {
    vertical-align: middle;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-right: 10px;
    width: 160px;
    height: 160px;
    border: 1px dashed #ddd;
    background: #fff;
    text-align: center;
    margin: 5px 10px 5px 0px;

    &:before {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
        content: "";
        display: inline-block;
        @include sprite($sp_icon_add_img);
    }

    .title {
        width: 100%;
        position: absolute;
        left: 0;
        top: 20px;
        font-size: 13px;
        line-height: 18px;
        color: #666;
    }

    .info {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 20px;
        font-size: 13px;
        line-height: 18px;
        color: #666;
        text-align: center;
    }

    .inp {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &.size-320 {
        width: 320px;
    }
}

.add-image {
    vertical-align: middle;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 5px 10px 5px 0px;
    font-size: 0;

    .img {
        position: relative;
        z-index: 1;
        width: 160px;
        height: 160px;
        border: 1px solid #ddd;
        overflow: hidden;

        img {
            max-width: 160px;
            max-height: 160px;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .zoom {
        display: none;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        overflow: hidden;
        line-height: 200px;
        @include sprite($sp_icon_zoom);
    }

    .del {
        display: none;
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        overflow: hidden;
        line-height: 200px;
        @include sprite($sp_icon_del_img);
    }

    &.active,
    &:hover {
        .img {
            border-color: $color-11st-red;
        }

        .zoom,
        .del {
            display: block;
        }
    }

    &.size-320 {
        width: 320px;
        background-color: #fff;

        .img {
            position: relative;
            z-index: 1;
            width: 320px;
            height: 160px;
            border: 1px solid #ddd;
            overflow: hidden;

            img {
                max-width: 320px;
                max-height: 160px;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }
}

[class^="link link-style"] {
    &.underline {
        text-decoration: underline;
    }

    margin: 0 3px;
}

.c-more-chip {
    position: relative;
    padding: 0 27px 0 12px;
    border: 1px solid $color-gray-10;
    border-radius: 100px;
    background-color: $color-gray-14;
    color: $color-11st-blue;
    line-height: 30px;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -3px;
        width: 5px;
        height: 5px;
        border: 1px solid $color-11st-blue;
        border-width: 1px 1px 0 0;
        transform: rotate(135deg);
    }
}

.mapping_arrow_down {
    vertical-align: middle;
    margin: -4px 0 0 0;
}

// input file type custom
label[class*="btn-"]:has([type="file"]) {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    [type="file"] {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
