@charset "UTF-8";

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto-Regular"), url("/css/fonts/roboto-v20-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local("Roboto Bold"), local("Roboto-Bold"), url("/css/fonts/roboto-v20-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/roboto-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: "11StreetGothic";
    font-style: normal;
    font-weight: 400;
    src: url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff");
}

@font-face {
    font-family: "11StreetGothic";
    src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "11StreetGothicBold";
    font-weight: bold;
    src: url("/css/fonts/11STREET_Gothic_bold.eot");
    src: url("/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("/css/fonts/11STREET_Gothic_bold.ttf") format("truetype");
}

/* new lato-regular - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* new lato-700 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}

/* new lato-900 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
    margin: 0;
    padding: 0;
}

html {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background: #eee;
}

body {
    width: 100%;
    height: 100%;
}

dl,
ul,
ol,
menu,
li {
    list-style: none;
}

img,
fieldset,
iframe {
    border: 0 none;
}

img {
    vertical-align: top;
}

input,
select,
textarea,
button {
    vertical-align: middle;
}

input::-ms-clear {
    display: none;
}

button {
    border: 0 none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 0;
    box-sizing: border-box;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
}

caption,
legend {
    position: absolute;
    top: -9999px;
    left: -9999px;
    font-size: 0px;
    line-height: 0;
}

a {
    color: #666;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
    &:active {
        background-color: transparent;
    }
}

body,
h1,
h2,
h3,
h4,
th,
td,
input,
select,
textarea,
button {
    font-size: 14px;
    line-height: 1.5;
    font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
    color: #666;
    letter-spacing: -1px;
}

hr {
    display: none;
}

address,
caption,
cite,
code,
dfn,
em,
var {
    font-style: normal;
    font-weight: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
main {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote {
    &:before,
    &:after {
        content: "";
        content: none;
    }
}

q {
    &:before,
    &:after {
        content: "";
        content: none;
    }
}
