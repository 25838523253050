@charset "UTF-8";

.dialog-body.scroll {
    max-height: calc(100vh - 150px);
    overflow: hidden;
    overflow-y: auto;
}

.dialog-bg,
.alert-bg,
.main-lypop__bg,
.mov-pop__bg,
.cloud-pop__bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: hidden;
}

//dialog
.dialog-bg {
    .dialog,
    .dialog-ifrm {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 760px;
        min-width: 760px;
        height: auto;
        padding: 0;
        background: #fff;
        border: 1px solid #ddd;
        transform: translate(-50%, -50%);

        &.large {
            max-width: 980px;
            min-width: 980px;
        }

        &.small {
            max-width: 460px;
            min-width: 460px;
        }

        .dialog-header {
            position: relative;
            padding: 0 49px 0 20px;
            height: 60px;
            border: 1px solid transparent;
            border-bottom: 1px solid #ddd;
            text-align: center;

            .dialog-header__title {
                font-size: 18px;
                line-height: 60px;
                color: $color-gray-03;
                font-weight: bold;
            }
        }

        .dialog-body {
            padding: 30px 39px;
            max-height: calc(100vh - 180px);
            overflow: hidden;
            overflow-y: auto;
            background-color: #fff;
        }

        .dialog-bottom {
            position: static;
            height: auto;
            border-top: 1px solid #ddd;
            padding: 16px 40px 15px;
            text-align: center;

            button {
                margin: 0 5px;
            }

            .dialog-bottom__left {
                float: left;
                text-align: left;
            }

            .dialog-bottom__right {
                float: right;
                text-align: right;
            }

            @include clearfix();
        }

        .dialog-close {
            position: absolute;
            top: 14px;
            right: 10px;
            @include ico-cancel(17px, 1px, #333, 9px);
        }

        .c-guide:first-child {
            margin-top: 0;
        }
    }

    .dialog-ifrm {
        height: 100%;
        width: 100%;
    }

    &--po {
        .dialog,
        .dialog-ifrm {
            top: 0;
            transform: translate(-50%, 0%);
            .dialog-body {
                max-height: calc(100vh - 480px);
            }
        }
    }
}

body {
    // header, bottom height layer popup 과 다름
    .dialog {
        min-width: 600px;
        padding: 60px 0 80px;
        height: 100vh;
        background-color: #fff;

        .dialog-header {
            position: fixed;
            top: 0;
            width: 100%;
            padding: 0 49px 0 20px;
            height: 60px;
            border-bottom: 1px solid #ddd;
            text-align: center;
        }

        .dialog-header__title {
            font-size: 18px;
            line-height: 60px;
            color: #111;
            font-weight: bold;
        }

        .dialog-body {
            padding: 24px 40px;
            max-height: calc(100vh - 140px);
            overflow: hidden;
            overflow-y: auto;

            &:nth-last-child(2) {
                max-height: calc(100vh - 72px);
            }
        }

        .dialog-bottom {
            $dialog-color: red;
            position: fixed;
            z-index: 100;
            bottom: 0;
            width: 100%;
            height: 80px;
            border-top: 1px solid #ddd;
            background-color: #fff;
            padding: 16px 40px;
            text-align: center;

            button {
                margin: 0 5px;
            }

            .dialog-bottom__left {
                float: left;
                text-align: left;

                .check-style__2 {
                    margin: 4px 0 0 -9px;

                    .lab {
                        font-size: 13px;
                        color: #666;
                    }
                }
            }

            .dialog-bottom__right {
                float: right;
                text-align: right;
            }
            @include clearfix();
        }

        .dialog-close {
            position: absolute;
            top: 14px;
            right: 10px;
            @include ico-cancel(17px, 1px, #333, 9px);
        }
        // red color popup
        &.dialog-style2 {
            .dialog-header {
                background-color: $color-11st-red;
            }

            .dialog-header__title {
                color: #fff;
            }

            .dialog-body {
                max-height: calc(100vh - 120px);
            }

            .dialog-close {
                position: absolute;
                top: 9px;
                right: 10px;
                @include ico-cancel(17px, 1px, #fff, 9px);
            }
        }

        &.dialog-dim {
            &::after {
                content: "";
                z-index: 300; //jqx 그리드 헤더 z-index값이 189이상부터 지정이되어 300이상으로 지정함
                position: absolute;
                inset: -1px;
                background-color: rgba(0, 0, 0, 0.6);
            }
        }
        &__inner {
            display: none;
            z-index: 2;
            position: absolute;
            inset: -1px;
            background: rgba(0, 0, 0, 0.6);
            &-bg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 490px;
                padding: 40px;
                border: 1px solid $color-gray-10;
                background: $color-gray-14;
            }

            &.active {
                display: block;
            }

            &--loading {
                width: 64px;
                height: 64px;
                margin: 0 auto 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M31 16H33V22H31V16Z' fill='%23F43142'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M31 42H33V48H31V42Z' fill='%23F43142' fill-opacity='0.5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M48 31V33H42V31H48Z' fill='%23F43142' fill-opacity='0.2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22 31V33H16V31H22Z' fill='%23F43142' fill-opacity='0.8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M39.1338 17.6436L40.8658 18.6436L37.8658 23.8397L36.1338 22.8397L39.1338 17.6436Z' fill='%23F43142' fill-opacity='0.05'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M26.1338 40.1602L27.8658 41.1602L24.8658 46.3563L23.1338 45.3563L26.1338 40.1602Z' fill='%23F43142' fill-opacity='0.6'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M45.3564 23.1338L46.3564 24.8658L41.1603 27.8658L40.1603 26.1338L45.3564 23.1338Z' fill='%23F43142' fill-opacity='0.1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.8398 36.1338L23.8398 37.8658L18.6437 40.8658L17.6437 39.1338L22.8398 36.1338Z' fill='%23F43142' fill-opacity='0.7'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M46.3564 39.1338L45.3564 40.8658L40.1603 37.8658L41.1603 36.1338L46.3564 39.1338Z' fill='%23F43142' fill-opacity='0.3'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M23.8398 26.1338L22.8398 27.8658L17.6437 24.8658L18.6437 23.1338L23.8398 26.1338Z' fill='%23F43142' fill-opacity='0.9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M40.8662 45.3564L39.1342 46.3564L36.1342 41.1603L37.8662 40.1603L40.8662 45.3564Z' fill='%23F43142' fill-opacity='0.4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M27.8662 22.8398L26.1342 23.8398L23.1342 18.6437L24.8662 17.6437L27.8662 22.8398Z' fill='%23F43142'/%3E%3C/svg%3E");
                &.motion {
                    animation: dialogInnerLoading 1s linear infinite;
                }
            }
        }
    }
}
@keyframes dialogInnerLoading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dialog-block {
    font-size: 0;
    margin-top: 20px;
    text-align: center;

    [class^="btn-style"] {
        margin: 0 5px;
    }
}

//alert
.alert-bg {
    &--po {
        .alert {
            top: 0;
            transform: translate(-50%, 0%);
        }
    }
}

.alert {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    width: 490px;
    background: #fff;
    border: 1px solid #ddd;
    transform: translate(-50%, -50%);

    .alert-header {
        height: 0;
        overflow: hidden;
    }

    .alert-body {
        padding: var(--alert-body-pd-top, 40px) var(--alert-body-pd-right, 40px) var(--alert-body-pd-bottom, 40px) var(--alert-body-pd-left, 40px);

        &.pd-vertical-20 {
            --alert-body-pd-top: 20px;
            --alert-body-pd-bottom: 20px;
        }
        &.pd-horizontal-20 {
            --alert-body-pd-right: 20px;
            --alert-body-pd-left: 20px;
        }
    }

    .alert-banner {
        padding: 0 20px 16px 20px;
        img {
            width: 450px;
        }
    }

    .alert-bottom {
        border-top: 1px solid $color-gray-10;
        min-height: 56px;
        text-align: center;

        button {
            margin: 15px 5px;
            padding: 0 18px 0;
        }

        .alert-bottom__left {
            float: left;
            text-align: left;

            .check-style__2 {
                margin: 8px 0 0 8px;
            }
            .check-style__2 .lab {
                color: #666;
            }
        }

        .alert-bottom__right {
            float: right;
            text-align: right;
        }
    }

    .alert-close {
        position: absolute;
        top: 14px;
        right: 14px;
        @include ico-cancel(12px, 1px, #949494);
    }

    .alert-text {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.54px;
        text-align: center;
    }
}

.c-alert {
    font-size: 16px;
    letter-spacing: -0.47px;
    font-weight: bold;
    text-align: center;
    color: #111;

    &--thin {
        font-weight: 400;
    }
    &-small {
        font-size: 15px;
    }
    // c-alert--info 클래스 center 정의로 인해 left 사용 불가로 인해 important 추가
    &--left {
        text-align: left !important;
    }
    &--margin-top {
        margin-top: 20px;
    }
    .sub_text {
        display: block;
        margin-top: 8px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.44px;
        color: #666;
        font-weight: normal;
    }

    & + .c-progress {
        margin-top: 40px;
    }

    & + .c-spinner {
        position: static;
        margin-top: 30px;
    }

    &--info {
        color: $color-11st-red;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;

        &::before {
            display: inline-block;
            @include sprite-retina($sp_icon_2x_info_red_18);
            vertical-align: middle;
            margin: -4px 4px 0 0;
            content: "";
        }

        &:not(:first-child) {
            margin-top: 20px;
        }
    }
}

.c-alert__block {
    position: relative;
    margin-top: 20px;
    text-align: center;

    [class^="check-style"] {
        &:first-child {
            margin-left: -9px;
        }

        .lab {
            color: #333;
            font-size: 15px;
            letter-spacing: 0;
        }
    }
}

//window popup
.c-popup {
    min-width: 600px;
    padding: 58px 0;
    height: 100vh;
    background-color: #fff;

    .popup-header {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 0 49px 0 15px;
        height: 58px;
        border-bottom: 1px solid #ddd;
        text-align: center;
    }

    .popup-header__title {
        font-size: 18px;
        line-height: 58px;
        color: #111;
        font-weight: bold;

        &--left {
            text-align: left;
        }
    }

    .popup-body {
        padding: 24px 40px;
        max-height: calc(100vh - 116px);
        overflow: hidden;
        overflow-y: auto;
    }

    .c-popup__block {
        font-size: 0;
        margin-top: 20px;
        text-align: center;

        [class^="btn-style"] {
            margin: 0 5px;
        }
    }

    .popup-bottom {
        position: fixed;
        z-index: 100;
        bottom: 0;
        width: 100%;
        height: 58px;
        border-top: 1px solid #ddd;
        background-color: #fff;
        padding: 5px 40px;
        text-align: center;

        button {
            margin: 0 5px;
        }

        .popup-bottom__left {
            float: left;
            text-align: left;

            .check-style__2 {
                margin: 4px 0 0 -9px;

                .lab {
                    font-size: 13px;
                    color: #666;
                }
            }
        }

        .popup-bottom__right {
            float: right;
            text-align: right;
        }

        @include clearfix();
    }

    .popup-close {
        position: absolute;
        top: 9px;
        right: 10px;
        @include ico-cancel(17px, 1px, #333, 9px);
    }

    &.c-popup__style2,
    &.c-popup--style2 {
        .popup-header {
            background-color: $color-11st-red;
        }

        .popup-header__title {
            color: #fff;
        }

        .popup-close {
            @include ico-cancel(17px, 1px, #fff, 9px);
        }
    }

    &.c-popup--style3 {
        min-width: 600px;
        padding: 0;
        height: 100vh;
        background-color: #fff;

        .popup-header {
            position: static;
            top: auto;
            width: calc(100% - 80px);
            margin: 0 auto;
            padding: 40px 49px 40px 20px;
            height: 131px;
            border-bottom: 1px solid #ddd;
            text-align: center;
            background-color: $color-grayscale-13;
        }

        .popup-body {
            padding: 24px 40px;
            max-height: calc(100vh - 227px);
            overflow: hidden;
            overflow-y: auto;
        }

        .popup-header__title {
            color: #fff;
        }

        .popup-close {
            @include ico-cancel(17px, 1px, #fff, 9px);
        }

        .popup-bottom {
            position: fixed;
            z-index: 100;
            left: 40px;
            bottom: 0;
            width: calc(100% - 80px);
            height: 96px;
            border-top: 1px solid #ddd;
            background-color: #fff;
            padding: 24px 40px;
            text-align: center;

            button {
                margin: 0 5px;
                padding: 0 20px;
            }

            .popup-bottom__left {
                float: left;
                text-align: left;

                .check-style__2 {
                    margin: 4px 0 0 -9px;

                    .lab {
                        font-size: 13px;
                        color: #666;
                    }
                }
            }

            .popup-bottom__right {
                float: right;
                text-align: right;
            }

            @include clearfix();
        }
    }

    &--style4 {
        min-width: auto;
        .popup-header {
            background-color: $color-11st-red;
        }

        .popup-header__title {
            color: #fff;
        }

        .popup-body {
            padding: 24px 30px;
            height: 100%;
            max-height: calc(100vh - 166px);
            overflow: hidden;
            overflow-y: auto;
        }

        .popup-bottom {
            position: fixed;
            z-index: 100;
            bottom: 0;
            width: 100%;
            height: 108px;
            border-top: none;
            background-color: #fff;
            padding: 30px 30px;
            text-align: center;
        }

        .popup-close {
            @include ico-cancel(17px, 1px, #fff, 9px);
        }
    }

    &--non-bottom {
        padding-bottom: 0;
        .popup-body {
            max-height: calc(100vh - 58px);
        }
    }
}

.c-tooltip {
    position: absolute;
    z-index: 100;
    width: 420px;
    width: 400px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.36), 0px 6px 20px -8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: #fff;

    .tooltip-header {
        padding: 15px 64px 15px 16px;
        border-bottom: 1px solid #eee;
        + .tooltip-body + .tooltip-close {
            // 툴팁 해더가 있을 경우 닫기 버튼의 위치가 변경 됨
            top: 15px;
        }
    }
    .tooltip-title {
        @include text-ellipsis;
        font-weight: 700;
        font-size: 16px;
        line-height: 23px;
    }
    .tooltip-body {
        padding: 20px 45px 20px 20px;
        font-weight: normal;
    }

    .tooltip-close {
        position: absolute;
        z-index: 2;
        top: 20px;
        right: 16px;
        @include ico-cancel(12px, 1px, #111, 6px);
    }

    .tooltip-list {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.04em;
        color: #111;
    }

    ul.tooltip-list {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: -0.4px;

        li {
            position: relative;
            z-index: 1;
            padding-left: 14px;

            &:before {
                position: absolute;
                z-index: 1;
                left: 0;
                width: 14px;
                text-align: center;
                content: "•";
            }
            + li {
                margin-top: 5px;
            }
        }
    }
}
