@charset "UTF-8";

.c-product-card {
    &__thumb {
        position: relative;
        display: inline-block;
        width: 80px;
        height: 80px;
        min-width: 80px;
        background-color: $color-gray-14;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }

        &--border {
            border: 1px solid $color-gray-11;
        }
    }

    &__name {
        color: $color-gray-02;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-all;
    }

    &__attr {
        margin-top: 10px;

        .date {
            float: left;
            display: block;
            margin-right: 10px;
            color: $color-gray-04;
            font-size: 13px;
            line-height: 19px;
        }

        .time {
            float: left;
            display: block;
            color: $color-gray-04;
            font-size: 13px;
            line-height: 19px;
        }

        .brand,
        .cate {
            display: block;
            margin-top: 2px;
            color: #666;
            font-size: 13px;
            line-height: 19px;
        }
        .c-price-info {
            color: $color-gray-04;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.39px;
            &--emphasis {
                color: $color-11st-red;
            }
            &--price {
                color: $color-gray-02;
            }
        }

        @include clearfix();
    }
}
