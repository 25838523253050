.c-caution {
    padding: 20px;
    background: $color-gray-13;
    border: 1px solid $color-gray-11;

    &__title {
        position: relative;
        padding-left: 18px;
        color: $color-11st-red;
        font-size: 14px;
        font-weight: normal;
        line-height: 19px;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            display: block;
            @include sprite-retina($sp_icon_2x_info_red_28);
            content: "";
        }
    }

    &__list {
        margin: 10px 0 0 17px;
        color: $color-gray-04;
        font-size: 14px;
        line-height: 22px;

        li {
            list-style: decimal;
        }

        &--bullet {
            margin-top: 0;
            li {
                list-style: none;
                position: relative;
                padding-left: 10px;
                &:before {
                    position: absolute;
                    top: 10px;
                    left: 0;
                    display: block;
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background-color: $color-gray-04;
                    content: "";
                }
            }
        }
    }

    &:not(:first-of-type) {
        margin-top: 24px;
    }
}
