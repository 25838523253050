@charset "UTF-8";

.gicon {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    line-height: 200px;
}

.gicon-temporary-storage {
    &--text {
        line-height: 1.5;
        &::before {
            @include sprite-retina($sp_icon_2x_temporary-storage);
            display: inline-block;
            margin: -3px 10px 0 0;
            vertical-align: middle;
            content: "";
        }

        &:hover {
            &::before {
                @include sprite-retina($sp_icon_2x_temporary-storage-on);
            }
        }
    }
}

.gicon-connect {
    &--text {
        line-height: 1.5;
        &::before {
            @include sprite-retina($sp_icon_2x_icon-connect);
            display: inline-block;
            margin: -3px 4px 0 0;
            vertical-align: middle;
            content: "";
        }

        &:disabled {
            &::before {
                @include sprite-retina($sp_icon_2x_icon-unconnect);
            }
        }
    }
}

.gicon-preview {
    @include sprite($sp_icon_preview);
    @at-root .btn-size__1 {
        .gicon-preview {
            margin-top: -4px;
        }
    }
}

.gicon-must {
    position: relative;
    width: 8px;
    height: 8px;
    min-width: 8px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        @include sprite-retina($sp_icon_2x_must);
        content: "";
    }

    &--text {
        &::before {
            top: 6px;
        }
        padding-left: 13px;
        width: auto;
        overflow: visible;
        line-height: 19px;
        color: $color-grayscale-04;
        font-size: 13px;
    }
}

.gicon-question {
    @include sprite-retina($sp_icon_2x_help);
    min-width: 18px;
}

.gicon-autosave {
    @include sprite($sp_icon_auto_save);
}

.gicon-info {
    @include sprite($sp_icon_info);

    &--text {
        width: auto;
        height: auto;
        background: none;
        line-height: 1.5;
        &::before {
            @include sprite-retina($sp_icon_2x_icon-info);
            display: inline-block;
            vertical-align: middle;
            content: "";
        }
    }
}

.gicon-logo-11stEditor {
    @include sprite-retina($sp_icon_2x_11st-editor);
    vertical-align: middle;
    margin-top: -3px;
}

.gicon-swich {
    @include sprite-retina($sp_icon_2x_swich);
}

.gicon-download {
    @include sprite-retina($sp_icon_2x_download);
}

.gicon-fail {
    position: relative;
    background-color: $color-11st-red;
    border-radius: 50%;
    @include ico-cancel(7px, 1px, #fff, 5px);
    width: 16px;
    height: 16px;
}

.gicon-pass {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #0b83e6;
    border-radius: 50%;

    &:before {
        position: absolute;
        margin-top: 4px;
        margin-left: 4px;
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 5px;
        border: 1px solid #fff;
        border-width: 1px 1px 0 0;
        transform: rotate(135deg);
        content: "";
    }
}

.gicon-loading {
    position: relative;
    width: 16px;
    height: 16px;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        @include sprite-retina($sp_icon_2x_refresh-16);
        content: "";
    }
}

.gicon-favorites {
    position: relative;
    width: 8px;
    height: 8px;
    min-width: 8px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        @include sprite-retina($sp_icon_2x_favorites);
        content: "";
    }

    &--text {
        &::before {
            top: 2px;
        }
        padding-left: 20px;
        width: auto;
        height: 19px;
        overflow: visible;
        line-height: 19px;
        color: $color-grayscale-04;
        font-size: 13px;
    }
}

.gicon-refresh {
    position: relative;
    width: 8px;
    height: 8px;
    min-width: 8px;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        @include sprite-retina($sp_icon_2x_refresh);
        content: "";
    }

    &--text {
        &::before {
            top: 2px;
        }
        padding-left: 20px;
        width: auto;
        height: 19px;
        overflow: visible;
        line-height: 19px;
        color: $color-grayscale-04;
        font-size: 13px;
    }

    @at-root {
        .btn-size__4 {
            &.gicon-refresh--text {
                padding: 0 10px 0;
                width: auto;
                height: 30px;
                overflow: visible;
                line-height: 30px;
                color: $color-grayscale-04;
                font-size: 13px;

                &::before {
                    position: relative;
                    display: inline-block;
                    vertical-align: middle;
                    margin: -8px 4px 0 0;
                }
            }
        }
    }
}
.gicon-arrow-top {
    position: relative;
    min-width: 16px;
    min-height: 24px;

    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include sprite-retina($sp_icon_2x_ico_arrow_top);
        content: "";
    }
}
.gicon-arrow-left {
    --gicon-arrow-size: 24px;
    width: var(--gicon-arrow-size);
    height: var(--gicon-arrow-size);
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.01' x='0.5' width='24' height='24' fill='%23CCCCCC'/%3E%3Cpath d='M9.65245 6.64054C9.85098 6.4486 10.1675 6.45393 10.3595 6.65245L15.3742 11.6505C15.5608 11.8435 15.5617 12.1495 15.3761 12.3436L10.3634 17.3465C10.1725 17.5461 9.85602 17.5532 9.65642 17.3624C9.45683 17.1716 9.44971 16.8551 9.64054 16.6555L14.321 11.999L9.64054 7.34755C9.46992 7.17108 9.45518 6.90137 9.59344 6.7088L9.65245 6.64054Z' fill='%23979797'/%3E%3Cmask id='mask0_109_6369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='9' y='6' width='7' height='12'%3E%3Cpath d='M9.65245 6.64054C9.85098 6.4486 10.1675 6.45393 10.3595 6.65245L15.3742 11.6505C15.5608 11.8435 15.5617 12.1495 15.3761 12.3436L10.3634 17.3465C10.1725 17.5461 9.85602 17.5532 9.65642 17.3624C9.45683 17.1716 9.44971 16.8551 9.64054 16.6555L14.321 11.999L9.64054 7.34755C9.46992 7.17108 9.45518 6.90137 9.59344 6.7088L9.65245 6.64054Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_109_6369)'%3E%3Crect x='0.5' width='24' height='24' fill='%23111111'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg) translateY(2px);
}
.gicon-arrow-right {
    --gicon-arrow-size: 24px;
    width: var(--gicon-arrow-size);
    height: var(--gicon-arrow-size);
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.01' x='0.5' width='24' height='24' fill='%23CCCCCC'/%3E%3Cpath d='M9.65245 6.64054C9.85098 6.4486 10.1675 6.45393 10.3595 6.65245L15.3742 11.6505C15.5608 11.8435 15.5617 12.1495 15.3761 12.3436L10.3634 17.3465C10.1725 17.5461 9.85602 17.5532 9.65642 17.3624C9.45683 17.1716 9.44971 16.8551 9.64054 16.6555L14.321 11.999L9.64054 7.34755C9.46992 7.17108 9.45518 6.90137 9.59344 6.7088L9.65245 6.64054Z' fill='%23979797'/%3E%3Cmask id='mask0_109_6369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='9' y='6' width='7' height='12'%3E%3Cpath d='M9.65245 6.64054C9.85098 6.4486 10.1675 6.45393 10.3595 6.65245L15.3742 11.6505C15.5608 11.8435 15.5617 12.1495 15.3761 12.3436L10.3634 17.3465C10.1725 17.5461 9.85602 17.5532 9.65642 17.3624C9.45683 17.1716 9.44971 16.8551 9.64054 16.6555L14.321 11.999L9.64054 7.34755C9.46992 7.17108 9.45518 6.90137 9.59344 6.7088L9.65245 6.64054Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_109_6369)'%3E%3Crect x='0.5' width='24' height='24' fill='%23111111'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-2px);
    margin-right: calc(-1 * var(--gicon-arrow-size));
}

.gicon-help-black {
    &:before {
        content: "";
        @include sprite-retina($sp_icon_2x_ic_18_help_black);
        display: inline-block;
        margin: -4px 4px 0 0;
        vertical-align: middle;
    }
}
.gicon-check-black {
    &:before {
        content: "";
        @include sprite-retina($sp_icon_2x_ic_18_check_black);
        display: inline-block;
        margin: -4px 4px 0 0;
        vertical-align: middle;
    }
}
.gicon-public {
    width: 40px;
    height: 17px;
    margin-top: -3px;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='17' viewBox='0 0 40 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='17' rx='8.5' fill='%230B83E6' fill-opacity='0.1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.00001 12.82L7.33131 13.6357L6.46077 11.995L4.63131 11.6741L4.89144 9.83498L3.60001 8.50002L4.89144 7.16507L4.63131 5.32598L6.46077 5.00507L7.33131 3.36432L9.00001 4.18002L10.6687 3.36432L11.5392 5.00507L13.3687 5.32598L13.1086 7.16507L14.4 8.50002L13.1086 9.83498L13.3687 11.6741L11.5392 11.995L10.6687 13.6357L9.00001 12.82Z' fill='%230B83E6'/%3E%3Cpath d='M6.89999 8.5L8.39999 10L11.1 7' stroke='white'/%3E%3Cpath d='M24.33 11.59C24.33 12.62 23.2 13.29 21.24 13.29C19.27 13.29 18.13 12.62 18.13 11.59C18.13 10.56 19.27 9.89 21.24 9.89C23.2 9.89 24.33 10.56 24.33 11.59ZM25.35 9.21H17.3V8.28H20.35V6.73H21.52V8.28H25.35V9.21ZM24.31 4.7C24.31 6.36 24.19 7.11 24.03 7.84C23.66 7.79 23.28 7.73 22.9 7.69C23.05 7.09 23.14 6.56 23.14 5.62H18.22V4.7H24.31ZM23.14 11.6C23.14 11.08 22.4 10.83 21.24 10.83C20.07 10.83 19.34 11.08 19.34 11.6C19.34 12.1 20.07 12.36 21.24 12.36C22.4 12.36 23.14 12.1 23.14 11.6ZM33.3223 9.63H32.1523V4.39H33.3223V9.63ZM27.2923 10.06H33.3323V13.3H32.1523V10.99H27.2923V10.06ZM31.3923 8.24L30.7223 9.14C30.0423 8.89 29.1123 8.17 28.7223 7.35C28.4623 8.26 27.4923 9.09 26.6623 9.38L25.9523 8.45C27.2523 7.98 28.1123 6.93 28.1123 5.58V4.81H29.3223V5.52C29.3223 6.91 30.3423 7.9 31.3923 8.24Z' fill='%230B83E6'/%3E%3C/svg%3E");
}
