@charset "UTF-8";

.b-box__trans {
    display: table;
    width: 100%;
    @include clearfix();

    .b-box__col {
        display: table-cell;
    }
}

.c-title__text {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
}

.non-title {
    .b-box__cont {
        padding-left: 160px;
    }

    .b-box-sub__cont {
        padding-left: 160px;
    }

    &__expand {
        .b-box__cont {
            padding-left: 24px;
        }

        .b-box-sub__cont {
            padding-left: 0;
        }
    }
    &--type2 .b-box-sub__cont {
        padding: 0 20px;
    }
}

.b-box {
    margin-top: 11px;
    border: 1px solid #ddd;
    padding: 17px 24px 20px;
    background: #fff;
    @include clearfix();

    &:first-of-type {
        margin-top: 0;
    }

    &__floating {
        &-bottom {
            position: absolute;
            bottom: 20px;

            &--right {
                right: 20px;
            }
        }
    }

    &--topline1 {
        position: relative;
        margin-top: 30px;
        &::before {
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $color-gray-11;

            content: "";
        }
    }

    &--topline-none {
        border-top: none;
    }

    &.b-box--closed {
        .b-box__header {
            border-bottom: none;
        }
        .b-box__body {
            display: none;
        }
    }

    .b-box__header {
        position: relative;
        z-index: 1;
        height: 43px;
        margin-bottom: 10px;
        padding: 0 0 16px 0;
        border-bottom: 1px solid #eee;
        font-size: 13px;

        &--height-auto {
            height: auto;
        }

        .c-title {
            .gicon {
                margin-left: 3px;
            }
        }

        .c-title .c-title__text {
            font-size: 18px;
            color: #111;
            line-height: 26px;
            font-weight: bold;
        }

        .b-box__header--right {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            line-height: 26px;

            button[class^="btn-"] {
                position: relative;
                z-index: 1;

                &:first-child {
                    &:before {
                        display: none;
                    }
                }

                &:before {
                    position: absolute;
                    left: -20px;
                    top: 2px;
                    display: inline-block;
                    width: 1px;
                    height: 16px;
                    margin: 0 20px;
                    background-color: #eee;
                    content: "";
                    vertical-align: middle;
                }
            }

            button {
                &.btn-floding {
                    &::before {
                        display: none;
                    }
                }
                &:not(:first-child) {
                    margin-left: 5px;
                }
            }

            .btn-defult {
                height: 22px;
                padding: 0 20px;
                font-size: 14px;
                color: #000;
                line-height: 22px;
                vertical-align: middle;
            }

            .c-title__count {
                //.grid-box .c-title .c-title__count 와 동일한 스펙
                padding-left: 10px;
                color: $color-gray-04;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: -0.7px;
                vertical-align: bottom;
            }

            &.b-box__header--right--style2 {
                .c-select {
                    vertical-align: middle;
                }
                button {
                    &:before {
                        position: relative;
                        top: auto;
                        left: auto;
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        margin-right: 4px;
                        margin-top: -2px;
                        margin-left: 0;
                        vertical-align: middle;
                        background-color: transparent;
                        content: "";
                    }
                }
            }
        }
        .c-title__count {
            //.grid-box .c-title .c-title__count 와 동일한 스펙
            padding-left: 10px;
            color: $color-gray-04;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.7px;
            vertical-align: bottom;

            @at-root .table-box__header .c-title__count {
                margin-top: 10px;
                line-height: 20px;
            }
        }
    }

    .b-box__body {
        .c-title__text {
            color: #111;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;

            &--heavy {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }
    }

    .b-box__button {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #ddd;
        text-align: center;
        font-size: 0;

        &.del-plus {
            margin-top: 0;
            padding: 20px 0;
            border-top: none;
        }

        button {
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    .b-box__bottom {
        padding: 15px 0;
        border-top: 1px solid #ddd;
        text-align: center;
        font-size: 0;

        button {
            &:not(:first-child) {
                margin-left: 10px;
            }
        }

        &--margin-top-none {
            margin-top: 0;
            border: none;
        }
    }

    //box-style__1 사용x
    &.box-style__1 {
        border: none;
        padding: 0;

        .b-box__header {
            height: 60px;
            line-height: 60px;
            padding: 0;
            margin-bottom: 0;
            border-color: #eee;

            .c-title .c-title__text {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .c-input + .c-guide {
            margin-top: 10px;
        }

        .c-guide {
            margin-top: 5px;
        }

        & + .box-style__3 {
            margin-top: 0;
        }
    }

    &.b-box--style1 {
        border: none;
        padding: 0;

        //모달, 팝업 b-box가 첫번째 요소일 경우 top 간격 조정
        &:first-of-type {
            .b-box__row {
                &:first-of-type {
                    padding-top: 0;
                }
            }
        }

        .b-box__header {
            height: 60px;
            line-height: 60px;
            padding: 0;
            margin-bottom: 0;
            border-color: #eee;

            .c-title .c-title__text {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .b-box__row {
            &--line {
                padding-top: 20px;
            }
        }
    }
    &.box-style__ &.box-style__2,
    &.b-box--style2 {
        border: none;
        padding: 0;

        .b-box__header {
            height: 60px;
            line-height: 60px;
            padding: 0;
            border-bottom: none;
            margin-bottom: 0;

            .c-title .c-title__text {
                font-size: 14px;
                line-height: 20px;
                font-weight: normal;
            }
        }

        .b-box__body {
            border: 1px solid #eee;
            background-color: #fafafa;
        }

        .b-box__title {
            padding-left: 24px;
        }

        .b-box__cont {
            padding-right: 18px;
        }

        .c-input + .c-guide {
            margin-top: 10px;
        }

        .c-guide {
            margin-top: 5px;
        }
    }
    &.box-style__3,
    &.b-box--style3 {
        padding: 0;
        border: none;
        border-top: 1px solid #eee;

        .b-box__row + .b-box__row {
            padding-top: 0;
        }

        .b-box__row {
            padding: 20px;
            border-right: 1px solid #eee;
            border-left: 1px solid #eee;
        }

        .b-box-sub {
            --b-box-sub-padding-right: 20px;
            --b-box-sub-padding-left: 20px;

            & + .b-box__bottom {
                border-top: none;
            }
        }
    }
    /* 상품등록 외 바닥*/
    &.box-style__4,
    &.b-box--style4 {
        padding: 0;

        .b-box__header {
            margin-top: 20px;
            margin-bottom: 0;
            height: 41px;
            padding: 0 23px;

            .b-box__header--right {
                right: 23px;
            }
        }

        .b-box__row {
            padding: 22px 23px;
            border-top: 1px solid #eee;

            &:first-child {
                border-top: none;
            }
        }

        .b-box__vertical {
            .b-box__title {
                .c-title {
                    float: left;
                    min-height: 40px;
                }
                .c-title__text {
                    font-weight: bold;
                }
            }
        }

        .b-box-sub {
            --b-box-sub-padding-right: 20px;
            --b-box-sub-padding-left: 20px;

            & + .b-box__bottom {
                border-top: none;
            }
            &--style2 {
                padding: 0;
                margin-bottom: 20px;
            }
        }

        .b-box__bottom {
            background-color: #fafafa;
        }

        .b-box--vertical,
        .b-box__vertical {
            .c-title__text {
                font-size: 16px;
            }
        }
    }
    &.b-box--style5 {
        padding: 0;
        border: none;
        border-top: 1px solid #eee;

        .b-box__body {
            .c-title__text {
                font-weight: bold;
            }
        }
    }
    /* 리테일 상품등록 */
    &.b-box--style6 {
        padding: 0;
        margin-top: 10px;
        border-top: 1px solid #ddd;

        .b-box__body {
            padding: 11px 0;
        }

        .b-box__header {
            margin-bottom: 0;
            height: 60px;
            padding: 17px 24px;
            box-sizing: border-box;

            .b-box__header--right {
                top: 12px;
                right: 23px;
            }
            &--height-auto {
                height: auto;
            }
        }

        .b-box__row {
            padding: 11px 24px;

            &:first-child {
                border-top: none;

                &.b-box__row--line {
                    padding-bottom: 20px;
                    margin-bottom: 11px;
                }
            }

            & + .b-box__row {
                &--line {
                    padding-top: 20px;
                    margin-top: 11px;
                }
            }

            &--line {
                padding-top: 11px;
            }
        }

        .b-box__bottom {
            margin-bottom: -11px;
            background-color: #fafafa;
        }

        .b-box-sub {
            --b-box-sub-padding-top: 11px;
            --b-box-sub-padding-bottom: 11px;

            background-color: $color-gray-14;
            border: none;
            &::after {
                display: block;
                width: 100%;
                height: 1px;
                background-color: $color-gray-11;
                content: "";
            }

            &__row {
                border-left: 1px solid $color-gray-11;
                border-right: 1px solid $color-gray-11;

                &:first-child {
                    border-top: 1px solid $color-gray-11;
                }
            }

            & + .b-box-sub {
                margin-top: -11px !important;
            }
            &.pd-0 {
                --b-box-sub-padding-top: 0;
                --b-box-sub-padding-bottom: 0;
            }
            &.pt-0 {
                --b-box-sub-padding-top: 0;
            }
            &.pb-0 {
                --b-box-sub-padding-bottom: 0;
            }
        }

        .b-box--vertical {
            .b-box__title {
                & ~ .b-box__cont {
                    .c-guide:first-child {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    &.b-box--style7 {
        border: 0;
        padding: 0;
        .b-box__header {
            height: auto;
            padding: 0;
            margin-bottom: 0;
            border: 0;
            line-height: normal;
            .c-title__text {
                font-size: 16px;
                line-height: 24px;
            }
        }
        & + .b-box--style7 {
            margin-top: 10px;
            border-top: 1px solid #eee;
            .b-box__header {
                margin-top: 30px;
            }
        }
        .summary-box + .table-box,
        .tbl + .c-guide-list {
            margin-top: 6px;
        }
    }
    .color-brand {
        color: $color-brand;
    }

    &.non-line {
        border-top: none !important;
    }

    .pb-none {
        padding-bottom: 0;
    }
}

.b-box__row {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 20px 0;
    font-size: 0;

    .c-title {
        display: flex;
        position: relative;
        padding-right: 20px;
        color: #111;
        font-size: 14px;
        vertical-align: middle;

        .c-title__text {
            margin-top: 10px;

            &--heavy {
                margin-top: 0;
            }
        }

        .gicon {
            margin-left: 5px;
            &.gicon-question {
                margin-top: 12px;
            }
            &.gicon-must {
                margin-top: 17px;
                vertical-align: top;
            }
            &.gicon-info {
                margin-top: 10px;
            }
        }
        //b-box__cont가 내용이 텍스트로 시작할때 정렬을 위해 사용
        &--margin-top-none {
            .c-title__text {
                margin-top: 2px;
            }
            .gicon {
                &.gicon-must {
                    margin-top: 9px;
                }
                &.gicon-question {
                    margin-top: 4px;
                }
            }
        }
    }

    .b-box__title {
        display: table-cell;
        width: 160px;
        vertical-align: top;

        .c-title__count {
            display: inline-block;
            color: $color-grayscale-04;
            font-size: 13px;
            vertical-align: middle;
            margin-left: 4px;

            .value {
                color: $color-11st-red;
            }
        }
    }

    .b-box__title--right {
        float: right;
        line-height: 40px;
    }

    .b-box__cont {
        display: table-cell;
        font-size: 0;

        .tbl:nth-child(1) {
            margin-top: 4px;
        }

        .tbl--style3:nth-child(1) {
            margin-top: 10px;
        }

        .table-scroll {
            .tbl--style3:nth-child(1) {
                margin-top: 30px;
                border-collapse: separate;
            }
        }
    }

    &.line {
        border-top: 1px solid #eee;
        padding-top: 10px !important;
    }

    &.non-line {
        border-top: none !important;
        padding-top: 14px !important;
    }

    &:first-child {
        &.b-box__row--line {
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
        }
    }

    &:not(:first-child) {
        &.b-box__row--line {
            border-top: 1px solid #eee;
        }
    }
}

.b-box__row + .b-box__row {
    padding-top: 0;
}

.b-box-sub {
    padding: var(--b-box-sub-padding-top, 0) var(--b-box-sub-padding-right, 0) var(--b-box-sub-padding-bottom, 0) var(--b-box-sub-padding-left, 0);
    border: 1px solid #eee;
    background: #fafafa;

    .input-text.auto {
        .inp {
            background: #eee;
        }

        .input-etc {
            background: #eee;
        }
    }

    & + .b-box-sub {
        margin-top: 10px !important;
    }

    & + .b-box__row:not(:first-child) {
        &.b-box__row--line {
            border-top: none;
        }
    }

    &--style2 {
        margin: 0 24px;

        &:last-child {
            margin-bottom: 30px;
            &:has(.b-box-sub__bottom) {
                //가입 - 고객확인 의무정보고객확인 의무정보
                margin-bottom: 0;
                --b-box-sub-padding-bottom: 0;
            }
        }

        .c-text--value {
            line-height: 32px;
        }

        .b-box-sub__row {
            .c-title {
                min-height: 32px;
            }
        }

        &.b-box-sub--line {
            .b-box-sub__row {
                &:not(:first-child) {
                    padding-top: 20px;
                }

                &--line-none {
                    border-top: none;

                    &:not(:first-child) {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    &--line {
        border-top: none;

        .b-box-sub__row {
            border-top: 1px solid #eee;
        }
    }
    &--margin-none {
        @at-root &.b-box-sub--style2 {
            margin: 0;
        }
    }

    &.b-box-sub--none {
        display: none;
    }

    .grid-box {
        background-color: inherit;
    }
    &.pd-0 {
        --b-box-sub-padding-top: 0;
        --b-box-sub-padding-bottom: 0;
    }
    &.pt-0 {
        --b-box-sub-padding-top: 0;
    }
    &.pb-0 {
        --b-box-sub-padding-bottom: 0;
    }
}

.b-box-sub__row {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 20px 0;
    background: #fafafa;

    &.line {
        border-top: 1px solid #eee;
        padding-top: 10px !important;
    }

    &--non-line {
        border-top: none;
    }

    .c-title {
        display: flex;
        position: relative;
        padding-right: 20px;
        font-size: 14px;
        color: #111;
        vertical-align: middle;

        .c-title__text {
            margin-top: 9px;
        }

        .gicon {
            margin-left: 5px;
            &.gicon-question {
                margin-top: 11px;
            }
            &.gicon-must {
                margin-top: 16px;
                vertical-align: top;
            }
            &.gicon-info {
                margin-bottom: -2px;
            }
        }
    }

    .b-box-sub__title {
        position: relative;
        z-index: 1;
        display: table-cell;
        padding-left: 20px;
        width: 160px;
        vertical-align: top;
        color: #111;

        .gicon-must {
            margin-left: 6px;
        }

        .c-title__count {
            display: inline-block;
            color: $color-grayscale-04;
            font-size: 13px;
            vertical-align: middle;
            margin: 10px 4px 0 4px;

            .value {
                color: $color-11st-red;
            }
        }
    }

    .b-box-sub__title--right {
        position: absolute;
        z-index: 1;
        right: 20px;
        top: 0;
    }

    .b-box-sub__cont {
        display: table-cell;
        padding-right: 20px;

        .c-guide {
            &:first-child {
                // margin-top: 8px;
                --guide-margin-top: 8px;
            }
            &.mt-0 {
                // 중첩으로 인한 추가, 개선 필요
                --guide-margin-top: 0;
            }
        }
    }

    .check-style__5 .lab:before {
        background: #fff;
    }

    .check-style__5 .inp:checked + .lab:before {
        background-color: #fafafa;
    }
}

.b-box-sub__row + .b-box-sub__row {
    padding-top: 0;
}

.b-box-sub__bottom {
    width: calc(100% - 48px);
    margin: 0 auto;
    padding: var(--b-box-sub--pt, 16px) var(--b-box-sub--pr, 0) var(--b-box-sub--pb, 16px) var(--b-box-sub--pl, 0);
    border-top: 1px solid #eee;
    text-align: center;
    font-size: 0;
    @include clearfix();

    button {
        &:not(:first-child) {
            margin-left: 10px;
        }
    }
    .b-box-sub__bottom--left {
        float: left;
    }
    .b-box-sub__bottom--right {
        float: right;
    }

    &--topline-none {
        border-top: none;
    }
    &--thin {
        //sub-box 하단 영역 스타일 (가입 간소화 셀러벙보입력 참고)
        --b-box-sub--pt: 0;
        --b-box-sub--pb: 0;
        width: 100%;
        text-align: left;

        // 하단 구분선 숨김 처리
        // 대신 상단 구분손으로 b-box-sub (위에 있는 박스) 의 구분선을 추가
        // 지우는 구분선은 .b-box.b-box--style6 .b-box-sub::after 에서 발생함
        border-bottom: 1px solid #fff;
        margin-bottom: -1px;
        position: relative;

        &:has(.del-plus) {
            padding-top: 10px;
        }
    }
    &.pb-20 {
        // 연속된 b-box-sub 사이에 끼었을때  여백으로 구분
        --b-box-sub--pb: 20px;
    }
}
.b-box__vertical,
.b-box--vertical {
    .c-title {
        .gicon {
            &.gicon-must {
                margin-top: 16px;
                vertical-align: top;
            }
        }
        .c-title__count {
            margin-top: 10px;
        }
    }
    .b-box__title,
    .b-box-sub__title {
        display: table;
        width: 100%;
    }

    .b-box__cont {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .b-box-sub__cont {
        display: table;
        width: 100%;
        padding: 0 20px;
    }
}

.b-box-sub--vertical {
    .c-title {
        .gicon {
            &.gicon-must {
                margin-top: 16px;
                vertical-align: top;
            }
        }
        .c-title__count {
            margin-top: 10px;
        }
    }
    .b-box-sub__title {
        display: table;
        width: 100%;
        padding: 0 20px;

        &:not(:first-of-type) {
            padding: 10px 20px;
        }
    }
    .b-box-sub__cont {
        display: table;
        width: 100%;
        padding: 0 20px;
    }
}

.b-box .b-box__col {
    display: table-cell;

    &.th-150 {
        .b-box__title {
            width: 150px;
        }
    }
}

.b-box .b-box__col:nth-child(1):nth-last-child(2),
.b-box .b-box__col:nth-child(1):nth-last-child(2) ~ div {
    width: 50%;
}

.b-box_listing {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: #fafafa;
    border: 1px solid #eee;
    font-size: 0;

    &:after {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 1px;
        height: 100%;
        margin: 0 auto;
        background-color: #eee;
        content: "";
    }

    .col {
        display: inline-block;
        width: 50%;
        height: 100%;
        padding: 40px;
        font-size: 14px;
        vertical-align: top;

        .c-title__text {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            padding-bottom: 9px;
            border-bottom: 1px solid #eee;
            color: #000;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.3px;
            line-height: 25px;

            .total {
                position: absolute;
                z-index: 1;
                right: 0;
                text-align: right;
            }
        }

        .list {
            width: 100%;
            color: #000;
            font-size: 14px;
            line-height: 1.79;
            letter-spacing: -0.3px;

            tr:nth-child(1n) th,
            tr:nth-child(1n) td {
                padding-top: 15px;
            }
            tr:nth-child(2n) th,
            tr:nth-child(2n) td {
                padding-bottom: 15px;
                border-bottom: 1px solid #eee;
            }

            th {
                color: #000;
                font-size: 14px;
                line-height: 25px;
                text-align: left;
                font-weight: normal;
                vertical-align: top;
                letter-spacing: -0.3px;

                .sub_text {
                    display: block;
                    margin-top: 5px;

                    .number {
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }

                .btn-style__1 {
                    margin-left: 10px;
                }
            }

            td {
                color: #000;
                font-size: 14px;
                line-height: 25px;
                text-align: right;
                vertical-align: top;
                letter-spacing: -0.3px;
            }
        }

        .guide {
            font-size: 13px;
            line-height: 25px;
            letter-spacing: -0.46px;
            color: $color-grayscale-05-1;
        }
    }
}

.title-box {
    position: relative;
    padding: 25px 24px;
    border: 1px solid #ddd;
    background: #fff;

    .gicon-question {
        margin-left: 4px;
    }

    .title-box__text {
        font-size: 20px;
        color: #333;
        display: inline-block;
        vertical-align: middle;
    }

    .title-box__must {
        position: absolute;
        right: 24px;
        bottom: 30px;
    }

    .title-box__description {
        position: relative;
        margin-top: 3px;
        font-size: 13px;
        color: #666;

        .title-description__header {
            padding-right: 200px;
            color: #333;
            font-weight: normal;
            letter-spacing: -0.46px;
            line-height: 26px;
        }
        .title-description__detail {
            display: none;
            margin: 24px 12px 2px;
            color: #666;
            line-height: 24px;
            letter-spacing: -0.46px;
        }
        .title-description__btn {
            padding: 0 16px;
            position: absolute;
            top: 24px;
            right: 19px;
            color: #111;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 32px;
            border: 1px solid #ccc;
            background: #fff;

            &:after {
                display: inline-block;
                margin-left: 16px;
                @include ico-angle(down, 7px, #949494, 1px, static);
            }
        }
        &.open {
            .title-description__detail {
                display: block;
            }
            .title-description__btn {
                &:after {
                    display: inline-block;
                    margin-left: 16px;
                    @include ico-angle(up, 7px, #949494, 1px, static);
                }
            }
        }
    }

    &__side {
        position: absolute;
        right: 24px;
        bottom: 30px;

        .gicon {
            margin-left: 4px;
        }
    }
}

.copy-box {
    position: relative;
    padding: 0 24px;
    border: solid 1px #e3e3e8;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    background-color: #f3f9fe;
    letter-spacing: 0;
    line-height: 50px;
    box-sizing: border-box;

    &__right {
        position: absolute;
        top: 0;
        right: 24px;

        .link-style1 {
            margin-top: -2px;
        }
    }
    &__bottom {
        position: absolute;
        bottom: 3px;
        right: 24px;
    }

    &__title {
        float: left;
        color: $color-grayscale-02;
        font-size: 15px;
        font-weight: bold;
        line-height: 50px;

        &.gicon-info--text {
            &:before {
                margin: -3px 7px 0 0;
            }
        }
    }

    &__count {
        color: $color-gray-01;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }

    &__info {
        float: left;
        max-width: calc(100% - 124px);
        margin: 1px 0 0 10px;
        color: $color-grayscale-04;
        font-size: 13px;
        line-height: 48px;
        @include text-ellipsis();
    }
    &__subscript {
        padding: 20px 0;
        color: $color-gray-03;
        font-size: 13px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.65px;
    }
    &__dl {
        padding-bottom: 20px;
    }
    &__dt {
        margin-bottom: 10px;
        color: $color-gray-02;
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
    }
    &__dd {
        color: $color-gray-04;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.65px;
        b {
            font-weight: 700;
        }
        + .copy-box__dd {
            margin-top: 4px;
        }
    }
    .color-brand {
        color: $color-brand;
    }

    @include clearfix();
}

.allim-box {
    position: relative;
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    letter-spacing: 0;
    line-height: 52px;

    .allim-box__right {
        position: absolute;
        top: 0;
        right: 24px;
    }

    .allim-box__text {
        position: relative;
        border: solid 1px #e3e3e8;

        &:before {
            position: absolute;
            top: 20px;
            left: 22px;
            display: block;
            overflow: hidden;
            content: "";
        }

        &:nth-of-type(1):nth-last-of-type(1) {
            width: 100%;
            height: 52px;
            background-color: #f3f9fe;
            padding: 0 24px 0 56px;

            &:before {
                top: 13px;
                @include sprite-retina($sp_icon_2x_allim);
            }
        }

        &:nth-of-type(1):nth-last-of-type(2) {
            margin-right: 10px;
        }

        &:nth-of-type(1):nth-last-of-type(2),
        &:nth-of-type(1):nth-last-of-type(2) ~ p {
            display: table;
            float: left;
            width: calc(50% - 5px);
            height: 64px;
            line-height: 1.3;
            padding: 13px 24px 13px 56px;

            a {
                display: table-cell;
                width: 100%;
                height: 100%;
                color: #333;
                vertical-align: middle;

                &:after {
                    position: absolute;
                    top: 27px;
                    right: 19px;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 7px;
                    height: 7px;
                    border: 1px solid #666;
                    border-width: 1px 1px 0 0;
                    transform: rotate(45deg);
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.allim-box__text--type1 {
            background-color: #f3f9fe;
            &:before {
                @include sprite-retina($sp_icon_2x_allim);
            }
        }

        &.allim-box__text--type2 {
            background-color: #fefbeb;
            &:before {
                @include sprite-retina($sp_icon_2x_allim_icon6);
            }
        }
    }

    @include clearfix();
}

.tab-box {
    position: relative;
    background-color: #fff;
    margin-top: 11px;

    &:not([style*="display: none"]) + .tab-box {
        margin-top: 0;
    }

    & + .b-box {
        margin-top: 0;
        border-top: none;
    }

    & + .search-box {
        &:before {
            display: none;
        }

        .search-box__row {
            &:first-child {
                border-top: none;
            }
        }
    }

    & > .c-radio__tab {
        width: 100%;
        padding: 32px 16px 22px;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
    }
    .c-tab__style2 {
        .c-tab__text {
            font-size: 14px;
            line-height: 49px;
            letter-spacing: -0.44px;
        }
    }

    &.tab-box__style2 {
        margin-top: 0px;

        .tab-box__cont {
            padding: 30px 20px 0;

            .c-info__group {
                border-top: none;

                &:not(:first-child) {
                    margin-top: 40px;
                }
            }

            .img_block {
                border: 1px solid #f4f4f4;
                background-color: #fafafa;
                text-align: center;

                &:not(:nth-of-type(1)) {
                    margin-top: 30px;
                }
            }
        }
    }

    &.tab-box--style3 {
        padding: 24px 24px 0;
        border: 1px solid #ddd;
        border-bottom: none;
        display: flex;

        &:not([style*="display: none"]) + .tab-box--style3 {
            border-top: none;
            padding: 20px 20px 14px;
        }

        & + .search-box {
            margin-top: 0;
            border-top: none;
            .search-box__header {
                .tab-box {
                    padding: 20px 0 15px;
                }

                .search-box__header-right {
                    top: 5px;
                }
            }
        }

        .tab-box__container {
            width: 100%;
            overflow: hidden;
        }

        .c-tab-navi {
            width: 44px;
            background-color: $color-gray-13;

            &__prev {
                width: 44px;
                background-color: $color-gray-13;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-gray-13;
                border: 1px solid $color-gray-11;
                border-bottom-color: $color-11st-red;
                border-right: none;

                &:after {
                    position: absolute;
                    top: 21px;
                    left: 17px;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    border: 1px solid $color-gray-03;
                    border-width: 1px 1px 0 0;
                    transform: rotate(-135deg);
                }
            }

            &__next {
                position: relative;
                width: 100%;
                height: 100%;
                background-color: $color-gray-13;
                border: 1px solid $color-gray-11;
                border-bottom-color: $color-11st-red;
                border-left: none;

                &:after {
                    position: absolute;
                    top: 21px;
                    right: 17px;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 8px;
                    height: 8px;
                    border: 1px solid $color-gray-03;
                    border-width: 1px 1px 0 0;
                    transform: rotate(45deg);
                }

                &:disabled {
                    display: none;
                }
            }

            &--disabled {
                display: none;
            }
        }

        & + .iframe-box {
            margin-top: 20px;
        }
    }

    + .c-guide {
        &:first-of-type {
            margin-top: 30px;
        }
    }
    + .c-guide-list {
        margin-top: 30px;
    }

    .c-btn {
        position: absolute;
        top: 8px;
        right: 0;
    }
}

.status-box {
    position: relative;
    z-index: 1;
    border: 1px solid #ddd;
    border-top: none;
    background: #fff;
    @include clearfix();
    &-board {
        width: 100%;
        height: 56px;
        padding: 18px 24px;
        font-size: 0;
        background-color: #fafafa;
        @include clearfix();

        &__item {
            float: left;

            &:not(:first-child) {
                &::before {
                    display: inline-block;
                    width: 1px;
                    height: 20px;
                    margin: 0 30px;
                    background-color: #ddd;
                    vertical-align: middle;
                    content: "";
                }
            }
        }
    }

    .status-btn {
        position: relative;
        display: inline-block;
        font-size: 13px;
        color: $color-grayscale-03;
        vertical-align: middle;

        .lab {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 100%;
            height: inherit;
            text-align: center;

            &:before {
                display: inline-block;
                content: "";
                margin: -3px 3px 0 0;
                vertical-align: middle;
            }

            .lab_text {
                display: inline-block;
                height: inherit;
                text-align: left;
                line-height: 20px;
            }
        }

        .inp {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 20px;
            opacity: 0;
            cursor: pointer;

            &:checked {
                + .lab {
                    color: $color-11st-red;
                    em {
                        color: $color-11st-red;
                    }
                }
            }

            &:hover,
            &:focus {
                + .lab {
                    em {
                        text-decoration: underline;
                    }
                }
            }
        }

        .count {
            display: inline-block;
            margin-left: 8px;

            em {
                font-size: 18px;
                font-weight: bold;
                color: #111;
                margin-right: 2px;
                font-family: $font-family-number;
                vertical-align: text-top;
            }
        }

        &--all {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-all);
                }
            }

            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-all-red);
                        }
                    }
                }
            }
        }
        &--sale {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-sale);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-sale-red);
                        }
                    }
                }
            }
        }
        &--soldout {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-soldout);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-soldout-red);
                        }
                    }
                }
            }
        }
        &--van {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-van);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-van-red);
                        }
                    }
                }
            }
        }

        &--temp {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-temp-save);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-temp-save-red);
                        }
                    }
                }
            }
        }

        &--request {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-approval-request);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-approval-request-red);
                        }
                    }
                }
            }
        }

        &--approved {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-approved);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-approved-red);
                        }
                    }
                }
            }
        }

        &--reject {
            .lab {
                &:before {
                    @include sprite-retina($sp_icon_2x_file-reject);
                }
            }
            .inp {
                &:checked {
                    + .lab {
                        &:before {
                            @include sprite-retina($sp_icon_2x_file-reject-red);
                        }
                    }
                }
            }
        }
    }

    & + .search-box {
        margin-top: 10px;
        border-top: 1px solid #ddd;
    }
}

.search-box-wrap {
    &--reduce {
        display: none;
    }

    .search-box {
        &:first-child {
            margin-top: 10px;
            border-top: 1px solid #ddd;
        }
    }
}

.search-box {
    position: relative;
    z-index: 1;
    border: 1px solid #ddd;
    border-top: none;
    background: #fff;
    @include clearfix();

    &__floating {
        &-bottom {
            position: absolute;
            bottom: 20px;

            &--right {
                right: 20px;
            }
        }
    }

    &.grid-expand {
        display: none;
        & + .grid-box {
            .btn-expand:after {
                @include sprite-retina($sp_icon_2x_grid_small);
            }
        }
    }

    &.search-box--closed {
        .search-box__body {
            display: none;
        }
    }

    &.detail-open {
        .detail-area {
            display: block;
        }

        .btn-detail {
            &:after {
                margin-top: 2px;
                transform: rotate(-45deg);
                content: "닫기";
            }
        }
    }

    &.search-hidden {
        .search-box__row,
        .search-box__bottom {
            display: none;
        }
        .search-box__btn-hidden {
            &:after {
                @include ico-angle(up, 7px, #949494, 1px, static);
            }
        }
    }

    &.search-box--horizontal {
        position: relative;
        border-top: 1px solid #ddd;

        &::before {
            display: none;
        }

        .search-box__row {
            width: calc(100% - 138px);
            padding: 0;
            border-top: none;

            &:not(:first-child) {
                border-top: 1px solid #ebebeb;
            }
        }

        .search-box__title {
            min-width: 121px;
            padding: 10px 0 10px 24px;
            border-right: 1px solid #ebebeb;
            background-color: #eee;
            vertical-align: middle;
        }

        .search-box__cont {
            padding: 10px 0 10px 25px;
            border-right: 1px solid #ebebeb;
        }

        .search-box__bottom {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateZ(0) translateY(-50%);
            width: 138px;
            padding: 0;
            border: none;
            background-color: #fff;
        }
    }

    .btn-detail {
        &:after {
            content: "열기";
            margin-top: -8px;
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
            width: 9px;
            height: 9px;
            border: 1px solid #666;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
            line-height: 48px;
            overflow: hidden;
        }

        &:hover {
            &:after {
                border-color: $color-11st-red;
            }
        }
    }

    .c-title {
        font-size: 14px;
        color: #111;
        line-height: 22px;
        letter-spacing: -0.5px;

        .c-title__text {
            font-weight: normal;
        }
    }

    .search-box__header {
        position: relative;
        z-index: 1;
        padding: 0 24px 0;
        font-size: 16px;
        display: table;
        width: 100%;

        .c-title {
            display: table-cell;
            width: 200px;
        }
        .c-title .c-title__text {
            font-size: 16px;
            color: #111;
            font-weight: bold;
            line-height: 60px;
            letter-spacing: -0.5px;

            //b-box__header > c-title__text 와 사이즈 통일
            &--style1 {
                font-size: 18px;
            }
        }
        .c-radio__tab {
            display: table-cell;
            vertical-align: middle;
        }
        .tab-box {
            display: table-cell;
            padding: 15px 0 15px;
            margin-top: 0;
        }
        .search-box__header-right {
            position: absolute;
            z-index: 1;
            right: 24px;
            top: 11px;

            //버튼영역에 안내문구 들어가는 경우
            .c-guide {
                display: inline-block;
            }

            .c-guide + button {
                margin-left: 12px;
            }
        }
    }

    .search-box__btn-hidden {
        padding: 0 16px;
        border: 1px solid #ccc;
        font-size: 13px;
        color: #111;
        letter-spacing: 0;
        line-height: 32px;
        vertical-align: middle;
        margin: 16px 0 16px 5px;

        &:after {
            display: inline-block;
            margin-left: 8px;
            @include ico-angle(down, 7px, #949494, 1px, static);
            content: "";
        }
    }

    .search-box__inquiry {
        display: table;
        width: 100%;
        height: 80px;
        font-size: 0;

        .search-box__item {
            display: table-cell;
            height: 80px;

            &:not(:first-child) {
                padding-left: 24px;
            }
        }

        .btn-inquiry {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 100%;
            height: 80px;
            font-size: 14px;
            color: #666;

            .lab {
                position: relative;
                z-index: 1;
                display: inline-block;
                width: 100%;
                height: 80px;
                text-align: center;

                &:before {
                    display: inline-block;
                    background: #fff;
                    content: "";
                    margin: -24px 4px 0 0;
                    vertical-align: middle;
                }

                .lab_text {
                    display: inline-block;
                    height: 80px;
                    padding: 16px 0 0 0;
                    text-align: left;
                    line-height: 24px;
                }
            }

            .inp {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;

                &:focus + .lab {
                    &:after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.3);
                        content: "";
                    }
                }

                &:hover + .lab {
                    border-bottom: 2px solid $color-11st-red;
                }

                &:checked + .lab {
                    border-bottom: 2px solid $color-11st-red;
                    color: $color-11st-red;

                    em {
                        color: $color-11st-red;
                    }
                }
            }

            .count {
                display: block;
                margin-top: -2px;

                em {
                    font-size: 20px;
                    font-weight: bold;
                    color: #111;
                    margin-right: 2px;
                    font-family: $font-family-number;
                }
            }

            &.inquiry-style1 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style1);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style1_active);
                    }
                }
            }

            &.inquiry-style2 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style2);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style2_active);
                    }
                }
            }

            &.inquiry-style3 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style3);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style3_active);
                    }
                }
            }

            &.inquiry-style4 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style4);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style4_active);
                    }
                }
            }

            &.inquiry-style5 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style5);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style5_active);
                    }
                }
            }

            &.inquiry-style6 {
                .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style6);
                    }
                }

                .inp:checked + .lab {
                    &:before {
                        @include sprite-retina($sp_icon_2x_inquiry_style6_active);
                    }
                }
            }
        }
    }

    .search-box__row {
        position: relative;
        display: table;
        width: 100%;
        border-top: 1px solid #eee;
        padding: 20px 0;

        &.non-line {
            border-top: none;
            padding-top: 0;
        }

        &--vertical {
            padding: 20px 24px;
        }
    }

    .search-box__title {
        display: table-cell;
        min-width: 165px;
        vertical-align: top;
        padding: 6px 0 0 24px;

        &--w100 {
            min-width: 100px;
        }

        &--non-top {
            padding-top: 0;
        }

        h3.c-title__text {
            margin-top: -6px;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $color-gray-02;
        }
    }

    .search-box__cont {
        display: table-cell;
        width: 100%;
        padding-right: 25px;
        font-size: 0;
    }

    .search-box__col {
        display: table-cell;
        vertical-align: top;

        .col__row {
            &:first-child {
                margin-top: 0;
            }

            margin-top: 10px;
        }

        &-grid {
            display: table-cell;
            vertical-align: top;

            .search-box__title {
                min-width: auto;
                padding-right: 10px;
            }

            .search-box__cont {
                width: 80%;
            }
            /*
            &:not(:nth-child(1):nth-last-child(1)) {
                .search-box__cont {
                    padding-right: 0;
                }
            }*/

            &--1 {
                width: 8.33333%;
            }
            &--2 {
                width: 16.66667%;
            }
            &--3 {
                width: 25%;
            }
            &--4 {
                width: 33.33333%;
            }
            &--5 {
                width: 41.66667%;
            }

            &--6 {
                width: 50%;
            }
            &--7 {
                width: 58.33333%;
            }
            &--8 {
                width: 66.66667%;
            }
            &--9 {
                width: 75%;
            }
            &--10 {
                width: 83.33333%;
            }
            &--11 {
                width: 91.66667%;
            }
            &--12 {
                width: 100%;
            }
        }
    }

    .search-box__grid {
        display: table-cell;
        vertical-align: top;

        &__inner {
            display: table;
            width: 100%;
            vertical-align: top;
        }

        .col__row {
            &:first-child {
                margin-top: 0;
            }

            margin-top: 10px;
        }

        &:nth-child(1):not(:nth-last-child(1)) {
            .search-box__cont {
                padding-right: 0;
            }
        }

        &--1 {
            width: 8.33333%;
        }
        &--2 {
            width: 16.66667%;
        }
        &--3 {
            width: 25%;
        }
        &--4 {
            width: 33.33333%;
        }
        &--5 {
            width: 41.66667%;
        }

        &--6 {
            width: 50%;
        }
        &--7 {
            width: 58.33333%;
        }
        &--8 {
            width: 66.66667%;
        }
        &--9 {
            width: 75%;
        }
        &--10 {
            width: 83.33333%;
        }
        &--11 {
            width: 91.66667%;
        }
        &--12 {
            width: 100%;
        }
    }

    .search-box__col:nth-child(1):nth-last-child(2),
    .search-box__col:nth-child(1):nth-last-child(2) ~ div {
        width: 50%;
    }

    .search-box__bottom {
        width: 100%;
        padding: 15px;
        //border-top: 1px solid #ddd;
        background: #fafafa;
        @include clearfix();
        text-align: center;
        font-size: 0;

        > button {
            margin: 0 5px;
        }

        .search-box__left {
            float: left;
        }

        .search-box__right {
            float: right;
        }
    }

    .detail-area {
        display: none;
    }
    &--style2 {
        border: 0;
        // border-color: $color-gray-10;
        // background: $color-gray-13;
        // 팝업에서 검색박스를 사용하는 경우 헤더에 padding 값을 삭제
        // 상품그룹관리 > 등록 > 상품추가 팝업
        // 상품그룹관리 > 등록 > 기준상품 선택하기 팝업
        // 쇼킹딜 > 개별 상품 신청 팝업
        .search-box__header {
            padding: 0;
            + .c-guide {
                margin: 0 0 20px 0;
            }
        }

        .search-box__row {
            border-color: $color-gray-10;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #eee;
            background: $color-gray-13;
            &:first-child {
                border-top: 1px solid #ddd;
            }
            &:nth-last-child(2) {
                border-bottom: 1px solid #ddd;
            }
        }
        .search-box__bottom {
            background: $color-gray-14;
        }
    }
}

.grid-box {
    margin-top: 16px;
    padding: 16px 0;
    border: 1px solid #ddd;
    background: #fff;

    .c-title {
        .c-title__text {
            font-size: 18px;
            color: #111;
            font-weight: bold;
            vertical-align: middle;
        }

        .c-title__count {
            display: inline-block;
            padding-left: 8px;
            color: $color-gray-04;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.7px;
            vertical-align: bottom;

            em {
                font-family: $font-family-number;
                //그리드 카운트 포인색상 추가
                &.color-brand {
                    color: $color-brand;
                }
            }
        }
    }

    .grid-box__header {
        position: relative;
        z-index: 1;
        padding: 9px 24px 25px;

        & + .grid-box__btn {
            border-top: none;
        }
    }

    .grid-box__right {
        position: absolute;
        z-index: 1;
        right: 5px;
        top: 9px;
        line-height: 26px;

        button[class^="btn-"] {
            position: relative;
            z-index: 1;

            &:first-child {
                &:before {
                    display: none;
                }
            }

            &:before {
                position: absolute;
                left: -20px;
                top: 2px;
                display: inline-block;
                width: 1px;
                height: 16px;
                margin: 0 20px;
                background: #eee;
                content: "";
                vertical-align: middle;
            }
        }

        .btn-excel {
            padding: 0 20px;
        }

        .btn-expand {
            height: 22px;
            padding: 0 20px;
            font-size: 14px;
            color: #000;
            line-height: 22px;
            vertical-align: middle;

            &:after {
                display: inline-block;
                vertical-align: middle;
                margin-top: -2px;
                content: "";
                @include sprite-retina($sp_icon_2x_grid_expand);
            }
        }

        &__btn {
            .c-btn {
                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }
    }

    .grid-box__filter {
        display: table;
        width: 100%;
        padding: 0 16px;
        font-size: 0;

        .c-select {
            .select {
                background-color: #fff;
            }

            &:not(:first-child):before {
                display: inline-block;
                z-index: 1;
                width: 1px;
                height: 16px;
                background: #ddd;
                margin: 0 10px;
                vertical-align: middle;
                line-height: 32px;
                content: "";
            }
        }

        .c-select + button {
            margin-left: 18px;
        }

        .filter__left {
            display: table-cell;
            width: 50%;

            button {
                margin-right: 5px;
            }
            &--vertical-top {
                vertical-align: top;
            }
            &--vertical-bottom {
                vertical-align: bottom;
            }
        }

        .filter__right {
            display: table-cell;
            width: 50%;
            text-align: right;
            button {
                margin-left: 5px;
            }
            &--vertical-top {
                vertical-align: top;
            }
            &--vertical-bottom {
                vertical-align: bottom;
            }
        }

        & + .grid-box__table {
            padding-top: 6px;
        }
    }

    .grid-box__btn {
        position: relative;
        padding: 14px;
        border-top: 1px solid #ddd;
        font-size: 0;

        .c-select {
            margin: 6px 4px;

            .select {
                width: 120px;
            }
        }

        .c-dropdown {
            margin: 6px 4px;

            .btn {
                width: 120px;
            }
        }

        [class^="btn-style"] {
            width: 120px;
            margin: 6px 4px;
        }

        &--style2 {
            padding: 14px 14px 5px;

            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            .c-select {
                margin: 6px 4px 6px 5px;
                &:first-child {
                    margin-left: 0;
                }

                .select {
                    width: 160px;
                }
            }

            [class^="btn-style"] {
                width: auto;
                margin: 6px 0 6px 5px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &-col {
            display: inline-block;
            vertical-align: middle;
            &--bar {
                &::before {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 15px;
                    width: 1px;
                    height: 20px;
                    background-color: #ddd;
                    content: "";
                }
            }
        }
        &-right {
            position: absolute;
            right: 0;
            top: 0;
        }

        .c-btn:not(:first-child) {
            margin-left: 5px;
        }
    }

    .grid-box__btn + .grid-box__filter {
        padding: 38px 16px 0;
        background-color: #fafafa;
        border-top: 1px solid #ddd;

        .c-select {
            .select {
                background-color: #fafafa;
            }
        }
    }
    .grid-box__btn + .grid-box__filter + .grid-box__table {
        margin-top: 0;
        padding: 15px 0;
        background-color: #fafafa;
    }

    .grid-box__table {
        margin-top: 8px;
        padding: 0 15px;
        letter-spacing: 0;
        font-size: 14px;

        & + .grid-box__table {
            border-bottom: none;
        }
    }

    &.grid-box--style1 {
        .grid-box__header {
            padding: 4px 24px 20px;
            border-bottom: 1px solid #eee;

            .c-title {
                .c-title__text {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .grid-box__right {
            position: absolute;
            z-index: 1;
            right: 24px;
            top: 0;
            line-height: 26px;

            button[class^="btn-"] {
                position: relative;
                z-index: 1;

                &:first-child {
                    &:before {
                        display: block;
                    }
                }

                &:before {
                    position: static;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    width: auto;
                    height: auto;
                    margin: 0;
                    background: none;
                    content: "";
                    vertical-align: middle;
                }
            }
        }
    }

    &.grid-box__style2,
    &.grid-box--style2 {
        border: none;
        margin-top: 0;
        padding: 0;

        .grid-box__header {
            padding: 0 0 15px 0;
            border-bottom: 1px solid #ddd;

            .c-title__text {
                font-size: 14px;
                letter-spacing: -0.44px;
                color: #111;
                font-weight: bold;
            }
            .btn-excel {
                padding-right: 10px;
                margin-bottom: 15px;
            }
        }

        .grid-box__table {
            margin-top: 10px;
            padding: 0;
        }
    }

    &.grid-box__style3,
    &.grid-box--style3 {
        border: none;
        margin-top: 0;
        padding: 0;

        &:first-child {
            margin-top: 10px;
        }

        .grid-box__header {
            padding: 0;

            button[class^="btn-"] {
                margin-left: 10px;
            }

            .c-title__text {
                font-size: 14px;
                color: #111;
                font-weight: normal;
            }
        }

        .grid-box__btn {
            padding: 0;

            &:first-child {
                border-top: none;
                padding-top: 0;
            }

            .c-select {
                margin: 6px 4px 6px 5px;
                &:first-child {
                    margin-left: 0;
                }

                .select {
                    width: 160px;
                }
            }

            [class^="btn-style"] {
                width: auto;
                margin: 0 0 0 5px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .grid-box__filter {
            margin-top: 10px;
            padding: 0;
        }

        .grid-box__right {
            right: 0;
        }

        .grid-box__table {
            margin-top: 10px;
            padding: 0;
        }
    }
    @at-root .c-guide + & {
        margin-top: 20px;
    }
    .grid-box__body {
        padding: 20px 0 0 0;
    }
}

%paging {
    margin-top: 20px;
    text-align: center;
    font-size: 0;

    a,
    button,
    strong {
        display: inline-block;
        width: 32px;
        height: 32px;
        color: #111;
        font-size: 14px;
        line-height: 32px;
        vertical-align: middle;
        font-family: $font-family-number;
        background: transparent;
    }
    a,
    button {
        &.first,
        &.prev,
        &.next,
        &.last {
            position: relative;
            z-index: 1;
            width: 32px;
            height: 32px;
            border: 1px solid #ccc;
            background: #fff;
            color: #ccc;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: 8px;
                height: 8px;
                border: 1px solid $color-grayscale-05-1;
                border-width: 1px 1px 0 0;
            }
        }

        &.first {
            &:before {
                margin: -4px 0 0 -4px;
                transform: rotate(-135deg);
            }

            &:after {
                margin: -4px 0 0 2px;
                transform: rotate(-135deg);
            }

            & + .prev {
                border-left: none;
            }
        }

        &.prev {
            &:before {
                margin: -4px 0 0 -2px;
                transform: rotate(-135deg);
            }

            &:after {
                display: none;
            }
        }

        &.next {
            &:before {
                margin: -4px 0 0 -4px;
                transform: rotate(45deg);
            }

            &:after {
                display: none;
            }

            & + .last {
                border-left: none;
            }
        }

        &.last {
            &:before {
                margin: -4px 0 0 -8px;
                transform: rotate(45deg);
            }

            &:after {
                margin: -4px 0 0 -2px;
                transform: rotate(45deg);
            }
        }
    }

    strong {
        color: $color-11st-red;
    }
    a.disabled,
    button:disabled {
        color: #ddd;

        &.first,
        &.prev,
        &.next,
        &.last {
            border-color: #ddd;
            color: #ddd;

            &:before,
            &:after {
                border-color: #ddd;
            }
        }
    }
}

.grid-paging {
    @extend %paging;
    a,
    button,
    strong {
        background: #fafafa;
    }
}

.c-paging {
    @extend %paging;
    //이전 다음 버튼이 좌우 끝에 있는 경우
    &.wide {
        position: relative;
        height: 32px;
        strong {
            em {
                font-weight: normal;
                color: $color-gray-04;
            }
        }
        button {
            position: absolute;
            top: 0;
            width: auto;
            line-height: 31px;
            color: $color-gray-02;
            &:before,
            &:after {
                content: "";
                position: relative;
                top: auto;
                left: auto;
                display: inline-block;
                vertical-align: middle;
                width: 8px;
                height: 8px;
                border: 1px solid $color-grayscale-05-1;
                border-width: 1px 1px 0 0;
            }
            &.prev {
                left: 0;
                text-align: left;
                padding: 0 11px 0 12px;
                &:before {
                    margin: -4px 7px 0 0;
                    transform: rotate(-135deg);
                }

                &:after {
                    display: none;
                }
            }
            &.next {
                right: 0;
                text-align: right;
                padding: 0 12px 0 11px;
                &:before {
                    display: none;
                }

                &:after {
                    margin: -4px 0 0 7px;
                    transform: rotate(45deg);
                }
            }
        }
    }

    + .table-box {
        margin-top: 10px;
    }
}

.grid-notice {
    width: 100%;
    height: 130px;
    background: #fff;
    font-size: 16px;
    color: #333;
    letter-spacing: 0;
    text-align: center;
}

.not-found {
    width: 100%;
    height: 200px;
    margin-top: 20px;
    background-color: #fafafa;
    border: 1px solid #eee;
    background-color: $color-gray-13;
    border: 1px solid $color-gray-11;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.7px;
    color: $color-gray-02;
    text-align: center;
}

.grid-expand__area {
    display: block;

    &.grid-expand {
        display: none;
    }
}

.filter-box {
    font-size: 0;
    padding: 20px 0;
    @include clearfix();

    .c-title {
        display: block;
        font-size: 14px;
        color: #111;
        line-height: 40px;
    }

    .c-radio {
        margin-right: 10px;
    }
    .filter-box__row {
        display: flex;
    }
    .filter-box__left {
        float: left;
    }

    .filter-box__right {
        float: right;
    }

    .c-dropdown + div {
        margin-left: 10px;
    }
    & + div,
    & + table {
        margin-top: 0px;
    }

    &--style2 {
        padding: 30px 0 10px;
    }
    &--style3 {
        // b-box__row 위로 20px + padding 10px = 30 유지
        // tbl 위로 10px + 하단 0px =  10px 유지
        padding: 10px 0 0;
    }
}

// 결과
.result-box {
    padding: 30px 0 30px 0;
    border-top: 1px solid #eee;

    &:first-child {
        border-top: none;
        padding-top: 0;
    }

    .c-title {
        .c-title__text {
            font-size: 14px;
            color: $color-grayscale-02;
            line-height: 32px;
        }

        &__count {
            display: inline-block;
            font-size: 12px;
            color: $color-grayscale-04;
            vertical-align: middle;
            margin-left: 5px;

            .value {
                color: $color-11st-red;
                font-family: $font-family-number;
            }
        }

        &--normal {
            .c-title__text {
                font-size: 13px;
                color: $color-grayscale-04;
                line-height: 32px;
                font-weight: normal;
            }

            .c-title__count {
                display: inline-block;
                font-size: 13px;
                color: $color-grayscale-04;
                vertical-align: top;
                margin-left: 5px;
                line-height: 32px;

                .value {
                    color: $color-grayscale-04;
                    font-family: $font-family-number;
                }
            }
        }
    }

    .c-guide {
        &:first-of-type {
            margin-top: 5px;
        }

        & + .grid-box {
            margin-top: 10px;
        }
    }

    .grid-box {
        margin-top: 0;
    }

    .tbl {
        &:first-child {
            margin-top: 0;
        }
    }

    &__header {
        &:not(:first-child()) {
            margin-top: 30px;
        }
        &__left {
            float: left;
        }
        &__right {
            display: flex;
            align-items: center;
            float: right;

            &__item {
                padding-left: 5px;
            }
        }

        & + .grid-box {
            margin-top: 10px;
        }

        @include clearfix();
    }

    &__bottom {
        margin-top: 20px;
        text-align: center;
    }

    &__notice {
        color: $color-grayscale-02;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        height: 200px;
    }

    @include clearfix();
}

.table-box {
    .tbl,
    .tbl_fixed {
        &:not(:first-child) {
            margin-top: 10px;
        }

        &:first-child {
            margin-top: 0;
        }
        .non-date {
            line-height: 90px;
            font-size: 14px;
            color: #111;
            text-align: center;
        }
        td {
            &.non_data {
                height: 80px;
                color: #ff0038;
                font-size: 14px;
                &.non-data__style1 {
                    color: #ff0038;
                }
                &.non-data__style2 {
                    color: #111;
                }
            }
        }
    }

    .in_scroll {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;

        &--horizon {
            overflow-x: auto;
            overflow-y: hidden;
        }

        &--fixed {
            overflow: inherit;
            z-index: 1;
        }
    }

    .table-scroll {
        display: flex;
        margin-bottom: 30px;

        .in_scroll--horizon {
            margin-left: -1px;
        }
    }

    &.table-box__style2 {
        .table-box__header {
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;

            .c-title__text {
                font-size: 14px;
                letter-spacing: -0.44px;
                color: #111;
                font-weight: bold;
            }
            .btn-excel {
                padding-right: 10px;
                margin-bottom: 15px;
            }
        }
    }

    @at-root .c-info__sentence + & {
        margin-top: 20px;
    }
}

.table-box__header {
    position: relative;
    min-height: 40px;
    line-height: 32px;

    strong.c-title__text {
        font-weight: bold;
    }
    .c-title__text {
        font-weight: normal;
    }

    .table-box__left {
        position: absolute;
        bottom: 0;
        left: 0;

        [class^="btn-style"] {
            margin-right: 8px;
        }
    }

    .table-box__right {
        position: absolute;
        bottom: 0;
        right: 0;

        [class^="btn-style"] {
            margin-left: 8px;
        }
    }
}

.notice-box {
    position: relative;
    padding: 26px 19px 21px;
    margin-top: 8px;
    background-color: #fafafa;
    border: 1px solid #eee;
    font-size: 13px;

    .notice-box__title {
        color: #111;
        font-weight: normal;
        letter-spacing: -0.46px;
        .gicon {
            vertical-align: middle;
            margin-top: -2px;
        }
        &:before {
            display: inline-block;
            margin-right: 5px;
            content: "※";
        }
    }
    .notice-box__list {
        display: none;
        margin: 24px 12px 2px;
        color: #666;
        line-height: 24px;
        letter-spacing: -0.46px;

        li {
            position: relative;
            padding-left: 10px;

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                margin-right: 5px;
                content: "-";
            }
        }
    }
    .notice-box__btn {
        position: absolute;
        top: 24px;
        right: 19px;
        color: #666;
        font-size: 13px;
        letter-spacing: 0;

        &:after {
            display: inline-block;
            margin-left: 8px;
            @include ico-angle(down, 7px, #949494, 1px, static);
        }
    }
    &.open {
        .notice-box__list {
            display: block !important;
        }
        .notice-box__btn {
            &:after {
                @include ico-angle(up, 7px, #949494, 1px, static);
            }
        }
    }
}

.summary-box {
    border: 1px solid #eee;
    background: #fafafa;
    padding: 20px;

    .summary-box__list {
        %list {
            display: inline-block;
            position: relative;
            z-index: 1;
            padding-left: 11px;
            color: #111;
            line-height: 20px;
            font-size: 14px;
            text-align: left;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -1px;
                width: 3px;
                height: 3px;
                background: #333;
                content: "";
            }
        }
        &.center {
            text-align: center;
        }
        li,
        dt {
            @extend %list;
        }
        li {
            min-width: 157px;
        }
        dd {
            display: inline-block;
            min-width: 97px;
            padding-left: 10px;
            color: #111;
            line-height: 20px;
            font-size: 14px;
            text-align: left;
        }
    }
}

.b-frame {
    &--style1 {
        &:not(:first-child) {
            margin-top: 40px;
        }
        .c-title__text {
            font-size: 16px;
            color: $color-gray-03;
            font-weight: bold;
        }

        .table-box {
            margin-top: 20px;
        }
    }
    &--slim:not(:first-child) {
        margin-top: 24px;
    }
}

.search-area {
    // 20230906 가격 할인관리 상단 영역에만 사용
    @mixin mq {
        @media (max-width: 1277px) {
            @content;
        }
    }
    --fill-color: #333; // svg 아이콘 컬러

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background-color: $color-gray-14;
    padding: 20px 24px;
    &__board {
        display: flex;
        align-items: center;
    }
    &__board-li {
        position: relative;
        display: flex;
        align-items: center;

        padding-right: 31px; //after 넓이 포함
        margin-right: 30px;

        &:last-child {
            padding-right: 0;
            margin-right: 0;
        }

        @include mq {
            flex-wrap: wrap;
            flex: 1 1 min-content; // min-content 로 최소 사이즈로 줄어들게 함.
            max-width: 180px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 1px;
            height: 20px;
            background-color: $color-gray-11;
            @include mq {
                top: none;
                bottom: 0;
            }
        }
        &:last-child::after {
            display: none;
        }
        .search-area__board--icon {
            margin-right: 2px;
            @include mq {
                display: none;
            }
        }
        &:hover {
            .search-area__board--icon {
                path,
                line,
                circle {
                    --fill-color: #ff0038;
                }
            }
            .search-area__board--text,
            .search-area__board--number {
                text-decoration: underline;
                color: $color-11st-red;
            }
        }
        .gicon {
            margin: 0 15px 0 2px;
            @include mq {
                margin-right: 0;
            }
        }
        .mq_br {
            display: none;
            @include mq {
                content: "\A";
                display: contents;
                white-space: pre;
            }
        }
    }

    &__board--text {
        white-space: nowrap;
    }

    &__board--number {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.42px;
        b {
            padding-right: 4px;
            font-family: Roboto;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.5px;
        }
        @include mq {
            display: inline-flex;
        }
    }
    .c-btn:last-child {
        display: flex;
        align-items: center;
    }
}

.c-fluid {
    // 돔을 담기 위한 단순 블럭
    // c-fluid / c-fluid-item 개별사용 가능
    // 크기, 간격만 지정하여 사용할 것
    display: flex;

    &-item {
        // 독립적으로 사용가능
        // 해당 돔에서 style 로 수정 하여 사용
        flex-shrink: var(--fluidItemShrink, 1);
        flex-grow: var(--fluidItemGrow, 1);
        flex-basis: var(--fluidItemBasis, auto);
        margin: var(--fluidItemMargin, 0 0 0 0);
    }
}
