// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_delivery_deliver_gap: (210px, 610px, -210px, -610px, 140px, 30px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_deliver_gap', );
$sp_delivery_icon_tip: (210px, 650px, -210px, -650px, 41px, 41px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_icon_tip', );
$sp_delivery_tip1: (0px, 610px, 0px, -610px, 200px, 176px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_tip1', );
$sp_delivery_tip2_1: (681px, 0px, -681px, 0px, 324px, 600px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_tip2_1', );
$sp_delivery_tip2_2: (1015px, 0px, -1015px, 0px, 324px, 600px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_tip2_2', );
$sp_delivery_tip2_3: (343px, 0px, -343px, 0px, 328px, 600px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_tip2_3', );
$sp_delivery_tip2_4: (0px, 0px, 0px, 0px, 333px, 600px, 1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', 'sp_delivery_tip2_4', );
$spritesheet_sprites: ($sp_delivery_deliver_gap, $sp_delivery_icon_tip, $sp_delivery_tip1, $sp_delivery_tip2_1, $sp_delivery_tip2_2, $sp_delivery_tip2_3, $sp_delivery_tip2_4, );
$spritesheet: (1339px, 786px, '/img/selleroffice/sprites/sp_delivery.png', $spritesheet_sprites, );


