.c-clean {
    margin-top: 10px;

    &--fail {
        color: $color-11st-red;
    }

    &--pass {
        color: $color-11st-blue;
    }

    &--progress {
        color: $color-11st-red;
    }

    &__check {
        font-size: 14px;
        line-height: 20px;
    }

    &__text {
        font-weight: bold;
        line-height: 20px;
        margin-right: 8px;
        color: inherit;

        .gicon {
            margin: -4px 5px 0 0;
        }
    }

    .tbl {
        margin-top: 10px;
    }

    .c-guide {
        margin-top: 10px;
    }
}
