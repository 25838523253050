// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_main_pop_mov_btn_close: (0px, 260px, 0px, -260px, 34px, 34px, 456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', 'sp_main_pop_mov_btn_close', );
$sp_main_pop_mov_tit_info: (0px, 0px, 0px, 0px, 243px, 161px, 456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', 'sp_main_pop_mov_tit_info', );
$sp_main_pop_mov_tit_info2: (253px, 0px, -253px, 0px, 203px, 118px, 456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', 'sp_main_pop_mov_tit_info2', );
$sp_main_pop_mov_tit_info3: (0px, 171px, 0px, -171px, 219px, 79px, 456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', 'sp_main_pop_mov_tit_info3', );
$sp_main_pop_mov_tit_info4: (229px, 171px, -229px, -171px, 226px, 76px, 456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', 'sp_main_pop_mov_tit_info4', );
$spritesheet_sprites: ($sp_main_pop_mov_btn_close, $sp_main_pop_mov_tit_info, $sp_main_pop_mov_tit_info2, $sp_main_pop_mov_tit_info3, $sp_main_pop_mov_tit_info4, );
$spritesheet: (456px, 294px, '/img/selleroffice/sprites/sp_main_pop_mov.png', $spritesheet_sprites, );


