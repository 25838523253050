.main-report {
    width: 100%;
    height: 0;
    background-color: #fffbe9;
    text-align: center;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    overflow: hidden;

    &.open {
        min-height: 60px;
        height: auto;
        border-bottom: 1px solid #eee;
        overflow: visible;
    }

    .count {
        display: inline-block;
        margin-left: 3px;
        color: #ccc;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: -1.1px;
        line-height: 32px;
        vertical-align: middle;

        &.active {
            color: $color-11st-red;
        }
    }

    .allim-list {
        width: calc(100% - 80px);
        min-width: 868px;
        display: inline-block;

        > div {
            position: relative;
            padding: 14px 0;
            display: inline-block;
            text-align: left;

            > dt {
                display: inline-block;
                font-weight: bold;
                font-size: 18px;
                color: #333;
                letter-spacing: -1.7px;
                line-height: 32px;
                vertical-align: middle;

                &:before {
                    display: inline-block;
                    margin: -2px 4px 0 30px;
                    vertical-align: middle;
                    content: "";
                }
            }

            dd {
                display: inline-block;
                vertical-align: middle;
                text-align: left;
            }
        }

        ul {
            display: inline-block;

            li {
                display: inline-block;
                margin-left: 10px;
                color: #666;
                font-size: 14px;
                letter-spacing: -1.1px;
                line-height: 32px;
                vertical-align: middle;
            }

            .count {
                margin-top: -4px;
                font-size: 14px;
            }
        }

        .allim-list__btn {
            position: relative;
            width: 24px;
            height: 24px;
            margin: 0 0 -2px 5px;
            border-radius: 20px;
            border: solid 1px #ddd;
            background-color: #fff;
            line-height: 200px;
            overflow: hidden;

            &:after {
                @include ico-angle(right, 8px, #666, 1px, absolute);
                margin: -1px 6px 0 0;
            }
        }

        .allim-list__item-1 {
            min-width: 190px;

            dt:before {
                @include sprite-retina($sp_icon_2x_allim_icon1);
                margin-left: 0;
            }
        }
        .allim-list__item-2 {
            dt:before {
                @include sprite-retina($sp_icon_2x_allim_icon2);
            }
        }
        .allim-list__item-3 {
            dt:before {
                @include sprite-retina($sp_icon_2x_allim_icon3);
            }
        }
        .allim-list__item-4 {
            dt:before {
                @include sprite-retina($sp_icon_2x_allim_icon4);
            }
        }
        .allim-list__item-5 {
            dt:before {
                @include sprite-retina($sp_icon_2x_allim_icon5);
            }
        }

        .allim-list__item-6 {
            min-width: 190px;
            padding-right: 30px;
            dt:before {
                display: none;
            }
            em {
                font-size: 18px;
            }
            .main-tooltip__btn {
                margin-left: 1px;
            }
        }

        .c-tip-emphasis-line {
            width: 180px;
            left: 18px;
            top: 48px;
            text-align: center;
            z-index: 2;

            a {
                color: $color-11st-red;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .main-tooltip {
        width: 380px;
    }
}

.main-allim {
    width: 100%;
    height: 0;
    background-color: #fffbe9;
    overflow: hidden;
    font-size: 0;
    text-align: center;
    transition: all 0.7s ease;
    transition-delay: 0.5s;

    &.open {
        padding: 16px 0;
        min-height: 60px;
        height: auto;
        border-bottom: 1px solid #eee;
    }

    .allim-list {
        display: inline-block;

        .count {
            display: inline-block;
            margin-top: -4px;
            color: #ccc;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: -1.1px;
            line-height: 32px;
            vertical-align: middle;

            &.active {
                color: $color-11st-red;
            }
        }

        .allim-list__title {
            display: inline-block;
            margin-right: 10px;
            font-weight: bold;
            font-size: 18px;
            color: #333;
            letter-spacing: -1.7px;
            line-height: 32px;
            vertical-align: middle;

            .count {
                margin: -4px 0 0 4px;
                font-size: 22px;
                vertical-align: middle;
            }
            &:before {
                display: inline-block;
                margin: -2px 4px 0 0;
                vertical-align: middle;
                content: "";
            }
            &:nth-child(1):before {
                @include sprite-retina($sp_icon_2x_allim_icon1);
            }
            &:nth-child(3):before {
                margin-left: 81px;
                @include sprite-retina($sp_icon_2x_allim_icon2);
            }
            &:nth-child(5):before {
                margin-left: 81px;
                @include sprite-retina($sp_icon_2x_allim_icon3);
            }
            &:nth-child(7):before {
                margin-left: 81px;
                @include sprite-retina($sp_icon_2x_allim_icon4);
            }
        }
        .allim-list__cont {
            display: inline-block;
            vertical-align: middle;

            // start MPSR-53359 적용 후 삭제
            .btn-style__1 {
                width: 110px;
                margin-left: 24px;
            }
            // end MPSR-53359 적용 후 삭제

            .allim-list__btn {
                position: relative;
                width: 24px;
                height: 24px;
                border-radius: 20px;
                border: solid 1px #ddd;
                background-color: #fff;
                line-height: 200px;
                margin-bottom: -2px;

                &:after {
                    @include ico-angle(right, 8px, #666, 1px, absolute);
                    margin: -1px 6px 0 0;
                }
            }
        }
        .allim-list__item {
            display: inline-block;
            color: #666;
            font-size: 14px;
            letter-spacing: -1.1px;
            line-height: 32px;
            vertical-align: middle;
            margin-right: 10px;

            &:not(:first-child) {
                margin-left: 0px;
            }
        }
    }
}

@media (max-width: 1430px) {
    .main-allim .allim-list .allim-list__title {
        &:nth-child(3):before,
        &:nth-child(5):before,
        &:nth-child(7):before {
            margin-left: 37px;
        }
    }

    .main-report {
        &.open {
            height: auto;
            // min-height: 73px;
        }

        .allim-list {
            text-align: left;
            > div {
                padding: 14px 0 27px;
            }
            div:first-child {
                padding-left: 30px;
            }
        }

        .allim-list__item-1 {
            ul {
                position: absolute;
                min-width: 500px;
                left: -8px;
                bottom: 2px;
            }
        }
    }
}
