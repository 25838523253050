@charset "UTF-8";

.c-list-storage {
    &__container {
        margin-top: 30px;
    }

    &__item {
        display: flex;
        padding: 20px 0;
        border-top: 1px solid $color-gray-11;

        &:first-child {
            padding-top: 30px;
        }
    }

    .c-product-card {
        display: flex;
        width: 100%;

        &__info {
            flex-grow: 1;
            padding: 0 15px;
        }

        &__name {
            max-height: 40px;
            overflow: hidden;
        }

        &__button {
            width: 87px;
        }
    }
}
//본문 추천 카탈로그
.c-list-catalogue {
    &__container {
        margin-top: 10px;
    }

    &__item {
        padding: 20px 0;

        &:not(:first-of-type) {
            border-top: 1px solid $color-gray-11;
        }
    }

    &__notice {
        position: relative;
        padding: 20px 0;
        min-height: 200px;
        font-size: 13px;
        line-height: 19px;
        color: $color-gray-04;
        text-align: center;

        strong {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            color: $color-gray-03;
        }

        .vc {
            position: absolute;
            width: 100%;
        }
    }

    &__more {
        padding: 20px 0 0 0;
        border-top: 1px solid $color-gray-11;
        text-align: center;
    }

    .c-product-card {
        display: flex;
        width: 100%;

        &__info {
            flex-grow: 1;
            padding: 0 15px;
        }

        &__name {
            @include text-ellipsis-multiple(2);
            max-height: 40px;
            overflow: hidden;
        }

        &__button {
            min-width: 132px;

            .vc {
                text-align: right;
            }

            .btn-size__4 {
                min-width: 122px;
                max-width: 122px;
                padding: 0;
            }
        }
    }
}

//카탈로그 상품매칭 레이어팝업 - 검색결과
.c-list-catalogue-matching {
    &__container {
        margin-top: 10px;
    }

    &__item {
        padding: 20px 0;

        &:not(:first-of-type) {
            border-top: 1px solid $color-gray-11;
        }

        &:first-child {
            padding-top: 30px;
        }
    }

    &__notice {
        position: relative;
        padding: 20px 0;
        min-height: 200px;
        font-size: 13px;
        line-height: 19px;
        color: $color-gray-04;
        text-align: center;

        strong {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            color: $color-gray-03;
        }

        .vc {
            position: absolute;
            width: 100%;
        }
    }

    &__more {
        padding: 20px 0 0 0;
        border-top: 1px solid $color-gray-11;
        text-align: center;
    }

    .c-product-card {
        display: flex;
        width: 100%;

        &__info {
            flex-grow: 1;
            padding: 0 15px;
        }

        &__name {
            @include text-ellipsis-multiple(2);
            max-height: 40px;
            overflow: hidden;
        }

        &__button {
            min-width: 132px;

            .vc {
                text-align: right;
            }

            .btn-size__4 {
                min-width: 122px;
                max-width: 122px;
                padding: 0;
            }
        }
    }
}

//카탈로그 상품매칭 레이어팝업 - 옵션 선택
.c-list-catalogue-all {
    &__container {
        margin-top: 0;
        border: 1px solid $color-gray-10;

        li {
            padding: 21px 20px 21px 12px;

            &:not([style*="display: none"]) + li {
                border-top: 1px solid $color-gray-11;
            }
        }
    }

    &__item {
        padding: 24px 0;

        &:not([style*="display: none"]):first-child {
            padding-top: 30px;
        }
    }

    &__notice {
        position: relative;
        padding: 20px 0;
        min-height: 200px;
        font-size: 13px;
        line-height: 19px;
        color: $color-gray-04;
        text-align: center;

        strong {
            display: block;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 21px;
            color: $color-gray-03;
        }

        .vc {
            position: absolute;
            width: 100%;
        }
    }

    &__more {
        padding: 20px 0 0 0;
        border-top: 1px solid $color-gray-11;
        text-align: center;
    }

    .c-product-card {
        display: flex;
        width: 100%;

        &__info {
            flex-grow: 1;
            padding: 0 0px;
        }

        &__name {
            @include text-ellipsis-multiple(2);
            max-height: 40px;
            overflow: hidden;
        }

        &__button {
            min-width: 132px;

            .vc {
                text-align: right;
            }
        }

        &__attr {
            margin-top: 0;

            dd {
                display: block;
                margin-top: 4px;
                font-size: 14px;
                line-height: 21px;
                color: $color-gray-03;

                &.price {
                    &:after {
                        display: none;
                    }
                }

                .link {
                    margin-left: 0;

                    .total {
                        margin-left: 8px;
                    }
                }

                span.link {
                    color: $color-gray-08;

                    &::after {
                        border-color: $color-gray-08;
                    }
                }
            }
        }
    }

    .c-check--style2 {
        width: 157px;
        height: 80px;
        padding-right: 20px;
        font-size: 0;
        line-height: 0;

        .lab {
            margin-left: 13px;

            &:before {
                top: 50%;
                margin-top: -20px;
            }
        }

        .inp:focus + .lab:after {
            top: 29px;
        }
    }

    &__none {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;

        @at-root li#{&} {
            padding: 0;
        }

        &--text {
            margin-bottom: 5px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: -0.7px;
            color: $color-gray-03;
        }
        .link {
            font-weight: 400;
            letter-spacing: -0.52px;
        }
    }
}
