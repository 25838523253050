@charset "UTF-8";

@import "mixins/sprite";

@mixin clearfix {
    zoom: 1;

    &:after {
        display: block;
        content: "";
        clear: both;
    }
}

@mixin skip {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

@mixin sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

@mixin sr-only-reset {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    white-space: normal;
}

@mixin text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin text-ellipsis-multiple($line) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    white-space: initial;
}
// 안이 채워진 삼각형
@mixin ico-triangle($direction: "down", $width: 8px, $height: 5px, $color: #000, $position: "absolute") {
    width: 0;
    height: 0;
    content: "";

    @if ($position == "absolute") {
        -webkit-transform: translateZ(0) translateY(-50%);
        -o-transform: translateZ(0) translateY(-50%);
        transform: translateZ(0) translateY(-50%);
    }

    @if ($direction == "up") {
        border-bottom: $height solid $color;
        border-left: round($width/2) solid transparent;
        border-right: round($width/2) solid transparent;
    } @else if ($direction == "down") {
        border-top: $height solid $color;
        border-left: round($width/2) solid transparent;
        border-right: round($width/2) solid transparent;
    } @else if ($direction == "left") {
        border-top: round($height/2) solid transparent;
        border-bottom: round($height/2) solid transparent;
        border-right: $width solid $color;
    } @else if ($direction == "right") {
        border-top: round($height/2) solid transparent;
        border-bottom: round($height/2) solid transparent;
        border-left: $width solid $color;
    }
}

// > , < 모양
@mixin ico-angle($direction: "right", $size: 50px, $color: #000, $thick: 1px, $position: "absolute") {
    width: $size;
    height: $size;
    border: 1px solid $color;
    border-width: $thick $thick 0 0;
    transform-origin: center center;
    content: "";

    @if ($position == "absolute") {
        position: absolute;
        top: 50%;

        @if ($direction == "right") {
            right: 0;
            transform: translateZ(0) translate(-25%, -40%) rotate(45deg);
        } @else if ($direction == "left") {
            left: 0;
            transform: translateZ(0) translate(25%, -40%) rotate(-135deg);
        } @else if ($direction == "up") {
            right: 0;
            transform: translateZ(0) translate(-25%, -40%) rotate(-45deg);
        } @else if ($direction == "down") {
            right: 0;
            transform: translateZ(0) translate(-25%, -40%) rotate(-225deg);
        }
    } @else if ($position == "static") {
        vertical-align: middle;

        @if ($direction == "right") {
            transform: translateZ(0) rotate(45deg);
        } @else if ($direction == "left") {
            transform: translateZ(0) rotate(-135deg);
        } @else if ($direction == "up") {
            transform: translateZ(0) rotate(-45deg);
        } @else if ($direction == "down") {
            transform: translateZ(0) translateY(-50%) rotate(135deg);
        }
    }
}

// x 모양
@mixin ico-cancel($size: 40px, $thick: 2px, $color: #999, $padding: 0) {
    $sqrt_2: 1.41421356237;
    width: $size + $padding * 2;
    height: $size + $padding * 2;
    overflow: hidden;
    line-height: 200px;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size * $sqrt_2 - $thick);
        height: $thick;
        background-color: $color;
        content: "";
    }
    &:before {
        -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
        transform: translateZ(0) translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
        transform: translateZ(0) translate(-50%, -50%) rotate(135deg);
    }
}

// + 모양
@mixin ico-add($size: 40px, $thick: 2px, $color: #999, $padding: 0) {
    $sqrt_2: 1.41421356237;
    position: relative;
    width: $size + $padding * 2;
    height: $size + $padding * 2;
    overflow: hidden;
    line-height: 200px;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size * $sqrt_2 - $thick);
        height: round($thick/2);
        background-color: $color;
        margin-left: round($size/2 * -1);
        content: "";
    }
    &:before {
        -webkit-transform: translateZ(0) rotate(90deg);
        transform: translateZ(0) rotate(90deg);
    }
    &:after {
        -webkit-transform: translateZ(0) rotate(0deg);
        transform: translateZ(0) rotate(0deg);
    }
}

@mixin img-center {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
