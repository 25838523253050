.c-guide {
    color: #666;
    font-size: 13px;
    line-height: 19px;
    vertical-align: middle;
    margin: var(--guide-margin-top, 4px) var(--guide-margin-right, 0) var(--guide-margin-bottom, 0) var(--guide-margin-left, 0);

    strong {
        font-weight: bold;
    }

    .link {
        vertical-align: baseline;
    }

    &:first-of-type[style*="display: none"] + &,
    &:first-of-type {
        // margin-top: 10px;
        --guide-margin-top: 10px;
    }

    &:first-child {
        --guide-margin-top: 0;
        // margin-top: 0;
    }
    &.mt-0 {
        --guide-margin-top: 0;
    }
    &.mt-4 {
        --guide-margin-top: 4px;
    }
    &.mt-10 {
        --guide-margin-top: 10px;
    }
    &.mt-20 {
        --guide-margin-top: 20px;
    }
    &.ml-10 {
        --guide-margin-left: 10px;
    }

    &--info {
        &::before {
            @include sprite-retina($sp_icon_2x_icon-info);
            display: inline-block;
            margin-right: 7px;
            margin-top: -2px;
            vertical-align: middle;
            content: "";
        }
    }

    &--flex-between {
        display: flex;
        margin-top: 0;
        justify-content: space-between;
        align-items: center;
    }

    .gicon-question {
        margin-left: 5px;
        margin-top: -1px;
    }

    + .sub-text {
        margin-top: 2px;
    }

    + .c-form {
        &:not(:first-child) {
            margin-top: 20px;
        }
        .c-form__row:first-of-type .c-input:first-of-type {
            // 가격 -> 옵션명 으로 추가, 이슈 발생시 선택자 변경 가능
            margin-top: 0;
        }
    }

    &.center {
        text-align: center;
    }

    &.bullet {
        position: relative;
        z-index: 1;
        padding-left: 7px;
        &::before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            display: inline-block;
            content: "*";
        }
    }

    &--emphasis,
    &.c-guide__style2,
    &.c-guide--style2,
    .c-guide--emphasis {
        color: $color-11st-red;
        --guide-margin-top: 4px;
    }
    @at-root .waring + .c-guide--emphasis {
        // --guide-margin-top: -4px;
        --guide-margin-top: 0px;
        font-size: 12px;
    }
    @at-root .c-form--waring .c-guide--emphasis {
        // --guide-margin-top: -4px;
        --guide-margin-top: 0px;
    }
    &.c-guide__style3,
    &.c-guide--style3 {
        padding: 20px 0;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        line-height: -0.44px;
    }
    &.c-guide--style4 {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.52px;
        color: $color-gray-03;
    }
    &.c-guide--style5 {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.52px;
        color: $color-gray-03;
    }
    &.c-guide--style6 {
        //c-form__row 사이에 사용. 하단에 치우친 버전
        margin: 17px 0 -13px 0;
    }
    &.c-guide--style7 {
        //엑셀 일괄등록 팝업 로딩 로딩 텍스트
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.6px;
    }

    &--first-link {
        .link {
            &:first-child {
                margin-left: 0;
            }
        }
    }

    & + .table-box {
        margin-top: 20px;
    }

    & + .tab-box--style4 {
        margin-top: 20px;
    }
    &__box {
        margin-top: 15px;
        padding: 10px 15px;
        background: rgba(243, 249, 254, 0.8);
        b,
        strong {
            font-weight: 500;
        }
    }

    & + .b-box__header {
        margin-top: 10px;
    }

    & + .tbl--style3 {
        margin-top: 10px;
    }
}

.c-guide-list {
    margin-top: 4px;
    color: #666;
    font-size: 13px;
    line-height: 19px;
    vertical-align: middle;

    &:first-child {
        margin-top: 0;
    }

    &--bullet {
        li {
            position: relative;
            z-index: 1;
            padding-left: 18px;

            &::before {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 7px;
                display: block;
                width: 3px;
                height: 3px;
                background-color: $color-gray-04;
                border-radius: 50%;
                transform: translateY(-50%);
                content: "";
            }
            &.emphasis {
                color: $color-11st-red;
                &::before {
                    background-color: $color-11st-red;
                }
            }
            &.bullet-top::before {
                top: 10px;
            }
        }
        &__style2 {
            li {
                position: relative;
                z-index: 1;
                padding-left: 8px;
                margin-top: 3px;

                &:first-child {
                    margin-top: 0;
                }

                &::before {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 0;
                    display: block;
                    width: 3px;
                    height: 3px;
                    background-color: $color-gray-04;
                    border-radius: 50%;
                    transform: translateY(-50%);
                    content: "";
                }
                &.emphasis {
                    color: $color-11st-red;
                    &::before {
                        background-color: $color-11st-red;
                    }
                }
            }
        }
    }
}

.c-guide-special {
    strong {
        font-weight: bold;
    }

    &__sentance {
        color: #111;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
    }

    &__list {
        margin-top: 8px;
        color: #111;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;

        li {
            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        &--bullet {
            li {
                position: relative;
                z-index: 1;
                padding-left: 7px;
                color: $color-grayscale-03;
                font-size: 14px;
                line-height: 20px;

                &::before {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    content: "·";
                }
            }
        }
    }

    &--emphasis {
        color: $color-11st-red;
    }
}

.c-guide-group {
    + .b-box__header {
        margin-top: 10px;
    }
}
