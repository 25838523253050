.c-tip,
.c-tip-point,
.c-tip-line,
.c-tip-square,
.c-tip-emphasis-line {
    position: absolute;
    min-height: 19px;
    padding: 1px 10px;
    border-radius: 10px;
    font-size: 11px;
    color: $color-grayscale-13;
    box-sizing: border-box;
    line-height: 17px;
    letter-spacing: -1px;

    &:before {
        position: absolute;
        top: -7px;
        left: 12px;
        border: solid transparent;
        border-width: 0 6px 7px;
        content: "";
    }

    &--top-center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }
    }

    &--top-right {
        &:before {
            right: 12px;
            left: auto;
        }
    }

    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
        &:before {
            top: auto;
            bottom: -7px;
            border-width: 7px 6px 0;
        }
    }

    &--bottom-center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }
    }

    &--bottom-right {
        &:before {
            left: auto;
            right: 12px;
        }
    }
}

.c-tip {
    background: rgba($color: #000, $alpha: 0.8);

    &:before {
        border-bottom-color: rgba($color: #000, $alpha: 0.8);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
        &:before {
            border-top-color: rgba($color: #000, $alpha: 0.8);
        }
    }
}

.c-tip-point {
    background: rgba($color: $color-11st-blue, $alpha: 0.8);

    &:before {
        border-bottom-color: rgba($color: $color-11st-blue, $alpha: 0.8);
    }
    &.c-tip--bottom-left,
    &.c-tip--bottom-center,
    &.c-tip--bottom-right {
        &:before {
            border-top-color: rgba($color: $color-11st-blue, $alpha: 0.8);
        }
    }
}

.c-tip-line {
    background: #fff;
    color: $color-grayscale-03;
    border: 1px solid #ddd;

    &::before {
        position: absolute;
        top: -5px;
        width: 8px;
        height: 8px;
        background-color: rgb(255, 255, 255);
        border-style: solid;
        border-color: #ddd;
        border-image: initial;
        border-width: 1px 0px 0px 1px;
        vertical-align: middle;
        transform: rotate(45deg);
        content: "";
    }
}

.c-tip-emphasis-line {
    background: #fef8f9;
    color: $color-11st-red;
    border: solid 1px rgba(244, 49, 66, 0.3);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

    &::before {
        display: none;
        position: absolute;
        top: -5px;
        width: 8px;
        height: 8px;
        background-color: #fef8f9;
        border-style: solid;
        border-color: rgba(244, 49, 66, 0.3);
        border-image: initial;
        border-width: 1px 0px 0px 1px;
        vertical-align: middle;
        transform: rotate(45deg);
        content: "";
    }

    &:after {
        position: absolute;
        left: 25px;
        top: -5px;
        @include sprite-retina($sp_icon_2x_icon_arrow_up);
        content: "";
    }
}

.c-tip-square {
    background: #fff;
    color: $color-grayscale-03;
    border-radius: 0;
    border: 1px solid rgb(139, 139, 139);

    &::before {
        position: absolute;
        top: -4px;
        width: 6px;
        height: 6px;
        background-color: rgb(255, 255, 255);
        border-style: solid;
        border-color: rgb(139, 139, 139);
        border-image: initial;
        border-width: 1px 0px 0px 1px;
        vertical-align: middle;
        transform: rotate(45deg);
        content: "";
    }
}
