.c-addimg-box {
    position: relative;
    z-index: 1;
    font-size: 0;

    &.drag {
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            content: "";
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.4);
        }

        .drag-item {
            z-index: 2;
        }
    }
}
// agGrid에 참조하여 사용 중.
.c-addimg {
    position: relative;
    z-index: 1;
    font-size: 0;

    //리테일 동종그룹
    &--option {
        padding: 10px 0;

        .c-thumb {
            display: inline-block;
            margin-right: 10px;
            vertical-align: middle;
        }

        .c-addimg__btn {
            display: inline-block;

            .c-btn {
                margin-right: 5px;
            }

            &-add {
                vertical-align: middle;
                position: relative;
                z-index: 1;
                display: inline-block;
                width: 80px;
                height: 80px;
                border: 1px dashed #ddd;
                background: #fff;
                text-align: center;

                &:before {
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    margin-top: -16px;
                    margin-left: -16px;
                    content: "";
                    display: inline-block;
                    @include sprite-retina($sp_icon_2x_ic_add);
                }
            }
        }
    }

    &--prd {
        display: flex;

        .c-addimg__thumb-img,
        .c-addimg__btn {
            width: 160px;
            height: 160px;
        }

        @at-root .c-guide + & {
            margin-top: 10px;
        }
    }

    &--card {
        display: flex;

        .c-addimg__thumb-img,
        .c-addimg__btn {
            width: 320px;
            height: 160px;
        }
    }

    &--vod {
        display: flex;

        .c-addimg__thumb-img,
        .c-addimg__btn {
            width: 320px;
            height: 160px;
        }
    }

    &--option-value {
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
        width: calc(100% + 10px);
        margin-top: -20px;
        margin-left: -5px;

        .c-addimg__thumb {
            width: 128px;
            margin: 20px 5px 0;
        }
        .c-addimg__thumb-img {
            width: 128px;
            height: 128px;
        }
        .c-addimg__btn {
            width: 128px;
            margin: 20px 5px 0;
            .c-addimg__btn-add {
                width: 128px;
                height: 128px;
            }
        }

        .c-addimg__option {
            margin-bottom: 0;
        }
    }

    &.drag {
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            content: "";
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.4);
        }

        .drag-item {
            z-index: 2;
        }
    }

    &__thumb {
        overflow: hidden;
        position: relative;
        z-index: 1;
        font-size: 0;
        margin-right: 10px;
        width: inherit;
        height: inherit;

        &-img {
            position: relative;
            z-index: 1;
            width: inherit;
            height: inherit;
            border: 1px solid #ddd;
            // border-bottom: none;
            overflow: hidden;
            box-sizing: border-box;
            text-align: center;
            background-color: #fff;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
            }
        }

        &-btn {
            z-index: 1;
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            justify-content: space-around;
            width: 100%;
            height: 32px;
            padding: 4px;
            background-color: rgba(00, 00, 00, 0.7);
            transition: transform 0.15s ease-out;
            transform: translateY(100%);

            button[type="button"] {
                width: 22px;
                height: 22px;
                overflow: hidden;
                line-height: 200px;
            }

            &__edit {
                @include sprite-retina($sp_icon_2x_ic_22_edit);
            }

            &__zoom {
                @include sprite-retina($sp_icon_2x_ic_22_zoom_white);
            }

            &__del {
                @include sprite-retina($sp_icon_2x_ic_22_del);
            }
        }
        &:hover .c-addimg__thumb-btn {
            transform: translateY(0);
        }
    }

    &__btn {
        margin-right: 10px;
    }

    &__btn-add {
        vertical-align: middle;
        position: relative;
        z-index: 1;
        display: inline-block;
        width: inherit;
        height: inherit;
        border: 1px dashed #ddd;
        background: #fff;
        text-align: center;

        &:before {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            //margin-left: -16px;
            content: "";
            display: inline-block;
            @include sprite($sp_icon_add_img);
        }

        &__title {
            width: 100%;
            position: absolute;
            left: 0;
            top: 20px;
            font-size: 13px;
            line-height: 18px;
            color: #666;
        }

        &__info {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 16px;
            font-size: 13px;
            line-height: 19px;
            color: $color-grayscale-05_1;
            text-align: center;
        }

        &__inp {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &__option {
        display: block;
        margin: 10px 0;
        font-size: 14px;
        line-height: 21px;
        color: $color-gray-03;
    }
}
