@charset "UTF-8";

.skip {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

.sr-only {
    position: absolute;
    top: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    margin: -1px;
    font-size: initial;
    line-height: 0;
    color: transparent;
    overflow: hidden;
}

.vc {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.bold {
    font-weight: bold;
}

.number {
    font-family: $font-family-number;
}

.color-brand,
.color_brand {
    color: $color-11st-red !important;
}

.color-secondary,
.color_secondary {
    color: $color-secondary !important;
}

.scroll-lock,
.scroll_lock {
    width: 100%;
    height: 100%;
    overflow: hidden;

    body {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
