.c-option {
    &__row {
        display: flex;

        &:not(:first-of-type) {
            margin-top: 10px;

            .c-select {
                padding-top: 0;
                .title {
                    @include sr-only();
                }
            }

            .c-option__title {
                @include sr-only();
            }

            .c-option__etc {
                padding-top: 0;
            }

            .c-input {
                padding-top: 0;
                .title {
                    @include sr-only();
                }
            }
        }
    }

    &__name {
        max-width: 170px;
        padding: 0 10px 0 0;

        .title {
            display: inline-block;
            width: 110px;
            min-width: 110px;
            font-size: 14px;
            font-weight: normal;
            color: $color-gray-01;
            line-height: 18px;
        }
    }

    &__cont {
        position: relative;
        display: table-cell;
        width: 100%;
        + .c-option__name {
            padding: 0 0 0 10px;
        }
    }

    &__title {
        padding: 0 20px 0 0;

        .lab {
            display: inline-block;
            width: 110px;
            min-width: 110px;
            font-size: 14px;
            font-weight: normal;
            color: #000;
            line-height: 40px;
        }
    }

    &__etc {
        width: 131px;
        padding: 40px 0 0 10px;
    }

    &__notice {
        padding-left: 172px;
        margin-top: 10px;
        &--left {
            padding-left: 0;
        }
    }

    &__button {
        padding: 20px 0 0 172px;
    }

    .c-chip-choice {
        width: 100%;
        padding: 6px 7px 1px 12px;
        border: 1px solid $color-gray-10;
        background-color: $color-gray-14;
        box-sizing: border-box;
    }
}

.c-option-value {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 7px 0 12px;
    border: 1px solid $color-gray-10;
    background-color: $color-gray-14;
    box-sizing: border-box;

    &__input {
        flex-grow: 1;
        min-width: 320px;
        .inp {
            width: 100%;
            height: 38px;
            background: $color-gray-14;
            color: $color-gray-02;
            font-size: 14px;
            border: none;
        }
    }
    &--disabled {
        background-color: $color-gray-12;
        border-color: $color-gray-10;
        color: $color-gray-08;
        .inp {
            background-color: $color-gray-12;
            border-color: $color-gray-10;
            color: $color-grayscale-06;
            &::-webkit-input-placeholder {
                color: $color-grayscale-06;
                font-weight: normal;
            }
        }
    }
}

.c-option-choice {
    display: none;
    position: relative;
    background-color: $color-gray-14;

    &.active {
        display: block;
    }

    &__inner {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        background-color: $color-gray-14;
        z-index: 10;
    }

    &__container {
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        height: 240px;
        border: 1px solid $color-gray-08;
        @include clearfix;
        align-items: flex-start;
        align-content: flex-start;

        li {
            width: 25%;
            padding: 10px;

            .inp:disabled + .lab {
                color: $color-gray-08;
            }

            .inp:focus + .lab:after {
                top: 1px;
            }
        }
    }

    &__notice {
        position: relative;
        height: 240px;
        font-size: 13px;
        line-height: 19px;
        border: 1px solid $color-gray-08;
        color: $color-gray-04;
        text-align: center;

        strong {
            display: block;
            margin-bottom: 10px;
            color: $color-gray-03;
        }

        .vc {
            position: absolute;
            width: 100%;
        }
    }
}
