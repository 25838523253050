@charset "UTF-8";

.c-input {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;

    &__subtext {
        display: inline-block;
        color: $color-gray-04;
        font-size: 14px;
        line-height: 40px;
        vertical-align: middle;
        margin-left: 15px;

        &--number {
            letter-spacing: 0;
        }

        &--fee {
            letter-spacing: 0;

            em {
                color: $color-11st-red;
            }

            &::before {
                display: inline-block;
                width: 1px;
                height: 12px;
                margin: -2px 10px 0 0;
                background-color: $color-gray-10;
                vertical-align: middle;
                content: "";
            }
        }
        .value {
            letter-spacing: 0;
            color: $color-gray-02;
        }
    }

    .lab {
        line-height: 40px;
        font-size: 14px;
        margin-right: 10px;
        color: $color-gray-02;
        vertical-align: top; // waring 문구노출 시 필요
    }

    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;
        font-weight: normal;
    }

    .input-group {
        display: inline-block;
    }

    .input-text {
        display: inline-block;
        position: relative;
        z-index: 2; // dropdown type list box z-index:1

        &.auto {
            .inp {
                background: #fafafa;
            }

            .input-etc {
                background: #fafafa;
            }
        }

        & + button {
            margin-left: 10px;
        }

        &--search {
            .inp {
                padding-right: 55px;
            }
        }

        &--check {
            .inp {
                padding-right: 85px;
            }
        }
    }

    .inp {
        width: 160px;
        height: 40px;
        padding: 0 43px 0 12px;
        background: #fff;
        color: #111;
        font-size: 14px;
        border: 1px solid #ddd;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &::-webkit-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        }
        &::-moz-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* firefox 19+ */
        &::-ms-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* ie */
        &:focus {
            border-color: #bbb;
        }
        &:required:valid {
            border-color: #ddd;
            color: #111;
        }
        &:disabled {
            background-color: #f4f4f4;
            border-color: #ddd;
            color: $color-grayscale-06;
            &::-webkit-input-placeholder {
                color: $color-grayscale-06;
                font-weight: normal;
            }
            &::-moz-placeholder {
                color: $color-grayscale-06;
                font-weight: normal;
            } /* firefox 19+ */
            &::-ms-input-placeholder {
                color: $color-grayscale-06;
                font-weight: normal;
            } /* ie */
        }
        &:disabled + .input-etc {
            background-color: #f4f4f4;
            .btn-calender {
                @include sprite($sp_icon_calendar-dis);
            }
            .btn-search {
                @include sprite($sp_icon_search-dis);
            }
            .character-check {
                em {
                    color: $color-gray-08;
                }
                color: $color-gray-08;
            }
        }
    }

    .input-etc {
        height: 36px;
        position: absolute;
        top: 2px;
        right: 2px;
        text-align: center;
        background: #fff;
        overflow: hidden;

        .character-check {
            display: inline-block;
            height: 38px;
            margin: 0 9px 0 0;
            font-size: 13px;
            color: $color-grayscale-05-1;
            line-height: 38px;
            text-align: right;
            vertical-align: middle;

            @at-root .c-ag-layer & {
                // agGrid 에서 입력형 > 장문형 일때 텍스트 입력 후 삭제 버튼 정렬 틀어짐 대응
                vertical-align: middle;
            }

            em {
                color: #111;
            }
        }

        .btn-calender {
            display: inline-block;
            margin: 8px 9px;
            overflow: hidden;
            line-height: 200px;
            @include sprite($sp_icon_calendar);

            &:before {
                position: absolute;
                top: -1px;
                right: 40px;
                display: block;
                width: 1px;
                height: 40px;
                background: #ddd;
                content: "";
            }
        }

        .btn-search {
            display: inline-block;
            margin: 8px 11px;
            overflow: hidden;
            line-height: 200px;
            @include sprite($sp_icon_search);
        }

        .btn-clear {
            position: relative;
            display: inline-block;
            margin: 9px 12px;
            border-radius: 9px;
            background-color: #bbb;
            @include ico-cancel(8px, 1px, #fff, 5px);
            //@include sprite($sp_icon_clear);
        }

        .btn-clear:not(:last-of-type) {
            margin-left: 0px;
            margin-right: 0px;
        }

        .btn-clear + .character-check {
            margin-left: 0px;
        }

        .valid-time {
            display: inline-block;
            height: 38px;
            margin: 0 9px;
            font-size: 13px;
            color: $color-11st-red;
            line-height: 38px;
            text-align: right;
            vertical-align: middle;
        }
    }

    .input-file {
        display: inline-block;
        position: relative;
        z-index: 1;
        min-width: 130px;
        height: 40px;
        padding-left: 10px;
        vertical-align: middle;

        [class^="btn-style"] {
            position: absolute;
            top: 0;
            left: 10px;
            z-index: 1;
        }
        .inp {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 10px;
            width: 120px;
            height: 40px;
            opacity: 0;
            cursor: pointer;
        }
        .inp:hover + .btn-style__1 {
            background-color: #eee;
        }

        & + button {
            margin-left: 10px;
        }

        &--style2 {
            display: inline-block;
            position: relative;
            z-index: 1;
            min-width: 80px;
            height: 40px;
            padding-left: 0;
            vertical-align: middle;

            [class^="btn-style"] {
                position: absolute;
                top: 0;
                left: -3px;
                z-index: 1;
            }
            .inp {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 80px;
                height: 40px;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .unit {
        padding-left: 3px;
        line-height: 40px;
        color: #111;
        font-size: 14px;
        vertical-align: middle;
    }
    .input-message {
        display: none;
    }
    &.c-input.time {
        .inp {
            width: 80px;
        }
    }
    &.notice {
        vertical-align: top;

        .input-message {
            display: block;
            position: absolute;
            z-index: 1;
            left: 7px;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;

            .msg {
                margin-left: 3px;
                color: #666;
            }
        }
        .input-text {
            padding-bottom: 17px;
        }
    }
    &.waring {
        vertical-align: top;

        .inp {
            border-color: $color-11st-red;
        }
        .input-message {
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }
        .input-text {
            padding-bottom: 18px;
        }
    }
    &.notice {
        vertical-align: top;
        .input-message {
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 0;
            display: block;
            color: $color-11st-blue;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }
        .input-text {
            padding-bottom: 18px;
        }
    }
    &.wide {
        display: table;
        width: 100%;

        .lab {
            display: table-cell;
            min-width: 40px;
            padding-right: 10px;

            &--w70 {
                min-width: 70px;
            }
        }

        .input-text {
            display: table-cell;
            width: 100%;

            .inp {
                width: 100%;
            }
        }

        .input-file {
            display: table-cell;
            //min-width: 120px;
        }

        .unit {
            display: table-cell;
        }
    }
    &.flex-wide {
        flex-grow: 1;

        .input-text {
            width: 100%;
        }

        .inp {
            width: 100%;
        }
    }
    &.add {
        width: 100%;

        .input-text {
            width: calc(100% - 110px);

            .inp {
                width: 100%;
            }
        }
        .del-plus {
            margin-left: 10px;
        }
    }
    &.vertical {
        margin-top: 0;

        .lab {
            display: block;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
    &.file {
        position: relative;
        z-index: 1;
    }
    &.point {
        border: 1px solid #ccc;
        background: #fff;
        padding-right: 12px;
        height: 40px;

        .unit {
            color: $color-11st-red;
        }
        .inp {
            height: 36px;
            border: none;
            letter-spacing: 0px;
            color: $color-11st-red;
            text-align: right;
            padding-right: 0;
        }
        &.w-80 {
            .inp {
                width: 55px;
            }
        }
    }
    &.w-80 {
        .inp {
            width: 80px;
        }
    }
    &.w-120 {
        .inp {
            width: 120px;
        }
    }
    &.w-130 {
        // 팝업 >  col__row > calender 에서 최소 크기에 말줄임 회피용으로 사용할 것
        .inp {
            width: 130px;
        }
    }
    &.w-200 {
        .inp {
            width: 200px;
        }
    }
    &.w-240 {
        .inp {
            width: 240px;
        }
    }
    &.w-300 {
        .inp {
            width: 300px;
        }
    }
    &.w-600 {
        .inp {
            width: 600px;
        }
    }
    &.size__3 {
        .inp {
            height: 36px;
        }
        .input-etc {
            height: 34px;

            .btn-search {
                margin: 6px 10px;
            }
            .btn-clear {
                margin-top: 6px;
                margin-bottom: 6px;
            }
            .character-check {
                height: 34px;
                line-height: 34px;
            }
        }
    }
    &.size__4 {
        .inp {
            height: 32px;
        }
        .input-etc {
            height: 28px;

            .btn-search {
                margin: 4px 8px;
            }
            .btn-clear {
                margin-top: 6px;
                margin-bottom: 6px;
            }
            .character-check {
                height: 30px;
                line-height: 30px;
            }
        }
    }
    //input 패딩 블럭
    &.pr-12 {
        //input 오른쪽 여백 축소
        .inp {
            padding-right: 12px;
        }
    }
}

.c-input__style2 {
    .inp {
        border-color: $color-11st-red;
    }

    .input-text + [class^="btn-style"] {
        margin-left: 0 !important;
    }

    &.wide .input-text + [class^="btn-style"] {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.label-hidden {
    display: inline-block !important;
    min-width: 40px !important;
    width: 40px;
    height: 40px;
    overflow: hidden;
    vertical-align: middle;

    .form-check__label {
        display: block;
        height: 1px;
        overflow: hidden;
    }
}

// radio button
.c-radio {
    &--style1 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            line-height: 40px;
            color: #111;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                @include sprite-retina($sp_form_2x_radio);
                content: "";
                display: inline-block;
                overflow: hidden;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_radio_on);
        }
        .inp:disabled + .lab {
            color: $color-grayscale-06;
        }
        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_radio_disabled2);
        }
        .inp:checked:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_radio_disabled);
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--style2 {
        display: inline-block;
        position: relative;
        z-index: 1;
        line-height: 40px;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            line-height: 40px;
            color: #111;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                display: block;
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                background: #fafafa;
                border-radius: 50%;
                margin: 9px 0 0 9px;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_radio_on);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: #fff;
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_radio_disabled);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: #fff;
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--vertical {
        display: block;
        margin-left: -9px;
        &:not(:first-child) {
            --element-mt: 0;
        }
    }

    svg {
        vertical-align: middle;
        transform: translateY(-10%);
    }
    &--tab {
        // 탭 효과를 위해 추가
        margin-left: 15px;
        --element-mt: 0;
    }
}

.radio-style__1 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        line-height: 40px;
        color: #111;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            @include sprite-retina($sp_form_2x_radio);
            content: "";
            display: inline-block;
            overflow: hidden;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_radio_on);
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_radio_disabled);
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}
.radio-style__2 {
    display: inline-block;
    position: relative;
    z-index: 1;
    line-height: 40px;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        line-height: 40px;
        color: #111;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            background: #fafafa;
            border-radius: 50%;
            margin: 9px 0 0 9px;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_radio_on);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: #fff;
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_radio_disabled);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: #fff;
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

// check box
.c-check {
    white-space: nowrap;

    &--style1 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            color: #111;
            line-height: 40px;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                @include sprite-retina($sp_form_2x_check);
                content: "";
                display: inline-block;
                overflow: hidden;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_check_on);
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_check_disabled);
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--style2 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 38px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            color: #111;
            line-height: 40px;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                @include sprite-retina($sp_form_2x_check_style2);
                content: "";
                display: inline-block;
                overflow: hidden;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_check_on_style2);
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_check_disabled_style2);
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--style3 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            color: #111;
            line-height: 40px;
            vertical-align: middle;

            &:before {
                position: absolute;
                top: 8px;
                left: 8px;
                @include sprite-retina($sp_form_2x_check_style3);
                content: "";
                display: inline-block;
                overflow: hidden;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_check_on_style3);
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_check_disabled_style2);
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--style4 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            color: #111;
            line-height: 40px;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                display: inline-block;
                margin: 9px 0 0 9px;
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                background: #fafafa;
                border-radius: 50%;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_check_on);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: transparent;
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_check_disabled);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: transparent;
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            border-radius: 11px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &--style5 {
        display: inline-block;
        position: relative;
        z-index: 1;

        .inp {
            width: 40px;
            height: 40px;
            opacity: 0;
        }

        .lab {
            font-size: 14px;
            color: #111;
            line-height: 40px;
            vertical-align: middle;

            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                display: inline-block;
                margin: 9px 0 0 9px;
                width: 22px;
                height: 22px;
                border: 1px solid #ccc;
                background: #fff;
            }
        }

        .inp:checked + .lab:before {
            @include sprite-retina($sp_form_2x_check_on_style2);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: transparent;
        }

        .inp:disabled + .lab:before {
            @include sprite-retina($sp_form_2x_check_disabled_style2);
            margin: 0;
            border: 0;
            border-radius: 0;
            background-color: transparent;
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 9px;
            width: 22px;
            height: 22px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }
    }

    &__text {
        &--bold {
            font-weight: bold;
        }
    }

    &--line-break {
        white-space: normal;

        .inp {
            position: absolute;
            top: 0;
            left: 0;
            height: 21px;
        }

        .inp:focus + .lab:after {
            position: absolute;
            left: 9px;
            top: 1px;
            width: 22px;
            height: 22px;
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            content: "";
        }

        .lab {
            display: flex;
            align-items: top;
            position: relative;
            padding-left: 38px;
            line-height: 21px;
            white-space: normal;
            text-align: left;

            &:before {
                top: -8px;
            }
        }
    }

    &--unchangeable {
        .lab {
            color: $color-gray-08;
        }

        &.c-check--style2 {
            .lab {
                color: $color-gray-08;
                &:before {
                    @include sprite-retina($sp_form_2x_check_unchangeable_style2);
                }
            }

            .inp:checked + .lab:before {
                @include sprite-retina($sp_form_2x_check_disabled_style2);
            }
        }
    }
}

.check-style__1 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            @include sprite-retina($sp_form_2x_check);
            content: "";
            display: inline-block;
            overflow: hidden;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_check_on);
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_check_disabled);
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

.check-style__2 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 38px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            @include sprite-retina($sp_form_2x_check_style2);
            content: "";
            display: inline-block;
            overflow: hidden;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_check_on_style2);
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_check_disabled_style2);
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

.check-style__3 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;

        &:before {
            position: absolute;
            top: 8px;
            left: 8px;
            @include sprite-retina($sp_form_2x_check_style3);
            content: "";
            display: inline-block;
            overflow: hidden;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_check_on_style3);
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_check_disabled_style2);
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

.check-style__4 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: inline-block;
            margin: 9px 0 0 9px;
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            background: #fafafa;
            border-radius: 50%;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_check_on);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_check_disabled);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

.check-style__5 {
    display: inline-block;
    position: relative;
    z-index: 1;

    .inp {
        width: 40px;
        height: 40px;
        opacity: 0;
    }

    .lab {
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: inline-block;
            margin: 9px 0 0 9px;
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            background: #fff;
        }
    }

    .inp:checked + .lab:before {
        @include sprite-retina($sp_form_2x_check_on_style2);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    .inp:disabled + .lab:before {
        @include sprite-retina($sp_form_2x_check_disabled_style2);
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
    }

    .inp:focus + .lab:after {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 22px;
        height: 22px;
        box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        content: "";
    }
}

.c-radio__tab {
    display: table;
    table-layout: fixed;

    li {
        float: left;
        display: table-cell;

        &:not(:first-child) {
            margin-left: -1px;
        }
    }

    & + .c-radio__tab {
        margin-top: 20px;
    }

    .title {
        float: left;
        margin-right: 18px;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: -0.44px;
        color: #111;
    }

    .inp {
        position: absolute;
        display: inline-block;
        opacity: 0;
        width: 0;
        height: 0;
    }

    .lab {
        position: relative;
        z-index: 1;
        float: left;
        margin-left: -1px;
        display: table-cell;
        padding: 4px 10px;
        min-width: 120px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #ccc;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #111;
        background: #fff;

        &:first-child {
            margin-left: 0px;
        }
    }

    .c-radio__text {
        margin-top: 65px;
        color: #000;
        font-size: 13px;
        letter-spacing: -0.4px;
        line-height: 20px;
        text-align: center;
    }

    .active {
        z-index: 2;
        color: $color-11st-red;
        border: 1px solid $color-11st-red;

        &:before {
            content: "";
            margin-top: -5px;
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
            width: 12px;
            height: 7px;
            border: 1px solid $color-11st-red;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
        }
    }

    .disabled {
        color: #bbb;
        border-color: #ddd;
        background-color: #f4f4f4;

        &:before {
            display: none;
        }
    }

    .count {
        margin-left: 5px;
        font-weight: bold;
        line-height: 28px;

        .number {
            font-weight: bold;
        }
    }

    &.wide {
        display: flex;
        width: 100%;

        & > .lab,
        & > li {
            flex: 1 1 auto;
        }
    }

    &.w-200 {
        .lab {
            min-width: 200px;
        }
    }
}

.c-radio-tab {
    &.c-radio-tab--style2 {
        display: flex;
        flex-direction: row;

        .inp {
            position: absolute;
            display: inline-block;
            opacity: 0;
            width: 0;
            height: 0;
        }

        .lab {
            flex: 0 1 auto;
            position: relative;
            z-index: 1;
            padding: 2px 30px;
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            border: none;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #111;
            background: #fff;
            letter-spacing: 0;

            .count {
                font-weight: bold;
            }

            &::before {
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 1px;
                height: 20px;
                background: $color-grayscale-08;
                content: "";
            }

            &:first-of-type {
                &::before {
                    display: none;
                }
            }

            &.active {
                border-radius: 20px;
                border: 1px solid #fcd0d4;
                background: #fef8f9;
                color: $color-11st-red;

                &::before {
                    display: none;
                }

                & + .lab {
                    &::before {
                        display: none;
                    }
                }
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.c-dropdown {
    display: inline-block;
    position: relative;
    z-index: 1;
    height: 40px;
    vertical-align: middle;

    &.active {
        z-index: 10;
        .c-dropdown__list,
        .c-dropdown-list {
            display: block;
            top: 39px;
            left: 0;
        }
    }

    &-flag {
        display: inline-block;
        font-weight: normal;

        &--333333 {
            padding: 0 6px;
            height: 20px;
            color: $color-grayscale-13;
            font-size: 14px;
            background-color: #333333;
            line-height: 20px;
            font-weight: normal;
        }
    }

    .lab {
        color: #000;
        line-height: 26px;
        margin-right: 10px;
    }

    .title {
        display: inline-block;
        padding-right: 10px;
        font-size: 14px;
        color: #111;
        line-height: 40px;
        vertical-align: middle;
        white-space: nowrap;

        + .dropdown {
            vertical-align: middle;
        }
    }

    .c-text {
        margin-right: 10px;
    }

    .dropdown {
        display: inline-block;
        position: relative;
        z-index: 1;

        & + .unit {
            display: inline-block;
            margin-left: 10px;
            color: #111;
            line-height: 40px;
            font-size: 14px;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    .btn {
        position: relative;
        z-index: 2;
        top: 0;
        left: 0;
        padding: 0 45px 0 12px;
        width: 160px;
        height: 40px;
        border: 1px solid #ddd;
        text-align: left;
        color: $color-grayscale-05-1;
        font-size: 14px;
        background-color: #fff;

        span.text {
            display: block;
            width: 100%;
            height: 38px;
            line-height: 38px;
            overflow: hidden;
            //white-space: nowrap;
            text-overflow: clip;

            .text_style1 {
                .emblem {
                    margin-right: 5px;
                    color: $color-11st-red;
                }
            }

            &.text--style2 {
                color: $color-grayscale-02;
                letter-spacing: 0;
            }
        }

        &:before {
            position: absolute;
            top: 0;
            right: 40px;
            content: "";
            height: 100%;
            width: 1px;
            border-left: 1px solid #f4f4f4;
        }

        &:after {
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -7px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 8px;
            height: 8px;
            border: 1px solid $color-grayscale-05-1;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
        }

        .c-dropdown-flag {
            margin-right: 8px;
        }
    }

    .btn:disabled {
        $background-color: #f4f4f4;
        color: #ccc;
        border-color: #ddd;
        background-color: $background-color;

        .unit {
            background-color: $background-color;
        }

        &:before {
            border-color: #ddd;
            color: #ccc;
        }

        &:after {
            border-color: $color-gray-08;
        }
    }

    .list {
        display: none;
        position: absolute;
        z-index: 1;
        top: 39px;
        left: 0;
        width: 100%;
        padding: 10px 0;
        border: 1px solid #bbb;
        background: #fff;
        max-height: 200px;
        overflow-y: auto;

        [role="option"] {
            display: block;
            width: 100%;
            padding: 8px 12px;
            color: #666;
            cursor: pointer;
            text-align: left;
            font-size: 14px;

            &[aria-selected="true"] {
                color: $color-11st-red;

                .text_style1 {
                    color: #111;

                    .emblem {
                        margin-right: 5px;
                        color: $color-11st-red;
                    }
                }
            }

            &:hover {
                color: #111;
                background: #fafafa;
                text-decoration: none;
            }
        }
    }

    &.list-up {
        .list {
            top: auto;
            bottom: 39px;
        }
    }

    &.open {
        z-index: 10;

        .c-input .input-text .inp {
            border-color: #bbb;
        }

        .btn {
            border-color: #bbb;
            color: #111;

            &:afte r {
                top: 65%;
                transform: rotate(-45deg);
            }
        }
    }

    &.filled {
        $border-color: #bbb;

        .btn {
            border-color: $border-color;
            color: #111;
        }

        .btn:disabled {
            border-color: $border-color;
        }

        .c-input .input-text .inp {
            border-color: $border-color;
            color: #111;
            &::-webkit-input-placeholder {
                color: #111;
                font-weight: normal;
            }
            &::-moz-placeholder {
                color: #111;
                font-weight: normal;
            } /* firefox 19+ */
            &::-ms-input-placeholder {
                color: #111;
                font-weight: normal;
            } /* ie */
        }
    }

    &.auto {
        .btn {
            color: #111;
            background-color: #f4f4f4;
            border-color: #ddd;

            &:before {
                border-color: #ddd;
                color: #ccc;
            }

            &:after {
                border-color: $color-gray-08;
            }
        }

        .btn .unit {
            background-color: #f4f4f4;
            color: #eee;
            border-color: #ddd;
        }

        .c-input .input-text .inp {
            background-color: #f4f4f4;
            color: #111;
            &::-webkit-input-placeholder {
                color: #111;
                font-weight: normal;
            }
            &::-moz-placeholder {
                color: #111;
                font-weight: normal;
            } /* firefox 19+ */
            &::-ms-input-placeholder {
                color: #111;
                font-weight: normal;
            } /* ie */
        }

        .c-input .input-text .input-etc {
            background-color: #f4f4f4;
            .btn-search {
                @include sprite($sp_icon_search-dis);
            }
        }
    }

    &.wide {
        display: table;
        width: 100%;

        .dropdown {
            display: table-cell;
            width: 100%;
        }

        [class^="btn-style"] {
            margin: 0 0 0 10px;
            min-width: 160px;
        }
        .c-input,
        .input-text,
        .inp {
            width: 100%;
        }
        .btn {
            width: 100%;
        }
        .list {
            width: 100%;
        }
        .input-group {
            display: table-cell;
            width: 100%;

            &.non-unit {
                display: table;

                .input-text {
                    display: table;
                }
            }
        }
    }

    &.unit {
        .c-input {
            float: left;
            width: 120px;
        }

        .c-input + .btn {
            border-left: none;
        }

        .btn {
            width: 62px !important;
            min-width: auto !important;
            padding-right: 5px !important;

            &:before {
                display: none;
            }
        }

        .list {
            width: 62px;
            min-width: auto;
            left: auto;
            right: 0;
        }

        .inp {
            border-right: none;
        }
    }

    &.unit.wide {
        .c-input {
            width: calc(100% - 62px);
        }
    }

    &.notice {
        vertical-align: top;

        .input-message {
            display: block;
            position: absolute;
            z-index: 1;
            left: 7px;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;

            .msg {
                color: #666;
            }
        }

        .input-text {
            padding-bottom: 17px;
        }
    }

    &.waring {
        vertical-align: top;

        .inp {
            border-color: $color-11st-red;
        }

        .input-message {
            position: absolute;
            z-index: 1;
            left: 7px;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }

        .input-text {
            padding-bottom: 17px;
        }

        .btn {
            border-color: $color-11st-red;
        }

        &.unit {
            .inp {
                border-right: none;
            }
        }
    }

    &.w-600 {
        .btn {
            width: 600px;
        }

        .c-input,
        .input-text,
        .inp {
            width: 600px;
        }
        .list {
            width: 600px;
        }
    }

    &.w-300 {
        .btn {
            width: 300px;
        }

        .c-input,
        .input-text,
        .inp {
            width: 300px;
        }
        .list {
            width: 300px;
        }
    }

    &.w-200 {
        .btn {
            width: 200px;
        }
        .c-input,
        .input-text,
        .inp {
            width: 200px;
        }
        .list {
            width: 200px;
        }
    }

    &.w-120 {
        .btn {
            width: 120px;
        }
        .c-input,
        .input-text,
        .inp {
            width: 120px;
        }
        .list {
            width: 120px;
        }
    }

    &.w-90 {
        .btn {
            width: 90px;
        }
        .c-input,
        .input-text,
        .inp {
            width: 90px;
        }
        .list {
            width: 90px;
        }
    }

    &.size__3 {
        height: 36px;

        .btn {
            height: 36px;

            .text {
                height: 34px;
                line-height: 34px;
                font-size: 13px;
            }
        }

        .c-input .input-text .inp {
            height: 36px;
        }

        .c-input .input-text .input-etc {
            height: 34px;

            .btn-search {
                margin: 7px 12px;
            }
        }
    }

    &.size__4 {
        height: 32px;

        .btn {
            height: 32px;
            padding-right: 22px;

            &:before {
                display: none;
            }

            &:after {
                right: 7px;
            }

            .text {
                height: 30px;
                line-height: 30px;
                font-size: 13px;
            }
        }

        .c-input .input-text .inp {
            height: 32px;
        }

        .c-input .input-text .input-etc {
            height: 30px;

            .btn-search {
                margin: 5px 12px;
            }
        }
    }
}

.c-dropdown__list {
    display: none;
    position: absolute;
    z-index: 1000;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #bbb;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;

    [role="option"] {
        display: block;
        width: 100%;
        padding: 8px 12px;
        color: #666;
        cursor: pointer;
        text-align: left;
        font-size: 14px;

        &[aria-selected="true"] {
            color: $color-11st-red;
        }

        &:hover {
            color: #111;
            background: #fafafa;
            text-decoration: none;
        }
    }

    &.open {
        display: block;
    }
}

.c-dropdown-list {
    display: none;
    position: absolute;
    z-index: 1000;
    width: 100%;

    border: 1px solid #bbb;
    background: #fff;
    max-height: 200px;
    overflow-y: auto;

    &--notice {
        width: 380px;
    }

    &--matching {
        padding: 12px 0;

        .c-dropdown-list__title {
            padding: 0 12px;
            color: $color-grayscale-02;
            font-size: 14px;
            line-height: 36px;
        }

        .c-dropdown-list__subtext {
            padding: 0 12px;
            color: $color-grayscale-05-1;
            font-size: 14px;
            line-height: 36px;
        }

        .c-dropdown-list__fail {
            padding: 0 12px;
            color: $color-grayscale-02;
            background-color: #fafafa;
            font-size: 14px;
            line-height: 36px;
            em {
                font-weight: bold;
            }
        }

        .c-dropdown-list__guide {
            position: relative;
            padding-left: 10px;
            margin: 0 12px;
            color: $color-grayscale-04;
            font-size: 14px;
            line-height: 20px;

            &::before {
                position: absolute;
                top: 9px;
                left: 2px;
                display: block;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: #666;
                content: "";
            }

            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        .c-dropdown-list__list {
            color: $color-grayscale-02;
            font-size: 14px;

            li {
                color: #111;
                line-height: 36px;

                button {
                    width: 100%;
                    color: #111;
                    text-align: left;
                    line-height: 36px;
                    padding: 0 12px;

                    &:hover {
                        background-color: #fafafa;
                    }
                }

                em {
                    font-weight: bold;
                }
            }
        }
    }

    &--delivery {
        padding: 12px 0 0;

        .c-dropdown-list__list {
            color: $color-grayscale-02;
            font-size: 14px;
            padding: 0 12px;

            li {
                color: #111;
                line-height: 36px;

                button {
                    width: 100%;
                    color: #111;
                    text-align: left;

                    .c-dropdown-flag {
                        margin-right: 8px;
                        font-weight: normal;
                    }
                }

                em {
                    font-weight: bold;
                }
            }
        }

        .c-dropdown-list__bottom {
            margin-top: 12px;
            padding: 15px 12px;
            border-top: 1px solid #eee;

            .link-style1 {
                display: inline-block;
                font-size: 14px;
                color: #0b83e6;
            }
        }
    }

    &--option {
        padding: 12px 0 0;

        .c-dropdown-list__title {
            padding: 0 12px;
            color: $color-grayscale-02;
            font-size: 14px;
            text-align: left;
        }

        .c-dropdown-list__list {
            color: $color-grayscale-02;
            font-size: 14px;
            padding: 0 12px;

            li {
                color: #111;
                line-height: 36px;

                button {
                    width: 100%;
                    color: #111;
                    text-align: left;

                    .c-dropdown-flag {
                        margin-right: 8px;
                        font-weight: normal;
                    }
                }

                em {
                    font-weight: bold;
                }
            }
        }

        .c-dropdown-list__text {
            padding: 0 12px;
            margin-top: 15px;
            font-size: 14px;
            color: $color-11st-red;
        }

        .c-dropdown-list__bottom {
            margin-top: 12px;
            padding: 15px 12px;
            border-top: 1px solid #eee;

            .link-style1 {
                display: inline-block;
                font-size: 14px;
                color: #0b83e6;
                text-align: left;
            }
        }
    }

    &--ag-checkbox {
        width: 300px;
        .ag-label {
            white-space: normal;
        }
        .ag-checked {
            align-self: flex-start;
            margin-top: 2px;
        }
        .c-dropdown-list__list {
            padding: 20px 12px;
            color: $color-grayscale-02;
            font-size: 14px;

            li {
                padding: 7px 0;
                color: #111;
                line-height: 1.5;

                &:hover {
                    background-color: #fafafa;
                }
            }
        }

        &.active {
            display: block;
        }
    }
}

.c-textarea {
    position: relative;
    z-index: 1;

    .txtarea {
        width: 160px;
        padding: 13px;
        border: 1px solid #ddd;
        font-size: 14px;
        color: #111;
        resize: none;
        overflow-y: auto;
        &::-webkit-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        }
        &::-moz-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* firefox 19+ */
        &::-ms-input-placeholder {
            color: $color-grayscale-05-1;
            font-weight: normal;
        } /* ie */
    }

    .txtarea[rows="2"] {
        padding: 8px;
    }

    .character-check {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        display: inline-block;
        height: 15px;
        margin: 0 9px;
        font-size: 13px;
        color: $color-grayscale-05-1;
        line-height: 15px;
        text-align: right;
        vertical-align: middle;

        em {
            color: #111;
        }
    }

    .txtarea-text {
        position: relative;
        display: inline-block;
        border: 1px solid #ddd;
        background-color: $color-gray-14;
        padding: 8px;
        vertical-align: top;

        .txtarea {
            border: none;
            padding: 0;
        }

        .txtarea[rows="2"] {
            padding: 0;
        }

        .character-check {
            position: static;
            display: block;
            margin: 6px 0 0 0;
            text-align: right;
        }

        & + button {
            margin-left: 10px;
        }

        @include clearfix();
    }

    .input-message {
        display: none;
    }

    &.wide {
        display: table;
        width: 100%;

        .txtarea-text {
            display: table-cell;
            width: 100%;
        }

        .txtarea {
            width: 100%;
        }
    }

    &.waring {
        position: relative;
        z-index: 1;
        padding-bottom: 18px;

        .input-message {
            display: block;
            position: absolute;
            z-index: 1;
            left: 7px;
            bottom: 0;
            display: block;
            color: $color-11st-red;
            width: 100%;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            white-space: nowrap;
        }

        .character-check {
            bottom: 17px;
        }

        .txtarea-text {
            .character-check {
                bottom: 0;
            }
            .input-message {
                bottom: -17px;
            }
        }
    }

    @each $width in (200, 240, 300, 320) {
        &.w-#{$width} {
            .txtarea {
                width: #{$width}px;
            }
        }
    }

    &.disabled {
        .txtarea-text {
            border: 1px solid #ddd;
            background: #f4f4f4;
        }
        .txtarea {
            pointer-events: none;
            user-select: none;
            background: #f4f4f4;
            &::placeholder {
                color: #bbb;
            }
        }
        .character-check {
            color: #bbb;
            em {
                color: #bbb;
            }
        }
    }
}

.c-tab {
    display: table;
    font-size: 14px;
}

.c-tab__text {
    display: inline-block;

    &:hover {
        text-decoration: none;
    }
}

.c-tab__item {
    display: table-cell;
}

.c-tab__style1,
.c-tab--style1 {
    height: 26px;
    color: $color-grayscale-05-1;

    .c-tab__item {
        &:first-child {
            &:before {
                display: none;
            }
        }

        &:before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 12px;
            background: #eee;
            vertical-align: middle;
        }

        &.active {
            a,
            button {
                &:before {
                    display: inline-block;
                    content: "";
                    @include sprite($sp_icon_tab_active);
                    vertical-align: middle;
                }

                color: #111;
            }
        }
    }

    .c-tab__text {
        padding: 0 13px;
        line-height: 26px;
        font-size: 14px;
        color: $color-grayscale-05-1;
    }
}

.c-tab__style2,
.c-tab--style2 {
    width: 100%;
    overflow: hidden;

    .c-tab__item:nth-child(1):nth-last-child(2),
    .c-tab__item:nth-child(1):nth-last-child(2) ~ li {
        width: 50%;
    }

    .c-tab__item:nth-child(1):nth-last-child(3),
    .c-tab__item:nth-child(1):nth-last-child(3) ~ li {
        &:first-child {
            width: 34%;
        }

        width: 33%;
    }

    .c-tab__item {
        position: relative;
        z-index: 1;
        float: left;
        border: 1px solid #ddd;
        background: #fafafa;

        &:not(:first-child) {
            border-left: none;
        }

        &.active {
            border-bottom: none;
            background: #fff;

            .c-tab__text {
                color: $color-11st-red;
                vertical-align: middle;
                margin-left: 0px;
                font-weight: bold;
                border-bottom: 1px solid #fff;
            }

            .c-tab__text:before {
                display: inline-block;
                content: "";
                @include sprite-retina($sp_icon_2x_tab_active);
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }

    .c-tab__text {
        position: relative;
        z-index: 1;
        width: 100%;
        padding: 0 13px;
        line-height: 44px;
        text-align: center;
        color: #000;

        &:hover {
            color: $color-11st-red;
        }
    }
}
//.c-tab--style3 변경 후 삭제
.c-tab__style3 {
    color: $color-grayscale-05-1;

    .c-tab__item {
        &:first-child {
            &:before {
                display: none;
            }
        }

        &:before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 12px;
            background: #eee;
            vertical-align: middle;
        }

        &.active {
            a,
            button {
                color: #111;
            }
        }
    }

    .c-tab__text {
        padding: 0 13px;
        line-height: 26px;
        font-size: 13px;
        color: $color-grayscale-05-1;
    }
}

.c-tab--style3 {
    color: $color-grayscale-05-1;

    .c-tab__item {
        &:first-child {
            &:before {
                display: none;
            }
        }

        &:before {
            display: inline-block;
            content: "";
            width: 2px;
            height: 12px;
            background: #eee;
            vertical-align: middle;
        }

        &.active {
            a,
            button {
                color: #111;

                &::before {
                    display: inline-block;
                    margin-top: -2px;
                    margin-right: 2px;
                    @include sprite-retina($sp_icon_2x_ico_check);
                    content: "";
                    vertical-align: middle;
                }
            }
        }
    }

    .c-tab__text {
        padding: 0 13px;
        line-height: 26px;
        font-size: 13px;
        color: $color-grayscale-05-1;
    }
}

.c-tab--style4 {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;

    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 1px;
        background: $color-11st-red;
        content: "";
    }

    .c-tab__text {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 13px 40px;
        border: 1px solid #ddd;
        background: #fafafa;
        text-align: center;
        color: #000;
        font-size: 15px;
        line-height: 1.5;

        &:hover {
            color: $color-11st-red;
        }
    }

    .c-tab__item {
        flex: 0 1 auto;
        position: relative;
        z-index: 1;
        margin-left: -1px;

        &.active {
            z-index: 2;

            .c-tab__text {
                color: $color-11st-red;
                vertical-align: middle;
                margin-left: 0px;
                font-weight: bold;
                border: 1px solid $color-11st-red;
                border-bottom: 1px solid #fff;
                background: #fff;
            }
        }

        &--disabled {
            .c-tab__text {
                color: $color-gray-08;
                border: 1px solid $color-gray-11;
            }
        }
    }
}

.c-tab--style5 {
    position: relative;
    display: flex;
    flex-direction: row;

    .c-tab__text {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 2px 30px;
        text-align: center;
        color: $color-grayscale-02;
        font-size: 14px;
        line-height: 30px;

        &:hover {
            text-decoration: underline;
        }
    }

    .c-tab__item {
        flex: 0 1 auto;
        position: relative;
        z-index: 1;

        &:first-child {
            &::before {
                display: none;
            }
        }

        &::before {
            position: absolute;
            top: 8px;
            left: 0;
            display: block;
            width: 1px;
            height: 20px;
            background: $color-grayscale-08;
            content: "";
        }

        &.active {
            z-index: 2;

            .c-tab__text {
                color: $color-11st-red;
                vertical-align: middle;
                border-radius: 20px;
                border: 1px solid #fcd0d4;
                background: #fef8f9;
            }

            &::before {
                display: none;
            }

            & + .c-tab__item {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.c-tab--style6 {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 1px;
    overflow: hidden;

    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 1px;
        background: $color-11st-red;
        content: "";
    }

    &.c-tab--disabled {
        display: none;
    }

    .c-tab__text {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 13px 0;
        border: 1px solid $color-gray-11;
        background: #fafafa;
        text-align: center;
        color: $color-gray-02;
        font-size: 15px;
        line-height: 1.5;

        @include text-ellipsis();

        &:hover,
        &:focus {
            color: $color-11st-red;
        }
    }

    .c-tab__item {
        flex: 1 1 auto;
        position: relative;
        z-index: 1;
        margin-left: -1px;
        overflow: hidden;

        &.active {
            z-index: 2;

            .c-tab__text {
                color: $color-11st-red;
                vertical-align: middle;
                margin-left: 0px;
                font-weight: bold;
                border: 1px solid $color-11st-red;
                border-bottom: 1px solid #fff;
                background: #fff;
            }
        }
    }
}

.c-tab__cont {
    margin-top: 30px;
}

[class^="radio-style"],
[class^="check-style"] {
    margin-right: 20px;

    &:last-child {
        margin-right: 0;
    }

    &.label-hidden {
        margin-right: 0;
        line-height: 40px;
    }

    &.vertical {
        display: block;
        margin-left: -9px;
    }
}

.mt-0 {
    --element-mt: 0;
}
