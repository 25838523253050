@charset "UTF-8";
//conversion-dashboard  셀러 전환 대시보드

.conversion-dashboard {
    // 사용자 지정 변수
    --padding: 40px;
    --gap: 10px;

    // 폰트 크기
    --text-size-xxs: 13px;
    --text-size-xs: 14px;
    --text-size-sm: 15px;
    --text-size-md: 17px;
    --text-size-ml: 18px;
    --text-size-lg: 22px;
    --text-size-xl: 24px;
    --text-size-xxl: 28px;
    --text-size-xxxl: 32px;
    --text-bold: 700;
    --text-thin: 400;

    // 레이아웃
    --dashboard-padding: var(--padding);
    --dashboard-gap: var(--gap);
    --dashboard-divider-position: var(--padding);
    --step-number-size: 22px;
    //variable 값을 가져오면 오류남...node sass 버전이 낮음
    --step-pass: #ff0038; //통과한
    --step-active: #111; //진행중
    --step-default: #999; //기본
    --step-height: 54px;
    --step-gap: 4px;

    //스타일
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    height: 100%;
    min-height: 354px;

    &__left {
        position: relative;
        flex: 0 0 60%;
        padding: var(--dashboard-padding);
        background: url("/img/selleroffice/conversion/_dashboard_1.png") 40px 40px no-repeat;
        background-size: 60px auto;

        .conversion-dashboard__text-wrap {
            padding-left: 80px;
        }

        &::after {
            content: "";
            position: absolute;
            top: var(--dashboard-divider-position);
            bottom: var(--dashboard-divider-position);
            right: 0;
            width: 1px;
            background-color: $color-gray-11;
        }
    }
    &__right {
        position: relative;
        flex: 0 0 40%;
        padding: var(--dashboard-padding);
        background: url("/img/selleroffice/conversion/_dashboard_2.png") right 40px top 145px no-repeat;
        background-size: 116px auto;
    }
    &__text-wrap {
        display: flex;
        flex-direction: column;
        // gap: 10px;
    }
    &__text {
        // 대시보드 텍스트 전체
        @at-root [class^="conversion-dashboard__text"] {
            font-style: normal;
            font-weight: var(--text-thin);
            color: $color-gray-02;

            + [class^="conversion-dashboard__text"] {
                margin-top: var(--step-gap);
            }
        }

        &--size-17 {
            font-size: var(--text-size-md);
        }
        &--size-18 {
            font-size: var(--text-size-ml);
        }
        &--size-28 {
            font-size: var(--text-size-xxl);
            font-weight: var(--text-bold);
        }
        &--color-secondary {
            color: $color-secondary;
        }
        &--color-01 {
            color: $color-grayscale-01;
        }
    }
    &__step {
        &-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 21px;
        }
        &-item {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            height: var(--step-height);

            &::after {
                content: "";
                display: block;
                flex: 1 1 auto;
                height: 0;
                border-bottom: 1px solid var(--active-color, var(--step-pass));
            }
            &:last-child {
                flex: 0 0 auto;
                &::after {
                    display: none;
                }
                .conversion-dashboard__step-name {
                    --step-gap: 0;
                }
            }
            &:nth-child(n + 2) .conversion-dashboard__step-number {
                // 두번째 이후 스탭 번호 왼쪽 마진 추가
                margin-left: 4px;
            }
        }
        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--step-gap);
        }
        &-number {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--step-number-size);
            height: var(--step-number-size);
            border-radius: 50%;
            text-align: center;
            line-height: var(--step-number-size);
            font-size: var(--text-size-xs);
            border: 1px solid var(--active-color, var(--step-pass));
            color: var(--active-color, transparent);
            background: var(--active-background-color, var(--step-pass)) var(--step-background-image, url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='22/check'%3E%3Cpath id='ic_check' d='M5 10.8137L9.18769 15L17 8' stroke='white'/%3E%3C/g%3E%3C/svg%3E%0A")) center center;
        }
        &-name {
            flex: 0 0 auto;
            padding-right: var(--step-gap);
            color: var(--active-color, #ff0038);
        }
        // 활성화 +  기본 상태,  통화 스타일은 위에!!!!
        .active {
            //활성화 스타일
            --active-color: #111;
            --active-background-color: transparent;
            --step-background-image: none;
            &::after {
                border-bottom: 1px dashed var(--step-default);
            }
        }
        .active ~ .conversion-dashboard__step-item {
            //활성화 이후 스타일
            --active-color: #999;
            --active-background-color: transparent;
            --step-background-image: none;
            &::after {
                border-bottom: 1px dashed var(--step-default);
            }
        }
    }
    &__actions {
        // 버튼 그룹
        position: absolute;
        bottom: var(--dashboard-padding);
        left: var(--dashboard-padding);
        right: var(--dashboard-padding);
        display: flex;
        gap: var(--dashboard-gap);

        button,
        a {
            flex: 1 1 auto;
            font-weight: var(--text-bold);
        }
    }
}
