@charset "UTF-8";

.table {
    width: 100%;
    color: #111;
    font-size: 14px;

    td,
    th {
        border: 1px solid #ccc;
        text-align: center;
    }

    thead th {
        border-bottom: none;
        background: #eee;
        font-weight: normal;
    }

    .tabel-primary {
        color: $color-11st-red;
    }
}

.table-style1 {
    width: 100%;
    table-layout: fixed;

    td,
    th {
        text-align: left;
        font-size: 14px;
        border-bottom: 1px solid #eee;
        height: 59px;
        font-weight: normal;
        line-height: 20px;
        color: #111;
    }

    th {
        width: 163px;
    }

    td {
        font-weight: bold;
    }
}

.td-block {
    display: block;
}

.tbl {
    width: 100%;
    border-collapse: collapse;
    margin-top: 30px;
    table-layout: fixed;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;

    caption {
        font-weight: bold;
    }

    thead th {
        padding: 0 8px;
        height: 47px;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        font-size: 13px;
        color: #111;
        text-align: center;
    }

    tbody {
        background-color: #fff;
    }

    tfoot td {
        font-weight: 700;
    }

    th {
        padding: 10px 8px;
        line-height: 48px;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        background-color: #eee;
        font-size: 13px;
        color: #111;
        text-align: center;
        line-height: 1.8;
        letter-spacing: 0;
    }

    td {
        padding: 10px 8px;
        font-size: 13px;
        line-height: 1.8;
        height: 40px;
        color: #111;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        text-align: center;
        letter-spacing: 0;
        word-break: break-word;
    }

    tr.disabled {
        td,
        th {
            color: $color-grayscale-05-1;
        }
    }

    tr.highlight {
        td,
        th {
            background-color: #eff8ff;
        }
    }

    tr.separate-line {
        th,
        td {
            border-top-color: $color-gray-02;
        }
    }

    td.non-data {
        height: 80px;
        color: $color-11st-red; //프로모션 참여 신청에 사용 중, 추후 .non-data__style1 를 붙여서 사용
        font-size: 14px;

        &.non-data__style1 {
            color: $color-11st-red;
        }

        &.non-data__style2 {
            color: #111;
        }
    }

    @at-root .table-box & td.non-padding {
        //_bbox.scss .table-box .tbl td 보다 우선순위 높여야 적용됨
        padding: 0;
    }

    .tbl-group {
        td {
            background-color: #fffdf4;
        }

        &__list {
            &--hidden {
                display: none;

                &.active {
                    display: table-row;
                }
            }
        }
    }

    .text-brand {
        color: $color-11st-red;
    }
    /* 기본설정을 센터로 변경 .center 이제 사용하지 마세요 */
    .center {
        text-align: center;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .vertical-top {
        vertical-align: top;
    }

    .non-date {
        line-height: 90px;
        font-size: 14px;
        color: #111;
        text-align: center;
    }
    .non-bg {
        background-color: $color-gray-14;
    }

    .text-ellipsis1 {
        display: block;
        max-width: 100%;
        @include text-ellipsis();
    }

    .text-emphasis {
        &__style1 {
            color: $color-11st-red;
            font-weight: bold;
        }

        &__style2 {
            color: $color-11st-blue;
            font-weight: bold;
        }

        &__style3 {
            color: $color-gray-02;
            font-weight: bold !important;
        }
    }

    .text-point {
        &__style1 {
            color: $color-11st-red;
        }

        &__style2 {
            color: $color-11st-blue;
        }
    }

    .text-count {
        font-size: 12px;
        color: $color-grayscale-04;

        .value {
            color: $color-11st-red;
        }
    }

    .text-block {
        .c-select {
            vertical-align: middle;
        }
    }

    .tbl-date--term {
        dt,
        dd {
            display: inline-block;
        }
        dd {
            &:first-of-type {
                &:after {
                    display: inline-block;
                    margin: 0 2px;
                    content: "~";
                }
            }
        }

        span {
            display: inline-block;
        }
    }

    .tbl-button {
        &-arrow {
            position: relative;
            width: 22px;
            height: 22px;
            overflow: hidden;

            &--fold {
                &::after {
                    display: block;
                    @include ico-angle("down", 8px, #000, 1px, "absolute");
                    top: 8px;
                    right: 5px;
                }

                &.active {
                    &::after {
                        display: block;
                        @include ico-angle("up", 8px, #000, 1px, "absolute");
                        top: 13px;
                        right: 5px;
                    }
                }
            }
        }
    }

    &.line {
        position: relative;
        z-index: 1;
        margin-top: 30px;

        &:before {
            position: absolute;
            top: -20px;
            left: 0;
            width: 100%;
            border-top: 1px solid #eee;
            content: "";
        }
    }

    &--gap1 {
        td {
            padding: 0 8px !important; //추후  !important 제거 예정, .table-box 에서 tbl td{padding: 0 8px} 제거할때
        }
    }

    &--gap2 {
        td {
            padding: 20px 8px !important; //추후  !important 제거 예정, .table-box 에서 tbl td{padding: 0 8px} 제거할때
        }
    }
    &--margin-top {
        margin: 0;
    }
    /* 클래스명 변경 .tbl--style1 */
    &.tbl--style1,
    &.tbl-style__1,
    &.tbl-style__2 {
        caption {
            font-weight: normal;
        }

        th {
            font-weight: normal;
            padding: 10px 12px;
        }

        td {
            padding: 10px 12px;
        }

        &--group {
            margin-top: -1px;

            thead {
                visibility: collapse;
            }

            tbody {
                &:first-of-type {
                    td {
                        border-top: none;
                    }
                }

                td {
                    background-color: #fffdf4;
                }
            }
        }
    }
    /*
        제3자 제공 동의서 테이블 유형 - 법적 폰트사이즈 규정
        보유기간도 강조 표기 필요 (11pt 이상, 볼드 강조, 타 글자보다 20% 이상 커야함)
        제공하는자/제공받는자/ 제공목적의 글자크기는 최소 9pt 이상이며, 타 글자보다 20% 이상 커야합니다.
    */
    /* 클래스명 변경 .tbl--style2 */
    &.tbl--style2,
    &.tbl-style__3 {
        caption {
            font-weight: normal;
        }

        th {
            font-weight: bold;
            font-size: 14px;
        }

        td {
            color: #666;
            font-size: 14px;

            strong.text_big {
                font-size: 17px;
            }
        }
    }
    .gicon-question {
        margin-top: -2px;
    }

    // 정산관리 > 물류비 정산
    &.tbl--style3 {
        thead {
            th {
                font-weight: normal;
                font-size: 14px;
            }
        }

        tbody {
            th {
                background-color: $color-gray-14;
            }
        }

        th {
            font-size: 14px;
        }

        td {
            color: $color-gray-02;
            font-size: 14px;
        }

        & + .tbl--style3 {
            margin-top: 30px !important;
        }
    }
}

.tbl-label {
    display: inline-block;
    padding: 0 8px;
    margin-right: 5px;
    border-radius: 9.5px;
    border: solid 1px #0b83e6;
    line-height: 18px;
    color: #0b83e6;
    text-align: center;
    font-size: 12px;
    vertical-align: middle;
}

.tbl-link {
    display: block;
    margin: 0 auto;
    text-decoration: underline;
    color: #111;
    font-size: 13px;

    &.tbl-link__style1 {
        font-weight: bold;
    }
    &.tbl-link__style2 {
        color: $color-11st-red;
        font-weight: bold;
    }
}

.tbl-list {
    color: $color-gray-02;
    font-size: 14px;
    line-height: 22px;

    &--bullet {
        li,
        dd {
            list-style: none;
            position: relative;
            padding-left: 10px;

            &:before {
                position: absolute;
                top: 10px;
                left: 0;
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 100%;
                background-color: $color-gray-02;
                content: "";
            }
        }
    }
}
.tbl_fixed {
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    position: relative;
    padding-top: 48px;
    background-color: #fff;
    // 상품등록 - 리스팅 광고 - cell에 select 사용시 border-bottom 이 가려지는 이슈가 있어 사용함, 사이드 이팩트 발생시 z-index 처리는 class 제어로 분리 사용할 것
    z-index: 2;

    &.caption-open {
        margin-top: 50px;

        caption {
            position: absolute;
            left: auto;
            top: -40px;
        }
    }

    &.scroll-line__5 {
        height: 275px;
    }

    .in_scroll {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }

    .tbl {
        margin-top: 0 !important;
    }

    .tbl thead th {
        padding: 0px; /* reset */
        height: 0px;
        border: none;
    }

    thead .th-text {
        position: absolute;
        top: 0;
        width: inherit;
        height: 49px;
        line-height: 48px;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        background-color: #eee;
        font-size: 14px;
        color: #111;
    }

    thead th:first-child .th-text {
        border-left: none;
    }

    thead th:last-child .th-text {
        border-right: none;
    }

    .tbl tbody th {
        border-left: none;
        border-right: none;
    }

    .tbl tbody td:last-child {
        border-right: none;
    }

    .tbl tbody td:first-child {
        border-left: none;
    }
}

.caption-open {
    caption {
        position: static;
        font-size: 14px;
        color: #111;
        line-height: 20px;
        margin-bottom: 20px;
        text-align: left;
    }
}

.tbl-catalogue {
    padding: 14px 0;
    @include clearfix;

    &__price {
        float: left;
        margin-right: 8px;
        font-size: 15px;
        line-height: 22px;
    }
    &__flag {
        float: left;
        padding: 0 6px;
        margin-top: 1px;
        font-size: 14px;
        line-height: 21px;
        color: $color-gray-14;
        background-color: $color-gray-03;
    }
    &__delivery {
        display: block;
        padding-top: 2px;
        font-size: 13px;
        line-height: 19px;
        color: $color-gray-06;
        clear: both;
    }
}
