// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_icon_add_img: (50px, 86px, -50px, -86px, 32px, 32px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_add-img', );
$sp_icon_auto_save_on: (103px, 50px, -103px, -50px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_auto_save-on', );
$sp_icon_auto_save: (92px, 86px, -92px, -86px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_auto_save', );
$sp_icon_calendar_dis: (153px, 0px, -153px, 0px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_calendar-dis', );
$sp_icon_calendar: (153px, 32px, -153px, -32px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_calendar', );
$sp_icon_clear: (153px, 64px, -153px, -64px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_clear', );
$sp_icon_del_alert: (153px, 96px, -153px, -96px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_del-alert', );
$sp_icon_del_chip: (0px, 136px, 0px, -136px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_del-chip', );
$sp_icon_del_dialog: (0px, 36px, 0px, -36px, 40px, 40px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_del-dialog', );
$sp_icon_del_filed: (50px, 36px, -50px, -36px, 40px, 40px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_del-filed', );
$sp_icon_del_img: (32px, 136px, -32px, -136px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_del-img', );
$sp_icon_info: (64px, 136px, -64px, -136px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_info', );
$sp_icon_info_full: (96px, 136px, -96px, -136px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_info_full', );
$sp_icon_logo_11st_editor: (0px, 0px, 0px, 0px, 93px, 26px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_logo-11stEditor', );
$sp_icon_must: (135px, 50px, -135px, -50px, 8px, 8px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_must', );
$sp_icon_plus: (103px, 0px, -103px, 0px, 40px, 40px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_plus', );
$sp_icon_preview_on: (128px, 136px, -128px, -136px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_preview-on', );
$sp_icon_preview: (185px, 0px, -185px, 0px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_preview', );
$sp_icon_question: (185px, 32px, -185px, -32px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_question', );
$sp_icon_save_on: (185px, 64px, -185px, -64px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_save-on', );
$sp_icon_save: (185px, 96px, -185px, -96px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_save', );
$sp_icon_search_dis: (185px, 128px, -185px, -128px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_search-dis', );
$sp_icon_search: (0px, 168px, 0px, -168px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_search', );
$sp_icon_tab_active: (32px, 168px, -32px, -168px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_tab-active', );
$sp_icon_table_del: (0px, 86px, 0px, -86px, 40px, 40px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_table-del', );
$sp_icon_zoom: (64px, 168px, -64px, -168px, 22px, 22px, 207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', 'sp_icon_zoom', );
$spritesheet_sprites: ($sp_icon_add_img, $sp_icon_auto_save_on, $sp_icon_auto_save, $sp_icon_calendar_dis, $sp_icon_calendar, $sp_icon_clear, $sp_icon_del_alert, $sp_icon_del_chip, $sp_icon_del_dialog, $sp_icon_del_filed, $sp_icon_del_img, $sp_icon_info, $sp_icon_info_full, $sp_icon_logo_11st_editor, $sp_icon_must, $sp_icon_plus, $sp_icon_preview_on, $sp_icon_preview, $sp_icon_question, $sp_icon_save_on, $sp_icon_save, $sp_icon_search_dis, $sp_icon_search, $sp_icon_tab_active, $sp_icon_table_del, $sp_icon_zoom, );
$spritesheet: (207px, 190px, '/img/selleroffice/sprites/sp_icon_2023712_140721.png', $spritesheet_sprites, );


